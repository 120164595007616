import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import Modal from "react-modal";
import { Carousel } from "react-responsive-carousel";
import CallToAction from './CallToAction';
import styled from 'styled-components'
import colors from '../globalStyles.scss'
import { useLanguage } from '../context/LanguageContext';
import { useTheme } from '../context/ThemeContext';
import ConditionalRender from './ConditionalRender';
import classnames from 'classnames';
import useScreenSize from '../context/useScreenSize';
import Wrapper from './Wrapper';

const StyledDiv = styled.div`
.no-header {
    .header-wrapper {
        background: transparent !important;
    }
}
.uploaded-images {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 1%;
    margin-top: 1em;
}

.dropzone {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0 1em;
    min-height: 40px;
    background: ${(props) => (props.theme === 'light' ? colors.blue : colors.secondary)};
    cursor: pointer;
    transition: all 0.2s ease 0s;
    p {
        font-family: ${colors.openSans};
        color: ${(props) => (props.theme === 'light' ? colors.white : colors.black)};
    }
    &:hover {
        background: ${colors.lightGray};
        p {
            color: ${colors.blue};
        }
    }
}

.image-wrapper {
    position: relative;
    padding: 0.5em;
    background: ${colors.blue};
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    img {
        object-fit: cover;
        width: 100%;
        height: 250px;
        cursor: pointer;
    }
    .remove {
        position: absolute;
        top: 0;
        right: 0;
        background: ${colors.red};
        svg {
            color: ${colors.white};
            font-size: 1rem;
        }
    }

    /* Ensure all images maintain the same width */
    &:nth-last-child(1),
    &:nth-last-child(2),
    &:nth-last-child(3) {
        grid-column: span 1;
    }
}
`




const ImageUploader = ({
    onImageUpload,
    maxFiles,
    customMessage,
    title = '',
    children,
    showLightColors,
    isLoading = false,
    noHeader = false
}) => {
    const { text } = useLanguage();
    const { theme } = useTheme();
    const { isDesktop, isTablet, isPhone } = useScreenSize();

    const [uploadedImages, setUploadedImages] = useState([]);
    const [imageError, setImageError] = useState('');

    const [activeIndex, setActiveIndex] = useState(0);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = (index) => {
        setActiveIndex(index);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const onDrop = useCallback((acceptedFiles) => {
        const imageFiles = acceptedFiles.filter(file =>
            file.type.startsWith('image/')
        );
        if (uploadedImages.length + acceptedFiles.length > maxFiles) {
            setImageError(`${text?.projects?.create?.uploader?.validations?.upto} ${maxFiles} ${maxFiles > 1 ? text?.projects?.create?.uploader?.images : text?.projects?.create?.uploader?.image}`);
            return;
        }

        if (imageFiles.length !== acceptedFiles.length) {
            setImageError(text?.projects?.create?.uploader?.validations?.onlyImages)
        } else {
            const newImagesPromises = acceptedFiles.map((file) => new Promise((resolve, reject) => {
                const reader = new FileReader();

                reader.onloadend = async () => {
                    resolve({ file, dataUrl: reader.result, originalName: file.name });

                    // Send the uploaded image to the Express API
                    const formData = new FormData();
                    formData.append("image", file);
                };

                if (file) {
                    reader.readAsDataURL(file);

                } else {
                    reader.onerror = reject;
                }

            }));

            Promise.all(newImagesPromises)
                .then((images) => {
                    setUploadedImages(prevImages => [...prevImages, ...images]);
                    setImageError('');
                    onImageUpload(
                        images.map(img => ({
                            dataUrl: img.dataUrl,
                            originalName: img.originalName
                        })));
                })
                .catch(() => setImageError(text?.projects?.create?.uploader?.validations?.error));
        }


    }, [maxFiles, onImageUpload, uploadedImages]);


    const removeImage = (index) => {
        const updatedImages = uploadedImages.filter((_, i) => i !== index);
        setUploadedImages(updatedImages);
        if (updatedImages.length < maxFiles) {
            setImageError('');
        }
        onImageUpload(updatedImages);
    };

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept: {
            'image/jpeg': ['.jpeg', '.jpg'],
            'image/png': ['.png'],
            'image/gif': ['.gif'],
            'image/bmp': ['.bmp'],
            'image/svg+xml': ['.svg']
        },
        multiple: true,
    });

    return (
        <StyledDiv
            className={classnames({
                'image-uploader': true,
                desktop: isDesktop,
                tablet: isTablet,
                phone: isPhone
            })}
            theme={theme}
            noHeader={noHeader}
        >
            <Wrapper
                className={classnames({
                    'no-header': noHeader
                })}
                isLoading={isLoading}
                width={'100%'}
                title={title}
                showLightColors={showLightColors}
                button={
                    <div {...getRootProps()} className='dropzone'>
                        <input {...getInputProps()} />
                        <ConditionalRender renderIf={!customMessage}>
                            <p>
                                {`${text?.projects?.create?.uploader?.title} ${maxFiles} ${maxFiles > 1 ? text?.projects?.create?.uploader?.images : text?.projects?.create?.uploader?.image} ${text?.projects?.create?.uploader?.here}`}
                            </p>
                            <p>
                                {text?.projects?.create?.uploader?.or}
                            </p>
                        </ConditionalRender>
                        <ConditionalRender renderIf={customMessage}>
                            <p>
                                {customMessage}
                            </p>
                        </ConditionalRender>
                    </div>
                }
            >
                <div className='uploaded-images'>
                    {uploadedImages.map((image, index) => (
                        <div
                            key={index}
                            onClick={() => openModal(index)}
                            className='image-wrapper'
                        >
                            <button
                                className='remove'
                                type="button"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    removeImage(index);
                                }}
                            >
                                <FontAwesomeIcon icon={faClose} />
                            </button>
                            <img src={image.dataUrl} alt={`Uploaded ${index}`} className='uploaded-image' />
                        </div>
                    ))}
                </div>

                {imageError && <p className='error small mt-sm'>{imageError}</p>}


                <Modal
                    isOpen={isModalOpen}
                    onRequestClose={closeModal}
                    style={{
                        overlay: {
                            backgroundColor: colors.backgroundOverlay,
                        },
                        content: {
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            overflow: "auto",
                            WebkitOverflowScrolling: "touch",
                            borderRadius: "4px",
                            outline: "none",
                            padding: "20px",
                            width: '100vw',
                            height: '100vh',
                            background: 'transparent',
                            display: 'flex',
                            flexDirection: 'column'
                        },
                    }}
                >
                    <Carousel
                        selectedItem={activeIndex}
                        showThumbs={false}
                        showStatus={false}
                        dynamicHeight={true}
                        onChange={(index) => setActiveIndex(index)}
                    >
                        {uploadedImages?.map((image, index) => (
                            <div key={index} style={{ height: '100%', width: '100%', textAlign: 'center' }}>
                                <img
                                    src={image.dataUrl}
                                    alt={`Uploaded ${index + 1}`}
                                    style={{
                                        height: '80vh',
                                        width: '80vw',
                                        objectFit: 'contain'
                                    }}
                                />
                            </div>
                        ))}
                    </Carousel>
                    <CallToAction
                        text={'Close'}
                        onClick={closeModal}
                        style={{
                            marginTop: '15px',
                            alignSelf: 'start'
                        }}
                    />
                </Modal>
                <ConditionalRender renderIf={children}>
                    {children}
                </ConditionalRender>
            </Wrapper>
        </StyledDiv >
    );
};

export default ImageUploader;
