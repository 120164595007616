import React, { useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom";
import CallToAction from '../Core/CallToAction';
import Loader from '../Core/Loader';
import InventoryItems from './InventoryItems';
import { deleteAllProducts } from '../utils/calls';
import colors from '../globalStyles.scss'
import ConditionalRender from '../Core/ConditionalRender';
import { isEmpty } from 'lodash';
import Wrapper from '../Core/Wrapper';
import ConfirmModal from '../Core/ConfirmModal';
import { useLanguage } from '../context/LanguageContext';
import { useToast } from '../context/ToastContext';
import useScreenSize from '../context/useScreenSize';

const InventoryHomeContainer = ({
    inventory,
    fetchInventoryProducts,
    inventorysLoading,
    setInventory,
    inventoryColumns,
    setInventoryColumns,
    fetchAttributes,
    isProject,
    projectId,
    projects,
    project,
    user,
    employees,
    title = 'Inventory',
    noDataText,
    projectIsComplete,
    setExpanded
}) => {
    const navigate = useNavigate();
    const [toggleModal, setToggleModal] = useState(false)
    const { text } = useLanguage();
    const { notify } = useToast();
    const { isDesktop } = useScreenSize();

    const handleDeleteAllProducts = async () => {
        try {
            const res = await deleteAllProducts();
            if (res.status === 200) {
                setToggleModal(false);
                fetchInventoryProducts(projectId || null);
                notify(text?.notificationsUI?.inventory?.allProductsDeleted, 'success');
            }
        } catch (error) {
            notify(`Error: ${error.message}`, 'error')
            console.error(error)
        }
    }

    useEffect(() => {
        fetchInventoryProducts(projectId || null)
    }, [])

    const goTo = () => {
        const url = isProject ? `/inventory/create?id=${projectId}` : '/inventory/create'
        return navigate(url)
    }
    return (
        <>
            {
                inventorysLoading ? (
                    <Loader count={1} />
                ) : (
                    <Wrapper
                        setExpanded={setExpanded}
                        section={'inventory'}
                        expandable
                        className='mb-md'
                        disableButton={projectIsComplete}
                        title={title}
                        button={
                            <ConditionalRender renderIf={!projectIsComplete}>
                                <CallToAction text={text?.inventory?.landing?.createNew} onClick={goTo} />
                            </ConditionalRender>
                        }
                    >
                        <div className='InventoryHomeContainer' style={{
                            display: 'flex',
                            flexDirection: 'column'
                        }}>
                            <InventoryItems
                                inventory={inventory}
                                fetchInventoryProducts={fetchInventoryProducts}
                                inventoryColumns={inventoryColumns}
                                setInventoryColumns={setInventoryColumns}
                                fetchAttributes={fetchAttributes}
                                isSuperAdmin={false}
                                isProject={isProject}
                                projectId={projectId}
                                projects={projects}
                                project={project}
                                employees={employees}
                                user={user}
                                noDataText={noDataText}
                                projectIsComplete={projectIsComplete}
                            />
                            <ConditionalRender renderIf={user?.roleName === 'Admin' && !isEmpty(inventory)}>
                                <div style={{
                                    color: colors.red,
                                    fontFamily: colors.openSans,
                                    alignSelf: 'start',
                                    cursor: 'pointer'
                                }} onClick={() => setToggleModal(true)}>
                                    {text?.inventory?.landing?.deleteAll}
                                </div>
                            </ConditionalRender>
                        </div>

                    </Wrapper>
                )
            }
            <ConfirmModal
                onClick={handleDeleteAllProducts}
                text={text?.inventory?.landing?.modal?.button}
                toggle={() => setToggleModal(!toggleModal)}
                isOpen={toggleModal}
                btnStyles={{
                    color: colors.red
                }}
                width={isDesktop ? '50%' : '100%'}
                height={isDesktop ? '50%' : '100%'}
            >
                <div>
                    <h4 style={{ marginBottom: '15px' }}>
                        {text?.inventory?.landing?.modal?.title}
                    </h4>
                    <p>
                        {text?.inventory?.landing?.modal?.subtitle}
                    </p>
                </div>
            </ConfirmModal>
        </>
    )
}

export default InventoryHomeContainer