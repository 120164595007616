import request from '../request';

/**
 * get calendar event categories request to the API.
 * @returns {Promise<Object>} - A Promise that resolves to the API response data.
 * @throws {Error} - If the API request fails, an Error is thrown.
 */
export const getEventCategories = async () => {
    let url = '/calendar/categories';
    try {
        const { data, status } = await request('GET', url);
        return { data, status };
    } catch (error) {
        throw new Error(`Failed to get event categories: ${error.message}`);
    }
};

export default getEventCategories;
