import React from 'react'
import MultiFields from '../Core/MultiFields';
import { isRequired, composeValidators, isValidEmail } from '../utils/validators';
import SubmitModal from '../Core/SubmitModal';
import CheckBox from '../Core/Inputs/CheckBox';
import { isEmpty } from 'lodash';
import useScreenSize from '../context/useScreenSize';

import colors from '../globalStyles.scss'
import { useLanguage } from '../context/LanguageContext';

const CreateNewEmployeeContainer = ({
    company,
    isOpen,
    onClick,
    toggle,
    roleType,
    setRoleType,
    employees,
    error = ''
}) => {
    const { text } = useLanguage();
    const { isDesktop } = useScreenSize();

    // Validator to check if the project name already exists
    const emailExists = value => {
        const nameExists = employees?.some(
            employee => employee?.email?.toLowerCase()?.trim() === value?.toLowerCase()?.trim()
        );
        return nameExists ? text?.employees?.createNew?.validation?.emailExists : undefined;
    };

    const required = composeValidators(
        isRequired(text?.employees?.createNew?.validation?.required),
    )
    const emailValidator = composeValidators(
        isRequired(text?.employees?.createNew?.validation?.emailRequired),
        isValidEmail(text?.employees?.createNew?.validation?.validEmail),
        emailExists
    )
    return (
        <SubmitModal
            onClick={onClick}
            text={text?.employees?.createNew?.button}
            toggle={toggle}
            isOpen={isOpen}
            width={isDesktop ? '80%' : '100%'}
            height={isDesktop ? '80%' : '100%'}
            btnStyles={{
                color: colors.blue
            }}
        >
            <h4 className='mb-md'>
                {text?.employees?.landing?.createNew}
            </h4>
            <p>
                {text?.employees?.createNew?.info}
            </p>
            <MultiFields
                className='field-style'
                name="firstName"
                component="input"
                type="text"
                label={text?.employees?.createNew?.firstName}
                block
                validate={required}
            // showLightColors
            />
            <MultiFields
                className='field-style'
                name="lastName"
                component="input"
                type="text"
                label={text?.employees?.createNew?.lastName}
                block
                validate={required}
            // showLightColors
            />
            <MultiFields
                className='field-style'
                name="email"
                component="input"
                type="text"
                label={text?.employees?.createNew?.email}
                block
                validate={emailValidator}
            // showLightColors
            />
            <CheckBox
                label={text?.employees?.createNew?.checkbox}
                checked={roleType === 'Admin'}
                onChange={() => roleType !== 'Admin' ? setRoleType('Admin') : setRoleType('Employee')}
                style={{
                    marginRight: '15px'
                }}
            />
            {!isEmpty(error) && <p style={{ color: 'red' }}>{error}</p>}
        </SubmitModal>
    )
}

export default CreateNewEmployeeContainer