import React, { useState, useEffect, useRef, useCallback } from 'react';

import { useWebSocket } from '../context/WebSocketContext';
import SearchInput from '../Core/Inputs/SearchInput';
import CheckBox from '../Core/Inputs/CheckBox';
import { findCompanies } from '../utils/calls';
import SelectableBox from '../Core/SelectableBox';
import { isEmpty } from 'lodash';
import ConfirmModal from '../Core/ConfirmModal';
import FieldWrapper from '../Core/FieldWrapper';
import ConditionalRender from '../Core/ConditionalRender';
import CallToAction from '../Core/CallToAction';
import RichEditorWithImageUploader from '../Core/Inputs/RichEditorWithImageUploader';
import { Field } from 'react-final-form';
import { isRequired, composeValidators } from '../utils/validators';
import ChatWindow from '../Chat/ChatWindow';
import { useLanguage } from '../context/LanguageContext';
import CreatableInput from '../Core/Inputs/CreatableInput';
import { useLocation, useNavigate } from "react-router-dom";
import colors from '../globalStyles.scss';
import {
    grantAccess,
    emailEveryone,
    emailSingleUser
} from '../utils/calls'
import MultiFields from '../Core/MultiFields';
import queryString from 'query-string';


const AdminContainer = ({
    user
}) => {
    const {
        selectedConversationId,
        setSelectedConversationId,
        setSelectedConversation,
        setMessages,
        selectedConversation,
        findSuperAdminConversation,
        claimSuperAdminConversation,
        isSendingMessage,
        setIsSendingMessage,
        createSuperAdminMessage,
        conversationIdIsLoading,
        closeSuperAdminConversation
    } = useWebSocket();
    const location = useLocation();
    const navigate = useNavigate();
    const { text } = useLanguage();
    const containerRef = useRef(null);
    const firstUnreadRef = useRef(null);
    const [firstUnreadIndex, setFirstUnreadIndex] = useState(null);
    const conversationIdRef = useRef(selectedConversationId); // Ref to track the current conversation ID

    const [companies, setCompanies] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [isChecked, setIsChecked] = useState(false);
    const [pendingAccess, setPendingAccess] = useState(false);
    const [notPremium, setNotPremium] = useState(false)
    const [premium, setPremium] = useState(false)
    const [selectedCompany, setSelectedCompany] = useState(null);
    const [selectedUser, setSelectedUser] = useState(null);
    const [emailToEveryone, setEmailToEveryone] = useState(false);
    const [emailToSingleUser, setEmailToSingleUser] = useState(false);
    const [closeIsOpen, setCloseIsOpen] = useState(false);

    const required = composeValidators(isRequired('Required'));
    const { companyId, userId } = queryString.parse(window.location.search);

    const removeQueryParams = (paramsToRemove) => {
        const searchParams = new URLSearchParams(location.search);

        // Remove specified params
        paramsToRemove.forEach(param => searchParams.delete(param));

        // Navigate to the new URL without the removed params
        navigate(`${location.pathname}?${searchParams.toString()}`, { replace: true });
    };

    // Function to add a query parameter dynamically
    const addQueryParam = (key, value) => {
        const searchParams = new URLSearchParams(location.search);
        searchParams.set(key, value); // Add or update the parameter

        navigate(`${location.pathname}?${searchParams.toString()}`, { replace: true });
    };

    // Sync the ref with the latest selected conversation ID
    useEffect(() => {
        conversationIdRef.current = selectedConversationId;
    }, [selectedConversationId]);


    // Update search query state
    const handleSearchChange = (e) => {
        setSearchQuery(e.target?.value);
    };

    const handleSelectedCompany = (company) => {
        setSelectedCompany(company);
    }

    const [selectedLanguage, setSelectedLanguage] = useState(null);
    const handleSelectChange = (event) => {
        setSelectedLanguage(event);
    }

    const handleFindSuperAdminConversation = () => {
        console.log('selectedUser:', selectedUser);
        console.log('selectedCompany:', selectedCompany);
        findSuperAdminConversation(selectedUser?.id, selectedCompany?._id, (conversation) => {
            if (conversation) {
                // setSelectedConversation(conversation);
                // setSelectedConversationId(conversation._id);
                // setMessages(conversation.messages);
            } else {
                // setSelectedConversation(null);
                // setSelectedConversationId(null);
                // setMessages([]);
            }
        });
    };

    const handleClaimSuperAdminConversation = () => {
        claimSuperAdminConversation(selectedConversationId, user?._id, (conversation) => {
            if (conversation) {
                setCloseIsOpen(false);
                setSelectedConversation(conversation);
                setSelectedConversationId(conversation._id);
                setMessages(conversation.messages);
            } else {
                setCloseIsOpen(false);
                setSelectedConversation(null);
                setSelectedConversationId(null);
                setMessages([]);
            }
        });
    }

    const handleGrantAccess = async () => {
        try {
            const response = await grantAccess({ companyId: selectedCompany?._id });
            if (response.status === 200) {
                filteredData();
                setSelectedCompany(selectedCompany => ({ ...selectedCompany, accessGranted: response?.data?.accessGranted }));
            }
        } catch (error) {
            console.error('Error granting access:', error)
        }
    }

    useEffect(() => {
        if (companyId) {
            const company = companies.find(company => company._id === companyId);
            if (company) {
                setSelectedCompany(company);
            }
            if (userId) {
                const users = companies.map(company => company.admins.concat(company.employees)).flat();
                const user = users.find(user => user._id === userId);
                if (user) {
                    setSelectedUser(user);
                    handleFindSuperAdminConversation();
                }
            }
        }
    }, [selectedUser, selectedCompany, companies]);

    // Fetch and filter companies based on search and checkbox
    const filterOptions = {};
    if (searchQuery) {
        filterOptions.query = searchQuery;
    }
    if (pendingAccess) {
        filterOptions.pendingAccess = true;
    } else {
        filterOptions.pendingAccess = false;
    }
    if (premium) {
        filterOptions.premium = true;
    } else {
        filterOptions.premium = false;
    }
    if (notPremium) {
        filterOptions.notPremium = true;
    } else {
        filterOptions.notPremium = false;
    }
    const filteredData = async () => {

        try {
            const res = await findCompanies(filterOptions); // Call the findCompanies API with filter options
            if (res.status === 200) {
                setCompanies(res.data); // Set companies from API response
            }
        } catch (error) {
            console.error('Error fetching companies:', error);
        }
    };

    const handlePendingAccess = () => {
        setPendingAccess(!pendingAccess);
    }
    const handlePremium = () => {
        setPremium(!premium);
    }

    const handleNotPremium = () => {
        setNotPremium(!notPremium)
    }

    // Fetch companies when searchQuery or isChecked changes
    useEffect(() => {
        filteredData();
    }, [searchQuery, isChecked, pendingAccess, premium, notPremium]);

    const handleEmailEveryone = async (values) => {
        values.languageSelected = selectedLanguage?.value;
        if (filterOptions) {
            values.filterOptions = filterOptions
        }
        try {
            const response = await emailEveryone(values);
            if (response.status === 200) {
                setEmailToEveryone(false);
            }
        } catch (error) {
            console.error('Error sending email:', error);
        }
    }

    const handleEmailSingleUser = async (values) => {
        values.id = selectedUser?._id;
        values.email = selectedUser?.email;
        try {
            const response = await emailSingleUser(values);
            if (response.status === 200) {
                setEmailToSingleUser(false);
            }
        } catch (error) {
            console.error('Error sending email:', error);
        }
    }

    const handleSendMessage = useCallback(({ message, images }) => {
        const text = message;
        const contentToSend = text
        const imgs = images
        createSuperAdminMessage(
            conversationIdRef.current,
            user?.companyId,
            contentToSend,
            'text',
            user?._id,
            imgs,
            (message, conversation) => {
                if (message) {
                    setTimeout(() => {
                        if (containerRef.current) {
                            containerRef.current.scrollTop = containerRef.current.scrollHeight;
                        }
                    }, 100);
                    setSelectedConversation(conversation)
                    setSelectedConversationId(conversation._id)
                }
            }
        );
    }, [user?._id])

    return (
        <div>
            <div className='flex items-center justify-content-between'>
                <div>
                    <SearchInput
                        value={searchQuery}
                        onChange={handleSearchChange}
                        placeHolder="Search Company"
                    />
                    <CheckBox
                        className='mt-md'
                        checked={pendingAccess}
                        label={'Show pending access companies (in the waitlist)'}
                        onChange={handlePendingAccess}
                    />
                    <CheckBox
                        className='mt-md'
                        checked={premium}
                        label={'Show premium companies'}
                        onChange={handlePremium}
                    />
                    <CheckBox
                        className='mt-md'
                        checked={notPremium}
                        label={'Show not premium companies'}
                        onChange={handleNotPremium}
                    />
                </div>
                <CallToAction
                    text={'Bulk Email'}
                    onClick={() => setEmailToEveryone(true)}
                />
            </div>

            <div style={{ marginTop: '15px' }}>
                {companies.length > 0 ? (
                    companies.map((company) => (
                        <SelectableBox
                            onClick={() => {
                                handleSelectedCompany(company);
                                addQueryParam('companyId', company._id);
                            }}
                        >
                            <FieldWrapper
                                noHr
                                label={company.companyName}
                            />
                            <FieldWrapper
                                noHr
                                label={company._id}
                            />
                            <FieldWrapper
                                noHr
                                label={company?.accessGranted ? 'Access Granted' : 'Access Pending'}
                            />
                        </SelectableBox>
                    ))
                ) : (
                    <p>No companies found</p>
                )}
            </div>

            <ConfirmModal
                width={'100%'}
                height={'100%'}
                isOpen={!isEmpty(selectedCompany)}
                toggle={() => {
                    setSelectedCompany(null);
                    removeQueryParams(['companyId']);
                }}
                confirmText={'Confirm'}
                cancelText={'Cancel'}
                onConfirm={() => console.log('Confirm')}
                onCancel={() => console.log('Cancel')}
            >
                <div className='w-100'>
                    <ConditionalRender renderIf={!selectedCompany?.accessGranted}>
                        <CallToAction
                            className='mb-md'
                            text={'Grant Access'}
                            onClick={handleGrantAccess}

                        />
                    </ConditionalRender>
                    <ConditionalRender renderIf={selectedCompany?.accessGranted}>
                        <FieldWrapper
                            noHr
                            label={'Access Granted'}
                        />
                    </ConditionalRender>
                    <FieldWrapper
                        noHr
                        label={selectedCompany?.companyName}
                        name='Company Name'
                    />
                    <FieldWrapper
                        noHr
                        label={selectedCompany?.companyIndustry}
                        name='Company Industry'
                    />
                    <ConditionalRender renderIf={!isEmpty(selectedCompany?.companyPhone)}>
                        <FieldWrapper
                            noHr
                            label={selectedCompany?.companyPhone}
                            name='Company Phone'
                            isPhone
                        />
                    </ConditionalRender>
                    <ConditionalRender renderIf={!isEmpty(selectedCompany?.companyAddress)}>
                        <FieldWrapper
                            noHr
                            label={selectedCompany?.companyAddress}
                            name='Company Phone'
                        />
                    </ConditionalRender>
                    <ConditionalRender renderIf={!isEmpty(selectedCompany?.admins)}>
                        <hr />
                        <h2 className='mt-md mb-md'>
                            {'Admins'}
                        </h2>
                        {
                            selectedCompany?.admins.map((admin) => (
                                <SelectableBox
                                    onClick={() => {
                                        setSelectedUser(admin);
                                        addQueryParam('userId', admin._id);
                                    }}
                                >
                                    <FieldWrapper
                                        label={`${admin.firstName} ${admin.lastName}`}
                                        name='Name'
                                    />
                                    <FieldWrapper
                                        label={admin.email}
                                        name='Email'
                                        isMail
                                    />
                                </SelectableBox>
                            ))
                        }
                    </ConditionalRender>
                    <ConditionalRender renderIf={!isEmpty(selectedCompany?.employees)}>
                        <hr />
                        <h2 className='mt-md mb-md'>
                            {'Employees'}
                        </h2>
                        {
                            selectedCompany?.employees.map((employee) => (
                                <SelectableBox
                                    onClick={() => {
                                        setSelectedUser(employee);
                                        addQueryParam('userId', employee._id);
                                    }}
                                >
                                    <FieldWrapper
                                        label={`${employee.firstName} ${employee.lastName}`}
                                        name='Name'
                                    />
                                    <FieldWrapper
                                        label={employee.email}
                                        name='Email'
                                    />
                                </SelectableBox>
                            ))
                        }
                    </ConditionalRender>
                    <ConditionalRender renderIf={!isEmpty(selectedCompany?.projects)}>
                        <hr />
                        <h2 className='mt-md mb-md'>
                            {'Projects'}
                        </h2>
                        {
                            selectedCompany?.projects.map((project) => (
                                <SelectableBox>
                                    <FieldWrapper
                                        label={project.projectName}
                                        name='Project Name'
                                    />
                                    <FieldWrapper
                                        label={project.projectStatus}
                                        name='Project Status'
                                    />
                                </SelectableBox>
                            ))
                        }
                    </ConditionalRender>
                </div>
            </ConfirmModal>
            <ConfirmModal
                width={'100%'}
                height={'100%'}
                isOpen={!isEmpty(selectedUser)}
                toggle={() => {
                    setSelectedUser(null);
                    removeQueryParams(['userId']);
                }}
                confirmText={'Confirm'}
                cancelText={'Cancel'}
                onConfirm={() => console.log('Confirm')}
                onCancel={() => console.log('Cancel')}
            >
                <div className={'w-100 flex items-center justify-content-between'}>
                    <div className='w-50'>
                        <CallToAction
                            className='mb-md'
                            text={'Send Email'}
                            onClick={() => setEmailToSingleUser(true)}
                        />
                        <FieldWrapper
                            label={
                                <img
                                    style={{
                                        width: '100px',
                                        height: '100px',
                                        objectFit: 'contain',
                                    }}
                                    src={selectedUser?.profilePhoto}
                                    alt='Profile'
                                />
                            }
                        />
                        <FieldWrapper
                            label={`${selectedUser?.firstName} ${selectedUser?.lastName}`}
                            name='Name'
                        />
                        <FieldWrapper
                            label={`${selectedUser?.id}`}
                            name='User ID'
                        />
                        <FieldWrapper
                            label={selectedCompany?.companyName}
                            name='Company Name'
                        />
                        <FieldWrapper
                            label={selectedUser?.email}
                            name='Email'
                        />
                        <FieldWrapper
                            label={selectedUser?.roleName}
                            name='Role'
                        />
                        <FieldWrapper
                            label={selectedUser?.languageSelected}
                            name='Language'
                        />
                        <FieldWrapper
                            label={selectedUser?.userPhone}
                            name='Phone'
                            isPhone
                        />
                        <FieldWrapper
                            label={selectedUser?.emergencyContact}
                            name='Emergency Contact'
                            isPhone
                        />
                        <FieldWrapper
                            label={selectedUser?.address}
                            name='Address'
                        />
                    </div>
                    <div
                        className='w-50 h-100 flex flex-column'
                    >
                        <div className='ml-md'>
                            <ConditionalRender renderIf={selectedConversation && selectedConversation?.status === 'open'}>
                                <FieldWrapper
                                    name={'Status'}
                                    label={selectedConversation?.status}
                                />
                                <ConditionalRender renderIf={selectedConversation?.status === 'open' && selectedConversation?.superAdmin?._id !== user?._id}>
                                    <p className='mb-md'>
                                        {`This conversation is currently being handled by ${selectedConversation?.superAdmin?.firstName} ${selectedConversation?.superAdmin?.lastName}`}
                                    </p>
                                </ConditionalRender>
                                <ConditionalRender renderIf={selectedConversation?.status === 'open' && selectedConversation?.superAdmin?._id === user?._id}>
                                    <CallToAction

                                        style={{
                                            color: colors.red
                                        }}
                                        hasIcon
                                        className='mb-md w-100 items-end'
                                        text={'Close Conversation'}
                                        onClick={() => setCloseIsOpen(true)}
                                    />
                                    <ConfirmModal
                                        isOpen={closeIsOpen}
                                        toggle={() => setCloseIsOpen(false)}
                                        text={'Yes'}
                                        onClick={() => closeSuperAdminConversation(selectedConversationId)}
                                    >
                                        {'Are you sure you want to close this conversation?'}
                                    </ConfirmModal>
                                </ConditionalRender>

                            </ConditionalRender>
                            <ConditionalRender renderIf={selectedConversation && !selectedConversation?.isClaimed}>
                                <CallToAction
                                    text={'Claim Conversation'}
                                    onClick={handleClaimSuperAdminConversation}
                                />
                            </ConditionalRender>
                            <ConditionalRender renderIf={selectedConversation?.status === 'closed'}>
                                <p>
                                    {'This conversation has been closed'}
                                </p>
                            </ConditionalRender>
                        </div>
                        <ChatWindow
                            user={user}
                            containerRef={containerRef}
                            firstUnreadRef={firstUnreadRef}
                            setAllRead={() => console.log('')}
                            allRead={true}
                            setFirstUnreadIndex={setFirstUnreadIndex}
                            firstUnreadIndex={firstUnreadIndex}
                            isProject
                        />
                        <ConditionalRender renderIf={selectedConversation?.status === 'open' && selectedConversation?.superAdmin?._id === user?._id}>
                            <RichEditorWithImageUploader
                                // onImageUpload={handleImageChange}
                                maxFiles={5}
                                customMessage={text?.inventory?.create?.form?.extraFields?.uploader?.title}
                                title={text?.chat?.message}
                                showForm={false}
                                onSubmit={(values) => handleSendMessage(values)}
                                text={text}
                                noGif
                                submitText={text?.tasks?.details?.button}
                                name="message"
                                isSendingMessage={isSendingMessage}
                                setIsSendingMessage={setIsSendingMessage}
                                noButton
                                // imagePreviews={imagePreviews}
                                // setImagePreviews={setImagePreviews}
                                // isDark={isClient}
                                hideSave
                            />
                        </ConditionalRender>
                    </div>
                </div>
            </ConfirmModal>
            <ConfirmModal
                width={'100%'}
                height={'100%'}
                isOpen={emailToEveryone}
                toggle={() => setEmailToEveryone(false)}
                text={'Send'}
                cancelText={'Cancel'}
                onSubmit={handleEmailEveryone}
                isForm
            >
                <div className='w-100'>
                    <h1>
                        {'Bulk Email'}
                    </h1>
                    <p className='mt-md'>
                        {'* this email will only be sent to the admins of the companies that match the filter options'}
                    </p>
                    <CreatableInput
                        className='mt-md'
                        initValue={selectedLanguage}
                        isSearchable={false}
                        menuPlacement={'bottom'}
                        isClearable
                        placeholder={'Select Language'}
                        name="languageSelected"
                        onChange={(event) => {
                            handleSelectChange(event);
                        }}
                        options={[
                            { value: 'en', label: 'English' },
                            { value: 'es', label: 'Spanish' },
                        ]}
                        style={{
                            width: '100%'
                        }}
                    />
                    <MultiFields
                        className='field-style'
                        name="subject"
                        component="input"
                        type="text"
                        label={'Subject'}
                        block
                        validate={required}
                    />
                    <MultiFields
                        className='field-style'
                        name="notification"
                        component="textarea"
                        type="text"
                        label={'Notification Message'}
                        block
                    />
                    <Field name="body" validate={required}>
                        {({ input }) => (
                            <>
                                <FieldWrapper
                                    noHr
                                    name={'Email Message'}
                                />
                                <RichEditorWithImageUploader
                                    noGif
                                    showForm={false}
                                    name="body"
                                    height={false}
                                    noKeyDown
                                    noButton
                                    onChange={(value) => input.onChange(value)}
                                />
                            </>
                        )}
                    </Field>
                </div>
            </ConfirmModal>
            <ConfirmModal
                width={'100%'}
                height={'100%'}
                isOpen={emailToSingleUser}
                toggle={() => setEmailToSingleUser(false)}
                text={'Send'}
                cancelText={'Cancel'}
                onSubmit={handleEmailSingleUser}
                isForm
            >
                <div className='w-100'>
                    <h1>
                        {'Send Email'}
                    </h1>
                    <p className='mt-md'>
                        {`* this email will be sent to ${selectedUser?.firstName} ${selectedUser?.lastName}`}
                    </p>
                    <MultiFields
                        className='field-style'
                        name="subject"
                        component="input"
                        type="text"
                        label={'Subject'}
                        block
                        validate={required}
                    />
                    <Field name="body" validate={required}>
                        {({ input }) => (
                            <>
                                <FieldWrapper
                                    noHr
                                    name={'Email Message'}
                                />
                                <RichEditorWithImageUploader
                                    noGif
                                    showForm={false}
                                    name="body"
                                    height={false}
                                    noKeyDown
                                    noButton
                                    onChange={(value) => input.onChange(value)}
                                />
                            </>
                        )}
                    </Field>
                </div>


            </ConfirmModal>
        </div>
    );
};

export default AdminContainer;
