import React, { useState, useEffect, useMemo } from 'react';
import queryString from "query-string";

import moment from 'moment';
import styled from 'styled-components';
import { Link, useLocation } from "react-router-dom";
import CallToAction from './CallToAction';
import MultiFields from './MultiFields';
import ProgressBar from './ProgressBar';
import ConditionalRender from './ConditionalRender';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretRight, faCaretLeft, faPencil } from '@fortawesome/free-solid-svg-icons';
import {
    updateLogs,
    getWeeklyTimeLog,
    updateDailyLog,
    getEmployee
} from '../utils/calls';
import SearchInput from './Inputs/SearchInput';
import HoverPopup from './HoverPopup';
import TimeEditForm from './Inputs/TimeEditForm';
import ConfirmModal from './ConfirmModal';
import { isEmpty } from 'lodash';
import colors from '../globalStyles.scss'
import { useTheme } from '../context/ThemeContext';
import { useLanguage } from '../context/LanguageContext';
import { useToast } from '../context/ToastContext';
import useScreenSize from '../context/useScreenSize';


const CalendarView = styled.div`
  display: flex;
  flex-direction: column;
  .buttons-container {
    button {
        background: ${props => props.theme === 'dark' ? colors.secondary : colors.black};
        svg {
            color: ${props => props.theme === 'dark' ? colors.black : colors.white};
        }
        padding: 1rem;
        &:hover {
            background: ${colors.lightGray};
            svg {
                color: ${colors.black};
            }
        }
    }
    button.next {
        margin-right: 1em;
    }
  }
`;

const WeekView = styled.div`
  display: flex;
`;

const DayColumn = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  border-right: 1px solid ${colors.darkGray};
  &:last-child {
    border-right: none;
}
  .wrapper {
    display: flex;
    flex-direction: column;
    padding: 1rem;
  }
`;

const DateHeader = styled.div`
  text-align: center;
  padding: 10px;
  background-color: ${props => props.theme === 'dark' ? colors.backgroundSecondary : colors.lightGray};
  span {
      font-family: ${colors.openSans};
  }
  display flex;
  align-items: center;
  justify-content: center;
`;

const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

const CalendarLogs = ({
    projects,
    timeLogs,
    transformTimesToEvents,
    getTitleFromMilliseconds,
    fetchTimeLogs,
    logsIsLoading,
    user
}) => {
    const { theme } = useTheme();
    const { text, formatDate } = useLanguage();
    const { notify } = useToast();
    const { isDesktop } = useScreenSize();
    const [employee, setEmployee] = useState({});

    const [selectedDay, setSelectedDay] = useState({});
    const location = useLocation();
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState('');
    const [selectedProject, setSelectedProject] = useState({})
    const [openProjectKey, setOpenProjectKey] = useState(null);

    const handleOpenDailyLog = (open, project, dayIdentifier) => {
        if (open) {
            setSelectedProject(project);
            setOpenProjectKey(project.id + '-' + dayIdentifier);
        } else {
            setOpenProjectKey(null);
        }
    };

    const handleSaveDailyLog = async ({ dailyLog }) => {
        const { id } = queryString.parse(location.search);

        try {
            const res = await updateDailyLog(selectedProject?.id, dailyLog, selectedProject?.clockIn, id);
            if (res.status === 200) {
                handleOpenDailyLog(false, {});
                fetchTimeLogs(id)
                notify(text?.notificationsUI?.timeLog?.saved, 'success');
            } else {
                notify(text?.notificationsUI?.timeLog?.error, 'error')
                console.error(res.message)
                handleOpenDailyLog(false, {});
            }

        } catch (error) {
            notify(`Error: ${error.message}`, 'error')
            console.error(error)
        }
    }

    useEffect(() => {
        const { id } = queryString.parse(location.search);
        const fetchEmployeeData = async () => {

            try {
                setIsLoading(true);
                const res = await getEmployee(id);
                if (res.status === 200) {
                    setEmployee(res.data);
                    setIsLoading(false);
                }
            } catch (error) {
                setError(text?.logs?.validations?.notAvailable);
                setIsLoading(false);
                console.error(error);
            }
        };
        if (id) {
            fetchEmployeeData();
        } else {
            setEmployee({})
        }
    }, [location.search]);



    useEffect(() => {
        const { id } = queryString.parse(location.search);
        fetchTimeLogs(id)
    }, [location.search])

    const [isOpen, setIsOpen] = useState(false)
    const [currentWeekStart, setCurrentWeekStart] = useState(moment().startOf('week'));

    const nextWeek = () => {
        setCurrentWeekStart(currentWeekStart.clone().add(1, 'weeks'));
    };

    const prevWeek = () => {
        setCurrentWeekStart(currentWeekStart.clone().subtract(1, 'weeks'));
    };

    const [weeklyTotalTime, setWeeklyTotalTime] = useState(0)
    useEffect(() => {
        const { id } = queryString.parse(location.search);

        const fetchWeeklyTime = async () => {
            try {
                const res = await getWeeklyTimeLog({
                    currentWeekStart,
                    id
                });
                if (res.status === 200) {
                    setWeeklyTotalTime(res.data?.totalTime)
                }
            } catch (error) {
                console.error(error)
            }
        }
        fetchWeeklyTime()

    }, [currentWeekStart, timeLogs])


    const openModal = (day, key) => {
        const { id } = queryString.parse(location.search);

        const newObj = projects?.reduce((acc, project) => {

            if (id) {
                if (
                    (
                        project.adminEmployees?.some((x) => x.id === user?.id) &&
                        (project.assignedEmployees?.some((x) => x.id === id) || project.adminEmployees?.some((x) => x.id === id))
                    ) ||
                    (user?.roleName === 'Admin' && project.assignedEmployees?.some((x) => x.id === id))
                ) {
                    acc[project.projectName] = {
                        id: project.id,
                        totalTime: day?.projects?.[project.projectName]?.totalTime || 0
                    };
                }
            } else {
                if (
                    user?.roleName === 'Admin' ||
                    (
                        project.adminEmployees?.some((x) => x.id === user?.id) ||
                        project.assignedEmployees?.some((x) => x.id === user?.id)
                    )
                ) {
                    acc[project.projectName] = {
                        id: project.id,
                        totalTime: day?.projects?.[project.projectName]?.totalTime || 0
                    };
                }
            }
            return acc;
        }, {});

        let filteredProjects = {};

        // Ensure only projects where the current user is an admin or assigned employee are included
        if (day && day.projects) {
            Object.keys(newObj).forEach(key => {
                // Check if the project key from newObj exists in day.projects and meets the criteria
                if (!day.projects.hasOwnProperty(key) || newObj.hasOwnProperty(key)) {
                    filteredProjects[key] = newObj[key];
                }
            });
        } else {
            filteredProjects = newObj;
        }

        const createDay = !day ? Object.assign({}, {
            projects: {
                ...filteredProjects
            },
            clockIn: key
        }) : { ...day, projects: filteredProjects };

        setIsOpen(!isOpen);
        setSelectedDay(createDay);
    };

    const closeModal = () => {
        setIsOpen(false)
        setSelectedDay({})
    }

    const onSubmit = async (values) => {
        const { id } = queryString.parse(location.search);

        // Transform the values into the desired format
        const logs = Object.keys(values)?.reduce((acc, key) => {
            const [type, projectId] = key.split('_');
            if (!acc[projectId]) {
                acc[projectId] = { hours: 0, minutes: 0 };
            }
            if (type === 'hours') {
                acc[projectId].hours += parseInt(values[key], 10);
            } else if (type === 'minutes') {
                acc[projectId].minutes += parseInt(values[key], 10);
            }
            return acc;
        }, {});

        const logsArray = Object.entries(logs).map(([projectId, timeData]) => ({
            projectId,
            totalTime: (timeData.hours * 60 + timeData.minutes) * 60000,
        }));


        try {
            // Use logsArray for the update
            const res = await updateLogs(logsArray, selectedDay?.clockIn, id);
            if (res.status === 200) {
                closeModal()
                fetchTimeLogs(id)
                window.location.reload();
                notify(text?.notificationsUI?.timeLog?.saved, 'success');
            }
        } catch (error) {
            notify(`Error: ${error.message}`, 'error')
            console.error(error);
        }
    };
    const [filteredSelectedDay, setFilteredSelectedDay] = useState(selectedDay);
    useMemo(() => {
        setFilteredSelectedDay(selectedDay);
    }, [selectedDay])
    const [query, setQuery] = useState('');

    const handleSearchChange = (e) => {
        const query = e.target.value.toLowerCase();
        setQuery(query);

        if (selectedDay?.projects) {
            const filteredProjects = Object.keys(selectedDay.projects)
                ?.filter(key => key.toLowerCase().includes(query))
                ?.reduce((result, key) => {
                    result[key] = selectedDay.projects[key];
                    return result;
                }, {});

            setFilteredSelectedDay(prevState => ({
                ...prevState,
                projects: filteredProjects
            }));
        } else {
            setFilteredSelectedDay(prevState => ({
                ...prevState,
                projects: {}
            }));
        }
    };

    // Function to transform timeLogs to a daily format for the current week
    const eventsForWeek = transformTimesToEvents(timeLogs);

    const renderWeek = () => {
        const currentWeekStartDate = new Date(currentWeekStart); // Ensure currentWeekStart is a Date object
        return daysOfWeek.map((day, index) => {
            const now = new Date();

            const todaysTime = new Date(now.getTime() - (now.getTimezoneOffset() * 60000)).toISOString().split('.')[0] + 'Z';

            const dayDate = new Date(currentWeekStartDate);
            dayDate.setDate(currentWeekStartDate.getDate() + index);
            const dayKey = new Date(Date.UTC(dayDate.getUTCFullYear(), dayDate.getUTCMonth(), dayDate.getUTCDate(), 0, 0, 0, 0)).toISOString();
            const dayValues = eventsForWeek[dayKey];
            const isRunning = dayValues?.isRunning;
            const isFutureDate = dayDate > new Date(todaysTime);


            return (
                <DayColumn key={day} theme={theme}>
                    <DateHeader theme={theme}>
                        <div className='flex items-center'>
                            <span className='mr-sm'>
                                {dayDate.toLocaleDateString('en-US', { month: 'short', day: 'numeric' })}
                            </span>
                            <ConditionalRender renderIf={!isRunning && !isFutureDate}>
                                <HoverPopup
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        background: 'transparent',
                                        border: 'none',
                                        hoverBackground: 'transparent',
                                        padding: '0'
                                    }}
                                    placement={'right'}
                                    id={`${day}_${dayValues?.clockIn ? new Date(dayValues.clockIn).toLocaleDateString('en-US', { month: 'numeric', day: 'numeric', year: 'numeric' }).replace(/\//g, '-') : ''}`}
                                    text={text?.logs?.edit}
                                >
                                    <FontAwesomeIcon
                                        onClick={() => openModal(dayValues, dayKey)}
                                        icon={faPencil} style={{
                                            color: theme === 'dark' ? colors.white : colors.black,
                                            cursor: 'pointer'
                                        }}
                                    />
                                </HoverPopup>
                            </ConditionalRender>
                        </div>
                    </DateHeader>

                    <div className='wrapper'>
                        <ConditionalRender renderIf={isEmpty(dayValues)}>
                            <p>
                                {text?.logs?.notTimeLogged}
                            </p>
                        </ConditionalRender>
                        <ConditionalRender renderIf={!isEmpty(dayValues)}>
                            <div>
                                <p>
                                    {text?.logs?.totalTimeLogged}
                                </p>
                                <p>
                                    {dayValues?.totalTimeText}
                                </p>
                            </div>

                            <ConditionalRender renderIf={!isEmpty(dayValues?.projects)}>
                                <hr />
                                <div className='flex flex-column mt-md '>
                                    <div className='flex flex-column '>
                                        {dayValues?.projects &&
                                            (() => {
                                                // Calculate the day's total time by summing all project times
                                                const dayTotalTime = Object.values(dayValues?.projects)?.reduce((sum, project) => sum + project.totalTime, 0);

                                                // Now map over the projects to create ProgressBar components
                                                return Object.entries(dayValues?.projects)?.map(([key, x]) => {
                                                    // Calculate the width percentage of this project against the day's total time
                                                    const widthPercentage = ((x.totalTime / dayTotalTime) * 100).toFixed(2);
                                                    const dayIdentifier = new Date(x?.clockIn).toISOString().split('T')[0];

                                                    return (
                                                        <ConditionalRender key={key} renderIf={x.totalTime !== 0}>
                                                            <div className='mb-md'>
                                                                <label className='b align-self-start'>
                                                                    {key}
                                                                </label>
                                                                <ProgressBar value={widthPercentage} maxValue={100} />
                                                                <p>
                                                                    {x?.projectTotalTime}
                                                                </p>
                                                            </div>
                                                            <CallToAction
                                                                type="button"
                                                                onClick={() => handleOpenDailyLog(true, x, dayIdentifier)}
                                                                text={text?.logs?.dailyLogs}
                                                                btnHeight={'25px'}
                                                                style={{
                                                                    width: '100%'
                                                                }}
                                                            />
                                                            <hr />

                                                            <ConfirmModal
                                                                text={text?.logs?.modal?.button}
                                                                toggle={() => handleOpenDailyLog(false, x, dayIdentifier)}
                                                                isOpen={openProjectKey === (x.id + '-' + dayIdentifier)}
                                                                btnStyles={{
                                                                    color: colors.blue
                                                                }}
                                                                projectId={x?.id}
                                                                onSubmit={handleSaveDailyLog}
                                                                isForm
                                                                width={isDesktop ? '50%' : '100%'}
                                                                height={isDesktop ? '50%' : '100%'}
                                                            >
                                                                <div className="editor w-100">
                                                                    <MultiFields
                                                                        name="dailyLog"
                                                                        component="textarea"
                                                                        label={`${text?.logs?.modal?.title} ${selectedProject?.projectName} - ${new Date(selectedProject?.clockIn).toLocaleDateString('en-US')}`}
                                                                        block
                                                                        initialValue={selectedProject?.dailyLog}
                                                                    />
                                                                </div>
                                                            </ConfirmModal>
                                                        </ConditionalRender>
                                                    )
                                                })
                                            })()
                                        }
                                    </div>
                                </div>
                            </ConditionalRender>
                        </ConditionalRender>
                    </div>
                </DayColumn>
            );
        });
    };



    return (
        <ConditionalRender isLoading={logsIsLoading && isLoading} renderIf={true}>
            <ConditionalRender renderIf={!isEmpty(employee)}>
                <div className='flex flex column items-center'>
                    <h3 className='flex'>
                        {`${employee.firstName} ${employee.lastName}`}
                    </h3>
                    <ConditionalRender renderIf={employee.roleName === 'Admin'}>
                        <p className='ml-md'>
                            {'Administator'}
                        </p>
                    </ConditionalRender>
                </div>
                <p className='flex mt-md'>
                    {text?.logs?.info}
                    <Link className='ml-sm underline' to={`/analytics`}>
                        {text?.logs?.title}
                    </Link>
                </p>
            </ConditionalRender>
            <div className='flex mt-md'>
                <span className='mr-md'>
                    {text?.logs?.totalTimeWeek}
                </span>
                <span>
                    {getTitleFromMilliseconds(weeklyTotalTime)}
                </span>
            </div>

            <CalendarView theme={theme}>
                <div className={'flex justify-end mb-md buttons-container'}>
                    <button className='next' onClick={prevWeek}>
                        <FontAwesomeIcon
                            icon={faCaretLeft} style={{
                                cursor: 'pointer'
                            }}
                        />
                    </button>
                    <button className='prev' onClick={nextWeek}>
                        <FontAwesomeIcon
                            icon={faCaretRight} style={{
                                cursor: 'pointer'
                            }}
                        />
                    </button>
                </div>
                <WeekView>
                    {renderWeek()}
                </WeekView>

                <ConfirmModal
                    onSubmit={onSubmit}
                    isForm
                    text={text?.logs?.editForm?.button}
                    toggle={closeModal}
                    isOpen={isOpen}
                    width={isDesktop ? '80%' : '100%'}
                    height={isDesktop ? '80%' : '100%'}
                    btnStyles={{
                        color: colors.blue
                    }}
                >
                    <div className='flex flex-column w-100'>
                        <SearchInput
                            className='mb-md mr-md'
                            value={query}
                            onChange={handleSearchChange}
                            placeHolder={text?.tasks?.home?.filters?.search}
                        />
                        <ConditionalRender renderIf={isEmpty(filteredSelectedDay?.projects)}>
                            <h4 className='flex justify-center items-center'>
                                {text?.logs?.noProjects}
                            </h4>
                        </ConditionalRender>
                        {
                            Object.entries(filteredSelectedDay?.projects || {}).map(([projectName, projectDetails]) => {
                                return (
                                    <TimeEditForm
                                        id={projectDetails?.id} // Use the project name as a key for React list rendering
                                        initialTimeInMilliseconds={projectDetails?.totalTime}
                                        label={projectName}
                                        clockIn={selectedDay?.clockIn}
                                    />
                                );
                            })
                        }
                    </div>

                </ConfirmModal>
            </CalendarView>
        </ConditionalRender>
    );
};

export default CalendarLogs;
