import { Link, useLocation } from 'react-router-dom';
import classnames from 'classnames'
import { isEmpty } from 'lodash';
import ConditionalRender from '../Core/ConditionalRender';
import styled from 'styled-components';
import colors from '../globalStyles.scss';

const StyledLi = styled.li`
    position: relative;
    .banner {
        position: absolute;
        font-size: 0.8em;
        background: ${colors.green};
        font-family: ${colors.roboto};
        border-radius: 5px;
        padding: 0.1em 0.5em;
        top: -6px;
        opacity: 0.8;
        right: -15px;'
        z-index: 0;
        }
`;

const NavLink = ({ to, children, logoutUser, isSub, onClick, banner, className = '' }) => {
    const location = useLocation();
    const isActive = location.pathname === to || location.pathname.startsWith(to + '/');
    const handleLogOut = () => {
        logoutUser();
    };

    if (to) {
        return (
            <StyledLi onClick={onClick && onClick} className={classnames(className, {
                active: isActive,
                sub: isSub
            })}>
                <Link to={to}>
                    {children}
                    <ConditionalRender renderIf={!isEmpty(banner)}>
                        <span className='banner'>{banner}</span>
                    </ConditionalRender>
                </Link>
            </StyledLi>
        );
    }

    if (logoutUser) {
        return (
            <li className='logout' onClick={() => {
                onClick && onClick()
                handleLogOut()
            }}>
                {children}
            </li>
        )
    }
};

export default NavLink