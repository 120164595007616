import React, { useCallback, useState, useEffect, useContext } from 'react';
import { useDropzone } from 'react-dropzone';
import Modal from "react-modal";
import { Carousel } from "react-responsive-carousel";
import CallToAction from './CallToAction';
import styled from 'styled-components'
import colors from '../globalStyles.scss'
import { useLanguage } from '../context/LanguageContext';
import { useTheme } from '../context/ThemeContext';
import ConditionalRender from './ConditionalRender';
import classnames from 'classnames';
import useScreenSize from '../context/useScreenSize';
import Wrapper from './Wrapper';
import SelectableBox from './SelectableBox';
import CarouselWithCustomArrows from './CarouselWithCustomArrows';
import { GlobalContext } from '../context/GlobalContext';
import { useToast } from '../context/ToastContext';


const StyledDiv = styled.div`
.no-header {
    .header-wrapper {
        background: transparent !important;
    }
}
.uploaded-images {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(${(props) => props.imageSize || '250px'}, 1fr));
    gap: 1%;
    margin-top: 1em;
    margin-bottom: 1em;
}

.dropzone {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0 1em;
    min-height: 40px;
    background: ${(props) => (props.theme === 'light' ? colors.blue : colors.secondary)};
    cursor: pointer;
    transition: all 0.2s ease 0s;
    p {
        font-family: ${colors.openSans};
        color: ${(props) => (props.theme === 'light' ? colors.white : colors.black)};
    }
    &:hover {
        background: ${colors.lightGray};
        p {
            color: ${colors.blue};
        }
    }
}

.image-wrapper {
    position: relative;
    padding: 0.5em;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    img {
        object-fit: cover;
        width: ${props => props.imageSize || '250px'};
        height: ${props => props.imageSize || '250px'};
        cursor: pointer;
    }
    .remove {
        position: absolute;
        top: 0;
        right: 0;
        background: ${colors.red};
        svg {
            color: ${colors.white};
            font-size: 1rem;
        }
    }

    /* Ensure all images maintain the same width */
    &:nth-last-child(1),
    &:nth-last-child(2),
    &:nth-last-child(3) {
        grid-column: span 1;
    }
}
`

const ImageUploader = ({
    onImageUpload,
    maxFiles,
    customMessage,
    title = '',
    children,
    showLightColors,
    isLoading = false,
    noHeader = false,
    isEditing = false,
    imageSize = '250px',
    customButton = null,
    isSendingMessage = false,
    imagePreviews = [],
    isDark = false,
    noButton = false,
    hideSave = false,
}) => {
    const { UPLOAD_LIMIT, MAX_IMAGES } = useContext(GlobalContext);
    const { notify } = useToast();

    const { text } = useLanguage();
    const { theme } = useTheme();
    const { isDesktop, isTablet, isPhone } = useScreenSize();

    const [uploadedImages, setUploadedImages] = useState([]);
    const [imageError, setImageError] = useState('');

    const [activeIndex, setActiveIndex] = useState(0);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = (index) => {
        setActiveIndex(index);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };
    const [currentImagesLength, setCurrentImagesLength] = useState(0);

    useEffect(() => {
        if (uploadedImages?.length >= UPLOAD_LIMIT) {
            setImageError(`${text?.projects?.create?.uploader?.validations?.upto} ${UPLOAD_LIMIT} ${text?.projects?.create?.uploader?.validations?.atAtime}`);
        }

        // Count only 'new' images
        const newImages = uploadedImages?.filter((image) => image.new);
        setCurrentImagesLength(newImages?.length);
    }, [uploadedImages]);


    useEffect(() => {
        if (currentImagesLength >= UPLOAD_LIMIT) {
            notify(`${text?.projects?.create?.uploader?.validations?.uploadUpTo} ${UPLOAD_LIMIT} ${text?.projects?.create?.uploader?.validations?.atAtime}`, 'warning');
            setImageError(`${text?.projects?.create?.uploader?.validations?.uploadUpTo} ${UPLOAD_LIMIT} ${text?.projects?.create?.uploader?.validations?.atAtime}`);
        }
    }, [currentImagesLength]);

    const onDrop = useCallback((acceptedFiles) => {
        const imageFiles = acceptedFiles.filter(file => file.type.startsWith('image/'));

        // If total uploaded images have reached MAX_IMAGES, block further uploads
        if (uploadedImages.length >= MAX_IMAGES) {
            setImageError(`${text?.projects?.create?.uploader?.validations?.upto} ${MAX_IMAGES} ${MAX_IMAGES > 1 ? text?.projects?.create?.uploader?.images : text?.projects?.create?.uploader?.image}`);
            return;
        }

        // If there are already UPLOAD_LIMIT new images, prevent further uploads
        const newImagesCount = uploadedImages.filter(img => img.new).length;
        if (newImagesCount >= UPLOAD_LIMIT) {
            setImageError(`${text?.projects?.create?.uploader?.validations?.uploadUpTo} ${UPLOAD_LIMIT} ${text?.projects?.create?.uploader?.validations?.atAtime}`);
            return;
        }

        // Determine the number of new images that can be uploaded while staying within UPLOAD_LIMIT
        const remainingSlots = Math.min(UPLOAD_LIMIT - newImagesCount, MAX_IMAGES - uploadedImages.length);
        const filesToUpload = imageFiles.slice(0, remainingSlots);

        if (filesToUpload.length === 0) return;

        const newImagesPromises = filesToUpload.map((file) => new Promise((resolve, reject) => {
            const reader = new FileReader();

            reader.onloadend = () => {
                resolve({ file, dataUrl: reader.result, originalName: file.name, new: true });
            };

            if (file) {
                reader.readAsDataURL(file);
            } else {
                reader.onerror = reject;
            }
        }));

        Promise.all(newImagesPromises)
            .then((newImages) => {
                setUploadedImages((prevImages) => {
                    const updatedImages = [...newImages, ...prevImages]; // Ensure new images are added at the top
                    onImageUpload(updatedImages.map(img => ({
                        dataUrl: img.dataUrl,
                        originalName: img.originalName,
                        new: img.new
                    })));
                    return updatedImages;
                });
                setImageError('');
            })
            .catch(() => setImageError(text?.projects?.create?.uploader?.validations?.error));
    }, [MAX_IMAGES, UPLOAD_LIMIT, onImageUpload, uploadedImages, text?.projects?.create?.uploader?.validations]);


    useEffect(() => {
        if (isSendingMessage) {
            setUploadedImages([]);
            setImageError('');
        }
    }, [isSendingMessage])

    const removeImage = (index) => {
        setUploadedImages((prevImages) => {
            const updatedImages = prevImages.filter((_, i) => i !== index);
            onImageUpload(updatedImages); // Pass the correct updated state
            return updatedImages; // Ensure state updates properly
        });
    };



    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept: {
            'image/jpeg': ['.jpeg', '.jpg'],
            'image/png': ['.png'],
            'image/gif': ['.gif'],
            'image/bmp': ['.bmp'],
            'image/svg+xml': ['.svg']
        },
        multiple: true,
    });

    return (
        <StyledDiv
            className={classnames({
                'image-uploader': true,
                desktop: isDesktop,
                tablet: isTablet,
                phone: isPhone
            })}
            theme={theme}
            noHeader={noHeader}
            imageSize={imageSize}
        >
            <Wrapper
                className={classnames({
                    'no-header': noHeader
                })}
                isLoading={isLoading}
                width={'100%'}
                title={title}
                showLightColors={showLightColors}
                bodyClassName={''}
                isDark={isDark}
                button={
                    <ConditionalRender renderIf={!noButton}>
                        <ConditionalRender renderIf={!customButton}>
                            <div {...getRootProps()} className='dropzone'>
                                <input {...getInputProps()} />
                                <ConditionalRender renderIf={!customMessage}>
                                    <p>
                                        {`${text?.projects?.create?.uploader?.title} ${maxFiles} ${maxFiles > 1 ? text?.projects?.create?.uploader?.validations?.atAtime : text?.projects?.create?.uploader?.image} ${text?.projects?.create?.uploader?.here}`}
                                    </p>
                                    <p>
                                        {text?.projects?.create?.uploader?.or}
                                    </p>
                                </ConditionalRender>
                                <ConditionalRender renderIf={customMessage}>
                                    <p>
                                        {customMessage}
                                    </p>
                                </ConditionalRender>
                            </div>
                        </ConditionalRender>
                        <ConditionalRender renderIf={customButton}>
                            {customButton}
                        </ConditionalRender>
                    </ConditionalRender>
                }
            >
                {imageError && <p style={{ color: colors.red }} className='error small mt-md'>{imageError}</p>}

                <div className='uploaded-images'>
                    {uploadedImages.map((image, index) => (
                        <SelectableBox
                            padding={'0'}
                            className='w-100'
                            removeButton
                            onClickRemove={() => {
                                removeImage(index);
                            }}
                        >
                            <div
                                key={index}
                                onClick={() => openModal(index)}
                                className='image-wrapper mb-md'
                            >
                                <img src={image.dataUrl} alt={`Uploaded ${index}`} className='uploaded-image' />
                            </div>
                        </SelectableBox>
                    ))}
                </div>

                <Modal
                    isOpen={isModalOpen}
                    onRequestClose={closeModal}
                    style={{
                        overlay: {
                            backgroundColor: colors.backgroundOverlay,
                        },
                        content: {
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            overflow: "auto",
                            WebkitOverflowScrolling: "touch",
                            borderRadius: "4px",
                            outline: "none",
                            padding: "20px",
                            width: '100vw',
                            height: '100vh',
                            background: 'transparent',
                            display: 'flex',
                            flexDirection: 'column'
                        },
                    }}
                >
                    <CarouselWithCustomArrows
                        images={uploadedImages}
                        activeIndex={activeIndex}
                        setActiveIndex={setActiveIndex}
                        hideSave={hideSave}
                    />
                    <CallToAction
                        text={'Close'}
                        onClick={closeModal}
                        style={{
                            marginTop: '15px',
                            alignSelf: 'start'
                        }}
                    />
                </Modal>
                <ConditionalRender renderIf={children}>
                    {children}
                </ConditionalRender>
            </Wrapper>
        </StyledDiv >
    );
};

export default ImageUploader;
