import './setupConsole'; // Import this first

import React from 'react';

import './App.css';
import Router from './Router/Router';
import { GlobalProvider } from './context/GlobalContext';
import { TimerProvider } from './context/TimeContext';
import { RegisterProvider } from './context/RegisterContext';
import Favicon from 'react-favicon';
import logo from './images/ardidflow_logo_small.png';


import 'bootstrap/dist/css/bootstrap.min.css';

import './globalStyles.scss'

import { ThemeProvider } from './context/ThemeContext';
import { ToastProvider } from './context/ToastContext';
import { LanguageProvider } from './context/LanguageContext';

function App() {

  return (
    <div className="App">
      <Favicon url={logo} />
      <GlobalProvider>
        <LanguageProvider>
          <ThemeProvider>
            <ToastProvider>
              <TimerProvider>
                <RegisterProvider>
                  <Router />
                </RegisterProvider>
              </TimerProvider>
            </ToastProvider>
          </ThemeProvider>
        </LanguageProvider>
      </GlobalProvider>
    </div>
  );
}

export default App;
