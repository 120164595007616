import requestNoTimeOut from '../requestNoTimeOut';

/**
 *
 * @returns {Promise<Object>} - A Promise that resolves to the API response data.
 * @throws {Error} - If the API request fails, an Error is thrown.
 */
export const getQueryResults = async (extractedCode, userId) => {
    try {
        const { data, status } = await requestNoTimeOut('POST', '/search/get-query-results', { extractedCode, userId });
        return { data, status };
    } catch (error) {
        throw new Error(` ${error.message}`);
    }
};

export default getQueryResults;
