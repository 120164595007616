import React, { useState, useEffect } from 'react'
import queryString from 'query-string'
import { useLocation } from "react-router-dom";

import ConditionalRender from '../Core/ConditionalRender'
import { getSingleResource } from '../utils/calls'
import NotFound from '../Home/NotFound'
import Wrapper from '../Core/Wrapper'
import { useLanguage } from '../context/LanguageContext';
import classnames from 'classnames'
import he from 'he';
import styled from 'styled-components'
import { isEmpty } from 'lodash'

const StyledDiv = styled.div`
    padding: 20px;
    height: 100%;
    strong {
        font-weight: bolder;
    }
    p, ul, li ol {
        margin: 0.5em 0;
        text-align: left;
    }
    ul {
        list-style-type: disc;
        margin-left: 2em;
        li {
            margin: 0.5em 0;
        }
    }
    h1 {
        font-size: 2em;
        text-align: left;
    }
    h2 {
        font-size: 1.5em;
        text-align: left;
    }
    h3, h4, h5, h6 {
        font-size: 1.17em;
        text-align: left;
    }
    .body {
        display: flex;
        flex-direction: column;
        align-items: baseline;
        width: 100%;
    }
`


const DetailsResourcesContainer = ({
    isLoggedIn
}) => {
    const location = useLocation();
    const { language } = useLanguage()
    const queryId = queryString.parse(location.search).id;
    const [resource, setResource] = useState(null)
    const [loading, setLoading] = useState(true)
    const [resourceId, setResourceId] = useState(null)

    useEffect(() => {
        setResourceId(queryString.parse(location.search).id)
    }, [queryId])

    const fetchResource = async () => {
        setLoading(true)
        try {
            const res = await getSingleResource(resourceId, language)
            if (res.status === 200) {
                setResource(res.data)
                if (res.data.message === 'Resource not found or does not match the selected language') {
                    setResource(null)
                }
                setLoading(false)
            }
        } catch (error) {
            setLoading(false)
            console.error('Error fetching resource:', error)
        }
    }

    useEffect(() => {
        fetchResource()
    }, [resourceId])

    return !loading && isEmpty(resource) ? <NotFound /> : (
        <ConditionalRender renderIf={resource} isLoading={loading}>
            <StyledDiv
                className={classnames({
                    'DetailsResourcesContainer': true,
                    'scroll-container': !isLoggedIn,
                })}
            >
                <Wrapper
                    title={resource?.title}
                >
                    <ConditionalRender renderIf={resource?.body}>
                        <div
                            className="flex flex-column w-100 DetailsResourcesContainer__body"
                            dangerouslySetInnerHTML={{
                                __html: he.decode(resource?.body || ''),
                            }}
                        />
                    </ConditionalRender>
                </Wrapper>
            </StyledDiv>
        </ConditionalRender>
    )
}

export default DetailsResourcesContainer
