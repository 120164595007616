import React, { useState } from 'react';
import FieldWithValidation from './FieldWithValidation';
import styled from 'styled-components'
import TextAreaField from './Inputs/TextAreaField';
import TelephoneInput from './Inputs/TelephoneInput';
import SelectDate from './Inputs/SingleDateInput';
import SelectTime from './Inputs/TimeInput';
import AmountInput from './Inputs/AmountInput';
import LengthInput from './Inputs/LengthInput';
import WeightInput from './Inputs/WeightInput';
import VolumeInput from './Inputs/VolumeInput';
import InternalLocationSearchInput from './Inputs/LocationSearchInput';
import CodeEditorField from './Inputs/CodeEditorField';
import { useTheme } from '../context/ThemeContext';
import colors from '../globalStyles.scss'

import './styles.scss'

const StyledFormGroup = styled.div`
    .form-group {
        margin: ${(props) => props.styles ? props.styles.margin : '0'};
        div {
        }
        .toggle-password-visibility {
            position: absolute;
            right: -30px;
            top: 0;
            cursor: pointer;
        }
    }
    input:focus,
    textarea:focus {
      outline: 0;
    }
    input:focus ~ label,
    textarea:focus ~ label {
    font-size: 0.75rem;
    top: -10px;
    -webkit-transition: all 0.225s ease;
    transition: all 0.225s ease;
    }
    .rc-time-picker-input {
        height: 35px;
        color: ${(props) => props.theme === 'dark' ? colors.white : colors.black};
    }
`

const MultiFields = ({
    label,
    name,
    component,
    type,
    fieldValue,
    validate,
    width,
    showPassword,
    togglePasswordVisibility,
    style,
    block,
    setIsEditing,
    edit,
    fieldName,
    ...rest
}) => {
    const { theme } = useTheme()
    const [value, setValue] = useState(fieldValue || '')
    return (
        <StyledFormGroup
            styles={style}
            value={value}
            theme={theme}
            className='MultiFields'
            style={{
                width: width ? `${width}%` : '100%',
                display: !block ? `flex` : 'block',
                ...style
            }}
        >

            {
                component === 'textarea' ? (
                    <TextAreaField
                        name={name}
                        label={label}
                        setIsEditing={setIsEditing}
                        validate={validate}
                        {...rest}
                    />
                ) : (component === 'phone') ? (
                    <TelephoneInput
                        name={name}
                        label={label}
                        setIsEditing={setIsEditing}
                        {...rest}
                    />
                ) : (component === 'date') ? (
                    <SelectDate
                        name={name}
                        label={label}
                        setIsEditing={setIsEditing}
                        {...rest}
                    />
                ) : (component === 'time') ? (
                    <SelectTime
                        name={name}
                        label={label}
                        setIsEditing={setIsEditing}
                        defaultValue='00:00'
                        {...rest}
                    />
                ) : (component === 'amount') ? (
                    <AmountInput
                        name={name}
                        label={label}
                        setIsEditing={setIsEditing}
                        {...rest}
                    />
                ) : (component === 'length') ? (
                    <LengthInput
                        name={name}
                        label={label}
                        setIsEditing={setIsEditing}
                        {...rest}
                    />
                ) : (component === 'weight') ? (
                    <WeightInput
                        name={name}
                        label={label}
                        setIsEditing={setIsEditing}
                        {...rest}
                    />
                ) : (component === 'volume') ? (
                    <VolumeInput
                        name={name}
                        label={label}
                        setIsEditing={setIsEditing}
                        {...rest}
                    />
                ) : (component === 'code') ? (
                    <CodeEditorField
                        name={name}
                        label={label}
                        setIsEditing={setIsEditing}
                        {...rest}
                    />
                ) : (component === 'location') ? (
                    <InternalLocationSearchInput
                        name={name}
                        label={label}
                        setIsEditing={setIsEditing}
                        {...rest}
                    />
                ) : (component !== 'time') ? (
                    <FieldWithValidation
                        label={label}
                        name={name}
                        validate={validate}
                        setIsEditing={setIsEditing}
                        type={component} // "component" prop is used to set the "type" here based on your previous code
                        showPassword={name === 'password' && showPassword}
                        togglePasswordVisibility={togglePasswordVisibility}
                        allowNegatives={component === 'number'}
                        fieldName={fieldName}
                        {...rest}
                    />

                ) : null
            }

        </StyledFormGroup >
    )
}

export default MultiFields