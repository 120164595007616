import React, { useState, useEffect } from 'react'
import ConditionalRender from '../Core/ConditionalRender'
import { Form } from 'react-final-form';
import FormActions from '../Core/FormActions';
import CallToAction from '../Core/CallToAction'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faCircle, faUser } from '@fortawesome/free-solid-svg-icons';
import { isEmpty } from 'lodash'
import ImageUploader from '../Core/ImageUploader';
import {
    forgotPassword,
    sendVerificationEmail,
    updateUser
} from '../utils/calls'
import { composeValidators, isRequired, isValidEmail } from '../utils/validators';
import MultiFields from '../Core/MultiFields';
import { Link } from "react-router-dom";
import Switch from '@mui/material/Switch';
import colors from '../globalStyles.scss'
import { useTheme } from '../context/ThemeContext'
import { useLanguage } from '../context/LanguageContext';
import { useToast } from '../context/ToastContext'
import base64ToBlob from '../utils/base64ToBlob';

import { uploadUserProfilePhoto } from '../utils/calls'

const ProfileContainer = ({
    fetchUserDetails,
    user,
    company,
    userRole
}) => {
    const { notify } = useToast()
    const [isEditing, setIsEditing] = useState(false);
    const [disabled, setDisabled] = useState(true);
    const { theme } = useTheme();
    const { text } = useLanguage();
    const [showForgotPassword, setShowForgotPassword] = useState(false);
    const [wrongEmail, setWrongEmail] = useState(false);
    const [emailSent, setEmailSent] = useState(false);
    const [verificationEmailSent, setVerificationEmailSent] = useState(false);
    const [error, setError] = useState('');
    const [profileIsLoading, setProfileIsLoading] = useState(false);


    const onSubmit = async (values) => {
        try {
            const res = await updateUser(values);
            if (res.status === 200) {
                fetchUserDetails()
                setDisabled(!disabled)
                notify(text?.notificationsUI?.profile?.updated, 'success')
            }
        } catch (error) {
            notify(`Error: ${error.message}`, 'error')
            setError(error.message);
            console.error(error)
        }
    }

    const submitPasswordReset = async () => {
        try {
            const res = await forgotPassword(user?.email);
            if (res.status === 200) {
                setEmailSent(true);
                setWrongEmail(false)
                notify(text?.notificationsUI?.profile?.resetPassword, 'success')
            } else {
                notify(text?.notificationsUI?.profile?.errorResetPassword, 'error')
                setEmailSent(false);
                setWrongEmail(true)
            }
        } catch (error) {
            notify(`Error: ${error.message}`, 'error')
            setEmailSent(false);
            setWrongEmail(true)
        }
    }

    const handleSendVerificationEmail = async () => {
        try {
            const res = await sendVerificationEmail(user?.email, user?.id);
            if (res.status === 200) {
                notify(text?.notificationsUI?.profile?.verifyEmail, 'success')
                setVerificationEmailSent(true)
            } else {
                notify(text?.notificationsUI?.profile?.errorVerifyEmail, 'error')
                setVerificationEmailSent(false)
            }
        } catch (error) {
            notify(`Error: ${error.message}`, 'error')
            console.error(error)
            setVerificationEmailSent(false)
        }
    }

    const emailValidator = composeValidators(
        isRequired(text?.profile?.validation?.required),
        isValidEmail(text?.profile?.validation?.validEmail),
    )
    const required = composeValidators(
        isRequired(text?.settings?.validation?.required),
    )

    const handleProfileImage = async (imageData) => {
        setProfileIsLoading(true);
        try {
            const image = imageData[0];
            const imageBlob = base64ToBlob(image?.dataUrl); // Convert base64 data to a Blob
            const formData = new FormData();
            formData.append("image", imageBlob, image?.originalName); // Add the Blob to FormData, along with a file name
            console.log("Uploading image...");
            return uploadUserProfilePhoto(formData).then((imageRes) => {
                if (imageRes?.status === 200) {
                    console.log("Image uploaded");
                    setProfileIsLoading(false);
                    fetchUserDetails()
                    notify(text?.notificationsUI?.profile?.imageUpdated, 'success')
                } else {
                    setProfileIsLoading(false);
                    notify(text?.notificationsUI?.profile?.errorImageUpdated, 'error')
                    throw new Error("Image upload failed");
                }
            });
        } catch (error) {
            setProfileIsLoading(false);
            console.error(error)
        }

    }

    return (
        <div className='ProfileContainer' style={{
            padding: '10px',
            display: 'flex',
            flexDirection: 'column',
        }}>
            <div style={{
                display: 'flex',
                justifyContent: 'start',
                alignItems: 'center',
                fontSize: '0.8rem'
            }}>
                <span style={{
                    fontFamily: colors.roboto
                }}>
                    {text?.profile?.memberId}
                </span>
                <span style={{
                    fontFamily: colors.roboto
                }}>
                    {'AF-'}{user.id.slice(-6)}
                </span>
            </div>

            <div style={{
                display: 'flex',
                alignSelf: 'end',
                alignItems: 'center'
            }}>
                <h4>
                    {text?.profile?.title}
                </h4>
                <FontAwesomeIcon
                    style={{
                        marginRight: '15px',
                        marginLeft: '15px',
                        fontSize: '3rem',
                    }}
                    icon={faUser}
                />
            </div>
            <div className='flex'>
                <div className='flex-one'>
                    <Form
                        onSubmit={onSubmit}
                        keepDirtyOnReinitialize
                        keepValuesOnReinitialize
                        initialValues={{
                            firstName: user?.firstName,
                            lastName: user?.lastName,
                            email: user?.email
                        }}

                        render={({ handleSubmit }) => (
                            <form onSubmit={handleSubmit}>
                                <MultiFields
                                    className='field-style'
                                    name="firstName"
                                    label={text?.profile?.fields?.firstName}
                                    component="input"
                                    type="text"
                                    setIsEditing={setIsEditing}
                                    validate={required}
                                    block
                                />
                                <MultiFields
                                    className='field-style'
                                    name="lastName"
                                    label={text?.profile?.fields?.lastName}
                                    component="input"
                                    type="text"
                                    setIsEditing={setIsEditing}
                                    validate={required}
                                    block
                                />
                                <MultiFields
                                    className='field-style'
                                    name="email"
                                    label={text?.profile?.fields?.email}
                                    component="input"
                                    subText={<div style={{
                                        alignSelf: 'flex-start',
                                        marginTop: '0.5em'
                                    }}>
                                        {
                                            user?.isAccountVerified ? (
                                                <div className='flex'>
                                                    <FontAwesomeIcon
                                                        style={{
                                                            color: theme === 'dark' ? colors.secondary : colors.green,
                                                            marginRight: '5px'
                                                        }} icon={faCheck} />
                                                    <span style={{ fontWeight: 'bold', fontFamily: colors.openSans }}> {text?.profile?.accountVerified} </span>
                                                </div>
                                            ) : (
                                                <div className='flex' onClick={handleSendVerificationEmail}>
                                                    <FontAwesomeIcon style={{ color: colors.red, marginRight: '5px' }} icon={faCircle} />
                                                    {
                                                        verificationEmailSent ? (
                                                            <p style={{
                                                                cursor: 'pointer',
                                                                fontWeight: 'bold',
                                                                fontFamily: colors.openSans
                                                            }}>
                                                                {text?.profile?.verificationEmailSent}
                                                            </p>
                                                        ) : (
                                                            <p style={{
                                                                cursor: 'pointer',
                                                                fontWeight: 'bold',
                                                                fontFamily: colors.openSans
                                                            }}>
                                                                {text?.profile?.verifyEmail}
                                                            </p>
                                                        )
                                                    }
                                                </div>
                                            )
                                        }
                                    </div>}
                                    type="text"
                                    setIsEditing={setIsEditing}
                                    validate={emailValidator}
                                    block
                                    disabled={user?.isAccountVerified}
                                />

                                {
                                    !isEmpty(error) && (
                                        <div className={'error'}>
                                            {error}
                                        </div>
                                    )
                                }
                                {
                                    (isEditing) && (
                                        <FormActions
                                            type="submit"
                                            submitText={text?.profile?.button}
                                        />
                                    )
                                }
                            </form>
                        )}
                    />

                    {/* Profile Photo */}
                    <ConditionalRender isLoading={profileIsLoading} renderIf={true}>
                        <div className='profileImage mb-md'>

                            <h4 className='mb-md' style={{ textAlign: 'left' }}>
                                {text?.profile?.profileImage}
                            </h4>
                            <div className='flex items-center'>
                                <img className='mr-md' height='100' src={user?.profilePhoto} alt={`${user?.profilePhoto}`} />
                                <ImageUploader
                                    onImageUpload={handleProfileImage}
                                    maxFiles={1}
                                    customMessage={text?.profile?.uploadImage}
                                    noHeader
                                />
                            </div>
                        </div>
                    </ConditionalRender>
                </div>
            </div>
            <hr />
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'start'
                }}>
                {emailSent ?
                    <p>
                        {text?.profile?.emailSent}
                    </p> :
                    <CallToAction style={{ alignSelf: 'flex-start', flex: '1' }} onClick={(e) => submitPasswordReset(e)} text={text?.profile?.resetPassword} />
                }
                <ConditionalRender renderIf={!isEmpty(error)}>
                    <span className='error'>
                        {error}
                    </span>
                </ConditionalRender>
                <>
                </>
            </div>
        </div>
    )
}

export default ProfileContainer