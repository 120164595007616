import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import moment from 'moment'
import { Link } from 'react-router-dom';
import { composeValidators, isRequired, isValidEmail } from '../utils/validators';

import { Form } from 'react-final-form';
import MultiFields from '../Core/MultiFields';
import { deleteResource } from '../utils/calls';
import CallToAction from '../Core/CallToAction';
import FieldWrapper from '../Core/FieldWrapper';
import FormActions from '../Core/FormActions';
import { useNavigate } from 'react-router-dom';
import ConditionalRender from '../Core/ConditionalRender';
import { resourcesCategories } from '../utils/helperFunctions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose, faFilter } from '@fortawesome/free-solid-svg-icons';
import Loader from '../Core/Loader';
import CustomSelect from '../Core/CustomSelect';
import HoverPopup from '../Core/HoverPopup';
import SearchInput from '../Core/Inputs/SearchInput';
import { isEmpty } from 'lodash';
import colors from '../globalStyles.scss'
import { useLanguage } from '../context/LanguageContext';
import { useTheme } from '../context/ThemeContext'
import useScreenSize from '../context/useScreenSize';
import PaginatedData from '../Core/PaginatedData';
import classnames from 'classnames';
import { subscribeToNewsletter } from '../utils/calls'

const NewsletterSignupForm = ({ onSubmit, text, error }) => {

    const emailValidator = composeValidators(
        isRequired(text?.tasks?.create?.validations?.required),
        isValidEmail(text?.employees?.createNew?.validation?.validEmail)
    )

    return (
        <Form
            onSubmit={onSubmit}
            render={({ handleSubmit, form, submitting, pristine }) => (
                <form onSubmit={handleSubmit} className='flex items-center justify-center'
                >
                    <div className='mt-md mb-md'>
                        <p>
                            {text?.resources?.signup}
                        </p>

                        <MultiFields
                            validate={emailValidator}
                            name="email"
                            component="input"
                            type="email"
                            block
                            label={text?.registration?.fields?.email}
                            style={{
                                margin: '15px 0'
                            }}
                        />
                        <ConditionalRender renderIf={!isEmpty(error)}>
                            <p className='mt-md error'>
                                {error}
                            </p>
                            <hr />
                        </ConditionalRender>

                        <FormActions
                            type="submit"
                            onClick={form.reset}
                            submitText={text?.resources?.subscribe}
                            form={form}
                        />
                    </div>
                </form>
            )}
        />
    );
};



const StyledDiv = styled.div`
margin: 0 auto;
margni-bottom: 1em;
strong {
    font-weight: bold;
}

.error {
    color: ${colors.red};
    font-size: 0.8em;
}

&.desktop {
.resource__wrapper {
        position: relative;
        display: flex;
        flex-direction: column;
margin-top: 1em;
        background: ${props => props.theme === 'dark' ? colors.backgroundSecondary : colors.lightGray};        padding: 15px;
        margin-bottom: 15px;
        .date {
            margin-left: 15px;
            color: ${props => props.theme === 'dark' ? colors.white : colors.black};
        }
        .title {
            font-weight: bold;
            font-size: 1.5rem;
            text-align: left;
            font-family: ${colors.openSans};
            color: ${props => props.theme === 'dark' ? colors.white : colors.black};
        }
        .body {
            font-size: 1.1rem;
            font-family: ${colors.alegreya};
            text-align: left;
            line-height: 1.2;
            color: ${props => props.theme === 'dark' ? colors.white : colors.black};
        }
    }
}

&.tablet, &.phone {
.resource__wrapper {
        position: relative;
        display: flex;
        flex-direction: column;
        margin-top: 1em;
        background: ${props => props.theme === 'dark' ? colors.backgroundSecondary : colors.lightGray};
        padding: 15px;
        margin-bottom: 15px;
        .header {
            flex-direction: column;
        }
        .date {
            margin-left: 15px;
            color: ${props => props.theme === 'dark' ? colors.white : colors.black};
        }
        .title {
            font-weight: bold;
            font-size: 1em;
            text-align: left;
            font-family: ${colors.openSans};
            color: ${props => props.theme === 'dark' ? colors.white : colors.black};
        }
        .body {
            font-size: 1.1rem;
            font-family: ${colors.alegreya};
            text-align: left;
            line-height: 1.2;
            color: ${props => props.theme === 'dark' ? colors.white : colors.black};
        }
    }
    } 
`;

const ResourcesContainer = ({
    user,
    resources,
    resourcesIsLoading,
    fetchResources,
    isLoggedIn
}) => {
    const { theme } = useTheme();
    const { text, language } = useLanguage();
    const navigate = useNavigate();
    const { isDesktop, isTablet, isPhone } = useScreenSize();
    const [showFilters, setShowFilters] = useState(false);
    const [filters, setFilters] = useState({});
    const [tableData, setTableData] = useState([]);
    const [subscribed, setSubscribed] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (resources?.length === 0) {
            setTableData([]);
        } else {
            setTableData(resources);
        }
    }, [resources]);



    const handleDeleteResource = async (id) => {
        try {
            const res = await deleteResource(id);
            if (res.status === 200) {
                fetchResources()
            }
        } catch (error) {
            console.error(error)
        }
    }

    const handleSelectInputChange = (e, name) => {
        setFilters({ ...filters, [name]: e?.value });
    };

    const handleSearchChange = (e) => {
        setFilters({ ...filters, query: e?.target?.value });
    };

    const filterTableData = async () => {
        const filterOptions = {};

        if (filters.query) {
            filterOptions.query = filters.query.toLowerCase();
        }
        if (filters.category) {
            filterOptions.category = filters.category;
        }

        let filteredResource = resources;

        if (filterOptions.query) {
            filteredResource = filteredResource.filter(resource => resource?.title.toLowerCase().includes(filterOptions.query));
        }

        if (filterOptions.category) {
            filteredResource = filteredResource.filter(resource => resource?.category === filterOptions.category);
        }
        setTableData(filteredResource);
    }

    const handleNewsletterSubmit = async (values) => {
        values.language = language;
        try {
            const res = await subscribeToNewsletter(values);
            if (res.status === 200) {
                setSubscribed(true);
                setError(null);
            } else {
                setSubscribed(false);
                setError(res.data.message);
            }
        } catch (error) {
            let alreadySubscribed = error.message === "Failed to subscribe: Email is already subscribed to the newsletter";
            let errorMessage = error.message;
            if (language === 'es') {
                errorMessage = 'Error al suscribirse al boletín';
                if (alreadySubscribed) {
                    errorMessage = 'Error al suscribirse: El correo electrónico ya está suscrito al boletín';
                }
            }

            setSubscribed(false);
            console.error('Error subscribing to newsletter:', error?.message);
            setError(errorMessage);
        }
    };

    useEffect(() => {
        filterTableData();
    }, [filters, resources]);

    return (
        <div className={classnames('h-100', {
            'scroll-container': !isLoggedIn
        })}>
            {
                resourcesIsLoading ? <Loader count={resources.length + 1} customHeight={'200px'} /> :
                    (
                        <StyledDiv
                            theme={theme}
                            className={classnames({
                                'desktop': isDesktop,
                                'tablet h-100': isTablet,
                                'phone h-100': isPhone,
                            })}

                        >
                            {
                                resources.length === 0 && (
                                    <p className='mt-md'>
                                        {text?.footer?.noResources}
                                    </p>
                                )
                            }
                            {
                                user?.isSuperAdmin && (
                                    <div style={{
                                        display: 'flex',
                                        justifyContent: 'end',
                                        marginBottom: '15px'
                                    }}>
                                        <Link to={'/resources/create'}>
                                            {'Create'}
                                        </Link>
                                    </div>
                                )
                            }
                            <ConditionalRender renderIf={!isEmpty(resources)}>
                                <div className='flex flex-column mt-md ml-md'>
                                    <HoverPopup
                                        className='align-self-start'
                                        style={{
                                            marginRight: '1rem',
                                        }}
                                        onClick={() => setShowFilters(!showFilters)}
                                        placement={'top'}
                                        id='toggle-filters-popup-tasks'
                                        text={isDesktop && text?.inventory?.landing?.table?.showFilters}
                                    >
                                        <FontAwesomeIcon icon={faFilter} style={{ color: showFilters ? colors.yellow : colors.white }} />
                                    </HoverPopup>
                                    <ConditionalRender renderIf={showFilters}>
                                        <div
                                            className='filters mt-md'
                                            style={{
                                                flexWrap: 'wrap',
                                            }}>
                                            <SearchInput
                                                className='mt-md'
                                                width={isDesktop ? '50%' : isTablet ? '50%' : '100%'}
                                                value={filters.query}
                                                onChange={handleSearchChange}
                                                placeHolder={text?.tasks?.home?.filters?.search}
                                            />
                                            <CustomSelect
                                                isSearchable={false}
                                                className='mt-md'
                                                handleSelectInputChange={(e) => handleSelectInputChange(e, 'category')}
                                                options={resourcesCategories(text)}
                                                name={'category'}
                                                placeHolder={text?.inventory?.create?.form?.category}
                                            />
                                        </div>
                                    </ConditionalRender>
                                </div>
                            </ConditionalRender>
                            <ConditionalRender renderIf={resources.length > 0}>
                                <PaginatedData
                                    defaultItemsPerPage={3}
                                    paginationLocation={'bottom'}
                                    className='mt-md'
                                    data={tableData}
                                    dataIsLoading={resourcesIsLoading}
                                    noDataText={text?.footer?.noResources}
                                    searching={filters}
                                    showLightColors
                                    ItemComponent={({ item }) => {
                                        return (
                                            <div className='resource__wrapper'>
                                                <ConditionalRender renderIf={user?.isSuperAdmin}>
                                                    <FontAwesomeIcon
                                                        onClick={() => handleDeleteResource(item?.id)}
                                                        icon={faClose} style={{
                                                            color: colors.red,
                                                            cursor: 'pointer',
                                                            position: 'absolute',
                                                            right: '0',
                                                            top: '0'
                                                        }}
                                                    />
                                                </ConditionalRender>
                                                <div className={classnames({
                                                    'flex': true,
                                                    'items-center': !isPhone,
                                                    'flex-column': isPhone,
                                                })}>
                                                    <div className={classnames({
                                                        'w-25': !isPhone,
                                                        'mb-md': isPhone,
                                                        'mr-md': !isPhone,
                                                    })} style={{
                                                        height: isPhone ? '150px' : '100%',
                                                    }}>
                                                        <img style={{
                                                            width: '100%',
                                                            height: '100%',
                                                            objectFit: 'cover'
                                                        }} src={item?.images[0]?.url || 'https://i.ibb.co/XtFJ5jM/image-123650291.jpg'} alt="resource" />
                                                    </div>
                                                    <div className={classnames({
                                                        'w-75': !isPhone,
                                                    })}>
                                                        <div className='header'
                                                            style={{
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                marginBottom: '15px',
                                                                justifyContent: 'space-between'
                                                            }}
                                                        >
                                                            <div className='title'>
                                                                {item?.title}
                                                            </div>
                                                            <div className='date'> {moment(item?.createdAt).format('MM-DD-YYYY')} </div>
                                                        </div>
                                                        <FieldWrapper
                                                            name={text?.inventory?.create?.form?.category}
                                                            label={resourcesCategories(text).find(category => category.value === item?.category)?.label || ''}
                                                        />
                                                        <p
                                                            className="body"
                                                        >
                                                            {item?.description}
                                                        </p>
                                                        <CallToAction
                                                            className='mt-md'
                                                            text={text?.resources?.readMore}
                                                            onClick={() => navigate(`/resources/details?id=${item?._id}`)}
                                                            style={{
                                                                alignSelf: 'start',
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }}
                                />
                            </ConditionalRender>
                            <hr />
                            <ConditionalRender renderIf={!subscribed}>
                                <NewsletterSignupForm onSubmit={handleNewsletterSubmit} text={text} error={error} />
                            </ConditionalRender>
                            <ConditionalRender renderIf={subscribed}>
                                <p className='mb-md mt-md'>
                                    {'Thank you for subscribing to our newsletter!'}
                                </p>
                                <hr />
                            </ConditionalRender>

                        </StyledDiv>
                    )
            }
        </div >
    )


}

export default ResourcesContainer