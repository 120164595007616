import React, { useState, useMemo } from 'react'
import { useNavigate } from "react-router-dom";
import CallToAction from '../Core/CallToAction';
import TasksContainer from '../Tasks/TasksContainer';
import RenderImages from '../Core/RenderImages';
import { isEmpty } from 'lodash';
import queryString from "query-string";
import { useLocation } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHistory } from '@fortawesome/free-solid-svg-icons';
import ConditionalRender from '../Core/ConditionalRender';
import FieldWrapper from '../Core/FieldWrapper';
import { GoogleMap, MarkerF, InfoWindowF } from '@react-google-maps/api';
import InventoryHomeContainer from '../Invetory/InventoryHomeContainer';
import ExpandedContainer from '../Expanded/ExpandedContainer';
import Wrapper from '../Core/Wrapper';
import { useLanguage } from '../context/LanguageContext';
import EmployeesContainer from '../Employees/EmployeesContainer';

import RenderFiles from '../Core/RenderFiles';
import TimeLogContainer from '../TimeLog/TimeLogContainer';
import FullCalendarContainer from '../Calendar/FullCalendar';
import colors from '../globalStyles.scss';
import useScreenSize from '../context/useScreenSize';
import { useTheme } from '../context/ThemeContext';
import {
    downloadProjectFile
} from '../utils/calls';
import { saveAs } from 'file-saver';

const DetailsProjectEmployeeContainer = ({
    project,
    projects,
    user,
    employees,
    tasks,
    tasksIsLoading,
    fetchProjectTasks,
    fetchInventoryProducts,
    inventory,
    inventorysLoading,
    inventoryColumns,
    setInventoryColumns,
    fetchAttributes,
    projectId,
    autocompleteValue,
    mapCenter,
    onLoad,
    designs,
    openCarousel,
    closeModal,
    setActiveIndex,
    activeIndex,
    isModalOpen,
    timeLogs,
    userRole,
    projectIsComplete,
    fetchTimeLogs,
    logsIsLoading,
    events,
    eventsIsLoading,
    fetchEvents,
    projectIsLoading,
    employeesIsLoading,
    company,
    fetchCompanyEmployees,
    fetchEventCategories,
    setHistoryStatus,
    historyStatus
}) => {
    const { text, formatDate } = useLanguage()
    const { theme } = useTheme()
    const { isDesktop, isTablet } = useScreenSize()
    const navigate = useNavigate()
    const location = useLocation();
    const [selectedEmployees, setSelectedEmployees] = useState([]);

    const [expanded, setExpanded] = useState('');
    const [infoWindowOpen, setInfoWindowOpen] = useState(true);

    const EmployeeIsAdmin = user?.roleName === 'Employee' && project?.adminEmployees?.some((x) => x.id === user?._id)
    const id = projectId || queryString.parse(location.search)?.id



    const handleMarkerClick = () => {
        setInfoWindowOpen(!infoWindowOpen); // Toggle InfoWindow open/close state
    };

    const handleDownloadFile = async (e, file) => {
        e.preventDefault();
        e.stopPropagation();
        try {
            const res = await downloadProjectFile({ projectId: project?.id, file });
            if (res.status === 200) {
                const contentType = res.headers['content-type'];
                const blob = new Blob([res.data], { type: contentType });
                saveAs(blob, file.originalName);
            } else {
                throw new Error('Failed to download file');
            }
        } catch (error) {
            console.error('Error downloading file:', error);
        }
    };

    useMemo(() => {
        setSelectedEmployees(project?.assignedEmployees?.map((x) => x?.id))
    }, [project]);

    return (
        <>
            <div className='mb-lg tx-left'>
                <h4>
                    {text?.projects?.details?.details}
                </h4>
                <hr />
                <FontAwesomeIcon
                    onClick={() => setHistoryStatus(!historyStatus)}
                    style={{
                        color: theme === 'dark' ? colors.secondary : colors.blue,
                        cursor: 'pointer',
                        marginRight: '1em'
                    }}
                    icon={faHistory}
                />
            </div>
            <div className={isDesktop ? 'desktop flex' : isTablet ? 'tablet' : 'phone'}
            >
                <Wrapper
                    flex={'1'}
                    padding={isDesktop && '0 15px 0 0'}
                    width={isDesktop ? '50%' : '100%'}
                    borderRight={isDesktop && `
                        ${theme === 'dark' ? colors.backgroundSecondary : colors.black} 1px solid
                    `}
                >
                    <Wrapper>
                        <FieldWrapper
                            name={'Name'}
                            label={project?.projectName}
                        />
                        <FieldWrapper
                            name={'Category'}
                            label={project?.projectCategory?.label}
                        />
                        <div className='flex flex-column mb-md'>
                            <ConditionalRender renderIf={!isEmpty(autocompleteValue)}>
                                <FieldWrapper
                                    name={text?.projects?.details?.location}
                                // label={autocompleteValue}
                                />
                                <GoogleMap
                                    mapContainerStyle={{
                                        width: '100%',
                                        height: '400px',
                                        marginTop: '15px'
                                    }}
                                    center={mapCenter}
                                    onLoad={onLoad}
                                    mapTypeId="hybrid"
                                    zoom={18}
                                    options={{
                                        mapTypeId: 'hybrid', // Explicitly set the map type here
                                        disableDefaultUI: false, // disable default map UI
                                        draggable: true, // disable dragging (panning)
                                        keyboardShortcuts: false, // disable keyboard shortcuts
                                        scaleControl: false, // disable scaling (zooming in/out) control
                                        scrollwheel: false, // disable scrolling (zooming in/out) control
                                        zoomControl: true, // disable zoom control buttons
                                        fullscreenControl: false,
                                        streetViewControl: false,
                                        version: false,
                                        styles: [
                                            {
                                                featureType: 'all',
                                                elementType: 'labels',
                                                stylers: [{ visibility: 'off' }] // Hide all labels
                                            },
                                            {
                                                featureType: 'landscape',
                                                elementType: 'geometry',
                                                stylers: [{ visibility: 'off' }] // Hide landscapes
                                            },
                                            {
                                                featureType: 'poi',
                                                elementType: 'geometry',
                                                stylers: [{ visibility: 'off' }] // Hide points of interest
                                            },
                                            {
                                                featureType: 'road',
                                                elementType: 'geometry',
                                                stylers: [{ visibility: 'simplified' }] // Show roads, but not in full detail
                                            },
                                            {
                                                featureType: 'road',
                                                elementType: 'labels.text',  // Specify the road's text element to modify the road names separately
                                                stylers: [{ visibility: 'on' }]  // Show road names
                                            },
                                            {
                                                featureType: 'transit',
                                                elementType: 'geometry',
                                                stylers: [{ visibility: 'off' }] // Hide transit
                                            },
                                            {
                                                featureType: 'water',
                                                elementType: 'geometry',
                                                stylers: [{ visibility: 'off' }] // Hide water
                                            },
                                            {
                                                featureType: 'administrative',
                                                elementType: 'geometry',
                                                stylers: [{ visibility: 'off' }] // Hide administrative buildings
                                            },
                                            {
                                                featureType: 'landscape.man_made', // Show only man-made landscapes (e.g., your building)
                                                elementType: 'geometry',
                                                stylers: [{ visibility: 'on' }]
                                            }
                                        ],
                                    }}
                                >
                                    <MarkerF
                                        position={mapCenter}
                                        onClick={handleMarkerClick}

                                    >
                                        {infoWindowOpen && (
                                            <InfoWindowF position={mapCenter} onCloseClick={() => setInfoWindowOpen(!infoWindowOpen)}>
                                                <a
                                                    href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(autocompleteValue)}`}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    style={{ color: colors.blue }}
                                                >
                                                    {autocompleteValue}
                                                </a>
                                            </InfoWindowF>
                                        )}
                                    </MarkerF>
                                </GoogleMap>
                            </ConditionalRender>
                        </div>
                        <FieldWrapper
                            name={'Description'}
                            label={project?.projectDescription}
                        />
                    </Wrapper>
                    {/* Logs */}
                    <TimeLogContainer
                        user={user}
                        timeLogs={timeLogs?.filter((x) => !isEmpty(x?.dailyLog))}
                        id={projectId}
                        logsIsLoading={logsIsLoading}
                        fetchTimeLogs={fetchTimeLogs}
                        projectId={projectId}
                        projects={projects}
                        project={project}
                    />

                    {/* Assign employees */}
                    <EmployeesContainer
                        filteredEmployees={employees?.filter((x) => x?._id !== user?._id && selectedEmployees?.includes(x._id))}
                        employees={employees}
                        userRole={userRole}
                        user={user}
                        projects={projects}
                        company={company}
                        fetchCompanyEmployees={fetchCompanyEmployees}
                        project={project}
                        employeesIsLoading={employeesIsLoading}
                        title={text?.projects?.details?.employees}
                        checkboxSelection={false}
                        isDetailsProject
                        EmployeeIsAdmin={EmployeeIsAdmin}
                        projectIsComplete={projectIsComplete}
                        // openEmployeeModal={openEmployeeModal}
                        // employeeModalIsOpen={employeeModalIsOpen}
                        id={id}
                        setExpanded={setExpanded}
                        expanded={expanded}
                    />

                    < ConditionalRender renderIf={!isEmpty(tasks)}>
                        <TasksContainer
                            projects={projects}
                            user={user}
                            employees={employees}
                            tasks={tasks}
                            tasksIsLoading={tasksIsLoading}
                            fetchProjectTasks={fetchProjectTasks}
                            isProject
                            projectId={projectId}
                            title={text?.dashboard?.tasks}

                            setExpanded={setExpanded}
                            expanded={expanded}
                        />
                    </ConditionalRender>
                </Wrapper>

                <Wrapper
                    width={isDesktop ? '50%' : '100%'}
                    flex={'1'}
                    padding={isDesktop && '0 0 0 15px'}
                >
                    {/* Client */}
                    <ConditionalRender renderIf={!isEmpty(project?.client)}>
                        <Wrapper title={'Client'}>
                            <FieldWrapper
                                goto={`/clients/details?id=${project?.client?._id}`}
                                name={'Name'}
                                label={project?.client?.name}
                            />
                        </Wrapper>
                    </ConditionalRender>

                    {/* Design Images */}
                    <ConditionalRender renderIf={!isEmpty(designs)}>
                        <Wrapper title={'Images'}>
                            <div className='flex flex-column'>
                                <RenderImages
                                    images={designs}
                                    openModal={openCarousel}
                                    closeModal={closeModal}
                                    setActiveIndex={setActiveIndex}
                                    activeIndex={activeIndex}
                                    isModalOpen={isModalOpen}
                                    disabled
                                />
                            </div>
                        </Wrapper>
                    </ConditionalRender>
                    <InventoryHomeContainer
                        inventory={inventory}
                        fetchInventoryProducts={fetchInventoryProducts}
                        inventorysLoading={inventorysLoading}
                        inventoryColumns={inventoryColumns}
                        setInventoryColumns={setInventoryColumns}
                        fetchAttributes={fetchAttributes}
                        isProject
                        projectId={projectId}
                        project={project}
                        title={text?.projects?.details?.inventory}
                        noDataText={text?.projects?.details?.noinventory}
                        projectIsComplete={projectIsComplete}
                        setExpanded={setExpanded}
                        expanded={expanded}
                    />

                    <Wrapper
                        noDataText={isEmpty(project?.files) && 'No files uploaded'}
                        title={'Files'}
                    >
                        <RenderFiles
                            files={project?.files}
                            roleName={user?.roleName}
                            handleDownloadFile={handleDownloadFile}
                            userId={user?._id}
                            readOnly
                        />
                    </Wrapper>
                    {/* Events */}
                    <Wrapper
                        title={text?.calendar?.calendar}
                        button={
                            <CallToAction
                                text={text?.calendar?.btnViewCalendar}
                                type='button'
                                onClick={() => navigate(`/calendar`)}
                            />
                        }
                    >
                        <FullCalendarContainer
                            events={events}
                            eventsIsLoading={eventsIsLoading}
                            user={user}
                            projects={projects}
                            employees={employees}
                            employeesIsLoading={employeesIsLoading}
                            fetchEvents={fetchEvents}
                            isProject
                            projectId={projectId}
                            projectIsLoading={projectIsLoading}
                            fetchEventCategories={fetchEventCategories}
                        />
                    </Wrapper>
                </Wrapper>

                <ExpandedContainer
                    expanded={expanded}
                    setExpanded={setExpanded}
                    projects={projects}
                    user={user}
                    employees={employees}
                    tasks={tasks}
                    tasksIsLoading={tasksIsLoading}
                    fetchProjectTasks={fetchProjectTasks}
                    isProject
                    projectId={projectId}

                    inventory={inventory}
                    fetchInventoryProducts={fetchInventoryProducts}
                    inventorysLoading={inventorysLoading}
                    inventoryColumns={inventoryColumns}
                    setInventoryColumns={setInventoryColumns}
                    fetchAttributes={fetchAttributes}
                    project={project}
                    title={text?.projects?.details?.inventory}
                    noDataText={text?.projects?.details?.noinventory}
                    projectIsComplete={projectIsComplete}
                />
            </div >
        </>
    )
}

export default DetailsProjectEmployeeContainer