import React, { useState, useMemo, useCallback, useEffect, useContext, useRef } from 'react'
import queryString from "query-string";
import { useLocation, useNavigate } from "react-router-dom";
import styled from 'styled-components'
import { Form, Field } from 'react-final-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose, faHistory } from '@fortawesome/free-solid-svg-icons';
import CallToAction from '../Core/CallToAction';
import _ from 'lodash';
import Info from '../Core/Info';
import { v4 as uuidv4 } from 'uuid';
import RenderAdvanceFields from './RenderAdvanceFields';
import SimpleFieldWithValidation from '../Core/SimpleFieldWithValidation';
import CustomField from '../Core/CustomField';
import { noSpecialChars, noNumbers } from '../utils/validators';
import { toCamelCase, compareStrings } from '../utils/helperFunctions';
import inputTypeOptions from './inputTypeOptions';
import ProposalsContainer from '../Proposals/ProposalsContainer';
import MultiFields from '../Core/MultiFields';
import HoverPopup from '../Core/HoverPopup';
import FormActions from '../Core/FormActions';
import FileUploaderInput from '../Core/Inputs/FileUploaderInput';
import { Link } from 'react-router-dom';
import ConfirmModal from '../Core/ConfirmModal';
import SubmitModal from '../Core/SubmitModal';
import TasksContainer from '../Tasks/TasksContainer';
import RenderImages from '../Core/RenderImages';
import RenderFiles from '../Core/RenderFiles';
import { useDropzone } from 'react-dropzone';
import base64ToBlob from '../utils/base64ToBlob';
import CheckBox from '../Core/Inputs/CheckBox';
import InventoryModalMessage from './InventoryModalMessage';
import CreateNewClientContainer from '../Clients/CreateNewClientContainer';
import EmployeesContainer from '../Employees/EmployeesContainer';
import TimeLogContainer from '../TimeLog/TimeLogContainer';
import ClientsContainer from '../Clients/ClientsContainer';
import DetailsProjectEmployeeContainer from './DetailsProjectEmployeeContainer';
import SelectInput from '../Core/SelectInput';
import { GlobalContext } from '../context/GlobalContext';
import ExpandedContainer from '../Expanded/ExpandedContainer';
import FullCalendarContainer from '../Calendar/FullCalendar';
import NotFound from '../Home/NotFound';
import classnames from 'classnames';
import {
    updateProject,
    deleteProject,
    completeProject,
    updateProjectImages,
    updateProjectFiles,
    deleteProjectFiles,
    downloadProjectFile,
    getProposalByProjectId,
    getProjectById,
    updateUpdatedDate,
    updateHistory,
    updateFields
} from '../utils/calls';
import { isEmpty } from 'lodash';
import ConditionalRender from '../Core/ConditionalRender';
import { composeValidators, isRequired } from '../utils/validators';
import InventoryHomeContainer from '../Invetory/InventoryHomeContainer';
import { useTheme } from '../context/ThemeContext'
import { useToast } from '../context/ToastContext';
import EnabledDisabledToggle from '../Core/EnabledDisabledToggle';
import Wrapper from '../Core/Wrapper';

import colors from '../globalStyles.scss'
import moment from 'moment';
import { useLanguage } from '../context/LanguageContext';
import useScreenSize from '../context/useScreenSize';

import { saveAs } from 'file-saver';

const StyledDiv = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    form {
        width: 100%;
    }

    .client-wrapper {
        display: flex;
        .remove {
            background: ${colors.red};
            svg {
                color: ${colors.white};
                font-size: 1rem;
            }
    }
    }

    .image-uploader {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .dropzone {
            display: flex;
            flex-direction: column;
            margin: 0.5em 0;
            padding: 1em;
            background: ${props => props.theme === 'dark' ? colors.secondary : colors.darkGray};
            cursor: pointer;
            transition: all 0.2s ease 0s;
            &:hover {
                background: ${colors.lightGray};
            }
            span {
                font-family: ${colors.roboto};
                color: ${props => props.theme === 'dark' ? colors.black : colors.black};
            }
        }
    }

`;
const DetailsProjectContainer = ({
    projects,
    projectId,
    company,
    user,
    employees,
    employeesIsLoading,
    fetchCompanyEmployees,
    fetchCompanyProjects,
    maxFiles = 10,
    isDashboard,
    inventory,
    fetchInventoryProducts,
    inventorysLoading,
    inventoryColumns,
    fetchAttributes,
    fetchFieldNames,
    setInventoryColumns,
    userRole,
    tasks,
    tasksIsLoading,
    fetchProjectTasks,
    fetchTimeLogs,
    timeLogs,
    logsIsLoading,
    categoryRecommendationData,
    categoryRecommendationIsLoading,
    fetchAllClients,
    clients,
    clientsIsLoading,
    proposalsIsLoading,
    fetchAllProposals,
    events,
    eventsIsLoading,
    fetchEvents,
    fetchEventCategories,
    fieldNames,
    attributesIsLoading

}) => {
    const { setProjects } = useContext(GlobalContext);
    const formRef = useRef();

    const location = useLocation();
    const navigate = useNavigate();
    const { theme } = useTheme();
    const { notify } = useToast();
    const { text } = useLanguage();
    const { isDesktop, isTablet, isPhone } = useScreenSize();
    const [expanded, setExpanded] = useState('');

    const id = projectId || queryString.parse(location.search)?.id
    useEffect(() => {
        !isDashboard && fetchInventoryProducts(id)
    }, [id, isDashboard])

    const [project, setProject] = useState({})
    const [projectIsLoading, setProjectIsLoading] = useState(true);
    const [client, setClient] = useState({})
    const [addClient, setAddClient] = useState(false);

    const [proposals, setProposals] = useState([])
    const [selectedClient, setSelectedClient] = useState(null);

    const [selectedLocation, setSelectedLocation] = useState(null);
    const [autocompleteValue, setAutocompleteValue] = useState('');

    const [isEditing, setIsEditing] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [designs, setDesigns] = useState([])
    const [files, setFiles] = useState([]);
    const [filesToRemove, setFilesToRemove] = useState([]);

    const [imageError, setImageError] = useState('')
    const [selectedEmployees, setSelectedEmployees] = useState([]);

    const [employeeModalIsOpen, setEmployeeModalIsOpen] = useState(false);

    const [fileModalIsOpen, setFileModalIsOpen] = useState(false);

    const EmployeeIsAdmin = user?.roleName === 'Employee' && project?.adminEmployees?.some((x) => x.id === user?._id)
    const isAdmin = user?.roleName === 'Admin'

    const [isOpen, setIsOpen] = useState(false);

    const handleFieldChange = useCallback(
        _.debounce((name, value) => {
            formRef.current.change(name, value)
        }, 300),
        []
    );

    const openRemoveModal = () => {
        setAssignedProject({
            label: text?.projects?.details?.unassigned,
            value: '000000000000000000000000'
        })
        setIsOpen(!isOpen)
    }

    const openEmployeeModal = () => {
        setEmployeeModalIsOpen(!employeeModalIsOpen)
    }

    const openFileModal = () => {
        setFileModalIsOpen(!fileModalIsOpen)
    }

    const openCompletedModal = () => {
        setCompletedIsOpen(!completedIsOpen)
    }

    const openClientModal = () => {
        setClientModalIsOpen(!clientModalIsOpen)
    }

    const onSubmit = async (values, form) => {
        delete values?.fieldName
        delete values?.inputType

        if (values?.advanceFields) {
            // remove null values from object
            Object.keys(values?.advanceFields).forEach(key => {
                if (values?.advanceFields[key] === null || values?.advanceFields[key] === '') {
                    delete values?.advanceFields[key];
                }
            });
        }

        setProjectIsLoading(true)
        values.assignedEmployees = selectedEmployees.map((employeeId) => ({ id: employeeId }));
        values.client = client?._id || null;

        const processProjectImages = async () => {
            const formData = new FormData();
            designs.forEach((image) => {
                if (image.new) {
                    const imageBlob = base64ToBlob(image?.url);
                    formData.append('newImages', imageBlob, image?.originalName);
                } else {
                    formData.append('oldImages', JSON.stringify(image));
                }
            });

            formData.append('projectId', id);

            // Send to the API
            const res = await updateProjectImages(formData)
            if (res.status === 200) {
                console.log('Images updated');
            } else {
                throw new Error("Images update failed");
            }

            return res;
        };

        const processProjectFiles = async () => {
            if (filesToRemove.length) {
                const res = await deleteProjectFiles(filesToRemove, id);
                if (res.status === 200) {
                    console.log('Files deleted');

                } else {
                    throw new Error("Files delete failed");
                }
            }
        };


        try {
            // Update fields before the project update
            const fieldRes = await updateFields(fieldNameAndValues);
            if (fieldRes.status === 200) {
                fetchFieldNames();
            }

            const res = await updateProject(id, values)
            if (res.status === 200) {
                setIsEditing(false);
                await processProjectImages();
                await processProjectFiles();
                await fetchInventoryProducts(id);
                form.change('newInventoryAssignedProjectId', null)
                notify(text?.notificationsUI?.project?.updated, 'success')
                updateUpdatedDate(id)
            }
        } catch (error) {
            notify(`Error: ${error.message}`, 'error')
            console.error(error)
        } finally {
            handleGetProject(text.projects?.history?.projectUpdated)
        }
    }

    const [updatedKeys, setUpdatedKeys] = useState([]);
    const [newAdvanceFields, setNewAdvanceFields] = useState([]);

    useMemo(() => {
        if (project?.advanceFields) {
            const field = Object.entries(project?.advanceFields).map(([key, value]) => {
                const field = key?.split('-')[0];
                const fieldValue = key?.split('-')[1];
                const fieldLabel = fieldNames?.find((x) => x.name?.toLowerCase() === fieldValue?.toLowerCase())?.label;
                return {
                    fieldName: field,
                    fullFieldName: key,
                    fieldValue: fieldValue,
                    fieldLabel: fieldLabel,
                    value
                };
            });
            setUpdatedKeys(field);
        }
    }, [project?.advanceFields]);

    const removeField = (field) => {
        // Remove from `newAdvanceFields` if it exists there
        setNewAdvanceFields((prevFields) => {
            return prevFields.filter((x) => x.fullFieldName !== field.fullFieldName);
        });

        // Remove from `updatedKeys` if it exists there
        setUpdatedKeys((prevFields) => {
            return prevFields.filter((x) => x.fullFieldName !== field.fullFieldName);
        });

        // Clear the field value in the form state
        formRef.current.change(`advanceFields.${field.fullFieldName}`, null);

        // Set editing state to indicate a change
        setIsEditing(true);
    };

    const getProjectProposals = async () => {
        try {
            const res = await getProposalByProjectId(id);
            if (res.status === 200) {
                setProposals(res.data)
            }
        } catch (error) {
            console.error(error)
        }
    }

    const handleUploadFiles = async (values) => {
        const formData = new FormData();

        values.files.forEach((file) => {
            formData.append('newFiles', file);
        });

        formData.append('projectId', id);
        try {
            setFileModalIsOpen(false)
            const res = await updateProjectFiles(formData);
            if (res.status === 200) {
                updateUpdatedDate(id)
                notify(text?.notificationsUI?.project?.filesUploaded, 'success');
            }
        } catch (error) {
            notify(`Error: ${error.message}`, 'error');
            console.error(error);
        } finally {
            handleGetProject(text.projects?.history?.filesUploaded)
        }
    };

    const handleDisabledToggle = () => {
        setDisabled(!disabled);
    };

    const required = composeValidators(
        isRequired(text?.projects?.details?.validations?.required)
    )

    useMemo(() => {
        setDesigns(project?.designs)
    }, [project?.designs])

    useMemo(() => {
        setFiles(project?.files)
    }, [project?.files])

    const onDrop = useCallback(async (acceptedFiles, id) => {
        const imageFiles = acceptedFiles?.filter(file =>
            file.type.startsWith('image/')
        );
        if (imageFiles.length !== acceptedFiles.length) {
            setImageError(text?.projects?.details?.validations?.onlyImages)
        } else {
            const newUploadedImages = await Promise.all(
                acceptedFiles?.map((file) => {
                    return new Promise((resolve, reject) => {
                        const reader = new FileReader();

                        reader.onloadend = async () => {
                            resolve({ file, url: reader.result, originalName: file.name });
                        };

                        if (file) {
                            reader.readAsDataURL(file);
                        } else {
                            reject(new Error("No file provided"));
                        }
                    });
                })
            );
            setDesigns((prevImages) => {
                const updatedImages = [
                    ...prevImages,
                    ...newUploadedImages?.map((img) => ({ url: img.url, originalName: img.originalName, new: true })),
                ];

                return updatedImages;
            });
            setIsEditing(true)
            setImageError('')
        }
    }, []);

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept: {
            'image/jpeg': ['.jpeg', '.jpg'],
            'image/png': ['.png'],
            'image/gif': ['.gif'],
            'image/bmp': ['.bmp'],
            'image/svg+xml': ['.svg']
        },
        multiple: true,
    });

    const removeImage = (imageIndex) => {
        setDesigns((prevImages) => {
            const updatedImages = prevImages?.filter((image, index) => index !== imageIndex);
            return updatedImages;
        });
        setIsEditing(true)
    };

    const removeFile = (fileIndex) => {
        setFilesToRemove((prev) => [...prev, files[fileIndex]._id]);
        setFiles((prevFiles) => {
            const updatedFiles = prevFiles?.filter((file, index) => index !== fileIndex);
            return updatedFiles;
        });
        setIsEditing(true)
    };

    const handleSelectLocation = useCallback((place) => {
        if (place.formatted_address && place.geometry && place.geometry.location) {
            setSelectedLocation(place);
            setAutocompleteValue(place.formatted_address);
        }
    }, []);


    const handleGetProject = async (action) => {
        setProjectIsLoading(true)
        try {
            // First, update the history if an action is provided
            if (action) {
                await updateHistory(id, action);
            }
            const res = await getProjectById(id);
            if (res.status === 200) {
                setNewAdvanceFields([])
                setProject(res.data);
                setProjectIsLoading(false)
                updateProjects(res.data)
                fetchEvents(id)
            }
        } catch (error) {
            console.error(error);
            setProjectIsLoading(false)
        } finally {
            setProjectIsLoading(false);
        }

    }

    // This will keep the project up to date with the latest changes
    const updateProjects = (updatedProject) => {
        setProjects(prevProjects => {
            return prevProjects?.map(project =>
                project.id === updatedProject.id ? updatedProject : project
            );
        });
    }

    const fetchLogs = () => {
        const isAdmin = projects?.some(project => project._id === id &&
            (project.adminEmployees?.some(employee => employee?.id === user?.id) || user?.roleName === 'Admin'));
        try {
            fetchTimeLogs(null, id, isAdmin);
        } catch (error) {
            console.error(error);
        }
    };

    useMemo(() => {
        // const findProject = projects?.find((x) => x.id === id);
        const fetchInitialLocation = (address) => {
            const geocoder = new window.google.maps.Geocoder();

            geocoder.geocode({ address }, (results, status) => {
                if (status === 'OK') {
                    handleSelectLocation(results[0]);
                } else {
                    console.error('Geocode was not successful for the following reason: ' + status);
                }
            });
        };

        setClient(project?.client)
        setSelectedClient(project?.client?._id)
        setSelectedEmployees(project?.assignedEmployees?.map((x) => x?.id))
        setAutocompleteValue(project?.projectLocation);
        fetchInitialLocation(project?.projectLocation);
        fetchLogs()
    }, [id, handleSelectLocation, project]);

    useEffect(() => {
        handleGetProject()
    }, [id])

    const mapCenter = useMemo(() => {
        const lat = parseFloat(selectedLocation?.geometry?.location?.lat());
        const lng = parseFloat(selectedLocation?.geometry?.location?.lng());
        return {
            lat,
            lng
        };
    }, [selectedLocation]);

    const onLoad = useCallback((map) => {
        if (selectedLocation) {

            map.panTo(new window.google.maps.LatLng(mapCenter?.lat, mapCenter?.lng));
        }
    }, [mapCenter, selectedLocation]);

    const [activeIndex, setActiveIndex] = useState(0);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [deleteInventory, setDeleteInventory] = useState(false);
    const [assignedProject, setAssignedProject] = useState({
        label: text?.projects?.details?.unassigned,
        value: '000000000000000000000000'
    })
    const [showAssignedProject, setShowAssignedProject] = useState(false)
    const [completedIsOpen, setCompletedIsOpen] = useState(false)
    const [clientModalIsOpen, setClientModalIsOpen] = useState(false)


    const openCarousel = (index) => {
        setActiveIndex(index);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    useEffect(() => {

        const fetchTasks = () => {
            fetchProjectTasks(id);
        }

        setAssignedProject({
            label: text?.projects?.details?.unassigned,
            value: '000000000000000000000000'
        })
        setIsEditing(false)
        getProjectProposals()
        handleGetProject()

        fetchLogs();
        fetchTasks()
    }, [id]);

    const [newFieldName, setNewFieldName] = useState({});
    const [fieldError, setFieldError] = useState('');
    const [selectedFieldType, setSelectedFieldType] = useState('Field Type');
    const [errorMessage, setErrorMessage] = useState('');
    const [fieldNameAndValues, setFieldNameAndValues] = useState([]);

    const handleOnChange = (e) => {
        const newValue = e?.value;
        const newLabel = e?.label;
        setNewFieldName({
            value: newValue,
            label: newLabel
        });

        const eValue = e?.value.replace(/\s/g, "");
        const fieldExists =
            updatedKeys?.some((field) => {
                return compareStrings(field.fieldValue, eValue) || compareStrings(field.fieldLabel, newLabel);
            }) ||
            newAdvanceFields?.some((field) => {
                return compareStrings(field.fieldValue, eValue) || compareStrings(field.fieldLabel, newLabel);
            });

        if (fieldExists) {
            setFieldError(text?.inventory?.details?.validations?.exists);
        } else {
            setFieldError(
                noNumbers(newValue) ||
                noSpecialChars(newValue)
            );
        }

        setIsEditing(true);
    };

    const handleInputChange = (e, form) => {
        if (!e?.value) {
            setSelectedFieldType('Field Type');
            form.change('customFieldType', null);
        } else {
            setSelectedFieldType(e?.value);
        }
    }

    const addField = useCallback(() => {
        if (!isEmpty(newFieldName) && selectedFieldType !== 'Field Type') {
            if (
                updatedKeys?.some((field) => compareStrings(field.fullFieldName.split('-')[1], newFieldName?.value)) ||
                newAdvanceFields?.some((field) => compareStrings(field.fullFieldName.split('-')[1], newFieldName?.value))
            ) {
                setFieldError(text?.inventory?.details?.validations?.exists);
            } else {
                const newId = uuidv4(); // Generate a new UUID

                const newField = {
                    fullFieldName: `${selectedFieldType}-${toCamelCase(newFieldName?.value)}`,
                    label: _.capitalize(newFieldName?.label),
                    fieldName: toCamelCase(selectedFieldType),
                    fieldLabel: _.capitalize(newFieldName?.label),
                    fieldValue: _.capitalize(newFieldName?.value),
                    value: null,
                    inputType: selectedFieldType,
                };
                setNewAdvanceFields([newField, ...newAdvanceFields]);
                setFieldNameAndValues([
                    ...newAdvanceFields,
                    {
                        id: newId,
                        label: _.capitalize(newFieldName?.label),
                        fieldLabel: _.capitalize(newFieldName?.label),
                        name: toCamelCase(newFieldName?.value),
                        fullFieldName: `${selectedFieldType}-${toCamelCase(newFieldName?.value)}`,
                        inputType: selectedFieldType,

                    }
                ]);

                setIsEditing(true);
            }
            formRef.current.change('customFieldName', null);
            formRef.current.change('customFieldType', null);
            setSelectedFieldType('Field Type');
            setFieldError('');
            setErrorMessage('');
            setNewFieldName({});

        } else {
            if (isEmpty(newFieldName)) {
                setErrorMessage(text?.inventory?.details?.validations?.fieldName);
            } else {
                setErrorMessage(text?.inventory?.create?.form?.extraFields?.validations?.fieldType);
            }
            return null;
        }
    }, [selectedFieldType, newFieldName]);

    const handleRemoveProject = async () => {
        try {
            const res = await deleteProject(id, deleteInventory, assignedProject);
            if (res.status === 200) {
                fetchCompanyProjects()
                navigate('/')
                notify(text?.notificationsUI?.project?.deleted, 'success')
            }
        } catch (error) {
            notify(`Error: ${error.message}`, 'error')
            console.error(error)
        }
    }

    const [projectIsComplete, setProjectIsComplete] = useState(false);
    useEffect(() => {
        setProjectIsComplete(project?.isCompleted)
    }, [project?.isCompleted])

    const handleCompleteProject = async (isCompleted) => {
        try {
            const res = await completeProject(id, { assignedProject, deleteInventory, isCompleted });
            if (res.status === 200) {
                fetchCompanyProjects()
                fetchInventoryProducts(id)
                setCompletedIsOpen(false)
                setIsEditing(false)
                fetchAllProposals()
                fetchProjectTasks(id);
                updateUpdatedDate(id);
                notify(text?.notificationsUI?.project?.updated, 'success')
            }
        } catch (error) {
            notify(`Error: ${error.message}`, 'error')
            console.error(error)
        } finally {
            handleGetProject(isCompleted ? text?.projects?.history?.projectCompleted : text?.projects?.history?.projectMarkedIncomplete)
        }
    }

    const handleClientSubmit = async () => {
        try {
            const res = await updateProject(id, { client: selectedClient });
            if (res.status === 200) {
                openClientModal()
                setIsEditing(false)
                updateUpdatedDate(id);
                notify(text?.notificationsUI?.project?.updated, 'success')
            }
        } catch (error) {
            notify(`Error: ${error.message}`, 'error')
            console.error(error)
        } finally {
            handleGetProject(text?.projects?.history?.clientUpdated)
        }
    }

    const setNewClient = async (client) => {
        try {
            const res = await updateProject(id, { client });
            if (res.status === 200) {
                setIsEditing(false)
                updateUpdatedDate(id);
                notify(text?.notificationsUI?.project?.updated, 'success')
            }
        } catch (error) {
            notify(`Error: ${error.message}`, 'error')
            console.error(error)
        } finally {
            handleGetProject(text?.projects?.history?.clientUpdated)
        }
    }

    const handleDownloadFile = async (e, file) => {
        e.preventDefault();
        e.stopPropagation();
        try {
            const res = await downloadProjectFile({ projectId: id, file });
            if (res.status === 200) {
                const contentType = res.headers['content-type'];
                const blob = new Blob([res.data], { type: contentType });
                saveAs(blob, file.originalName);
            } else {
                throw new Error('Failed to download file');
            }
        } catch (error) {
            console.error('Error downloading file:', error);
        }
    };

    const [historyStatus, setHistoryStatus] = useState(false);


    const adminProjects = projects?.filter(project => {
        // Check if the user is an admin
        return project.adminEmployees?.some(employee => employee?.id === user?.id) || user?.roleName === 'Admin';
    })?.filter((project) => project.id !== id);

    return !projectIsLoading && isEmpty(project) ? <NotFound isCompanyPremium /> :
        (
            <ConditionalRender renderIf={true} isLoading={categoryRecommendationIsLoading || projectIsLoading} loadingCount={1}>
                <StyledDiv
                    theme={theme}
                    className={isDesktop ? 'desktop' : isTablet ? 'tablet' : 'phone'}

                >
                    {/* Admin view so it can edit the project */}
                    <ConditionalRender customHeight={window.innerHeight} renderIf={isAdmin || EmployeeIsAdmin}>
                        <div className='mb-lg tx-left'>
                            <h4>
                                {text?.projects?.details?.details}
                            </h4>
                            <hr />
                        </div>
                        <div className='flex flex-column justify-end'>

                            <ConditionalRender renderIf={false}>
                                <ConditionalRender renderIf={!isDashboard && !projectIsComplete}>
                                    <EnabledDisabledToggle
                                        disabled={disabled}
                                        handleDisabledToggle={handleDisabledToggle}
                                        isEditing={isEditing}
                                    />
                                </ConditionalRender>
                            </ConditionalRender>

                            <ConditionalRender renderIf={isDashboard}>
                                <CallToAction style={{
                                    marginTop: '1.5rem',
                                    marginBottom: '1.5rem',
                                    alignSelf: 'flex-start',
                                }} text={text?.projects?.details?.details} onClick={() => navigate(`/project/details?id=${id}`)} />
                            </ConditionalRender>

                            <Form
                                initialValues={project}
                                onSubmit={(values, form) => onSubmit(values, form)}
                                render={({ handleSubmit, form }) => {
                                    formRef.current = form;

                                    return (
                                        <form onSubmit={handleSubmit}>
                                            <div>
                                                <ConditionalRender renderIf={!isDashboard}>
                                                    <div className='flex items-center mb-md'>
                                                        <FontAwesomeIcon
                                                            onClick={() => setHistoryStatus(!historyStatus)}
                                                            style={{
                                                                color: theme === 'dark' ? colors.secondary : colors.blue,
                                                                cursor: 'pointer',
                                                                marginRight: '1em'
                                                            }}
                                                            icon={faHistory}
                                                        />
                                                        <CheckBox
                                                            label={text?.projects?.details?.completed}
                                                            checked={projectIsComplete}
                                                            onChange={() => {
                                                                const project = projects.find(p => p.id === id);
                                                                if (project) {
                                                                    setAssignedProject({
                                                                        value: project.id,
                                                                        label: project.projectName
                                                                    });
                                                                }
                                                                openCompletedModal()
                                                            }
                                                            }
                                                        />
                                                        <ConditionalRender renderIf={project?.isCompleted}>
                                                            <p className='ml-md'>
                                                                {moment(project?.updatedAt).format('MM-DD-YYYY')}
                                                            </p>
                                                        </ConditionalRender>

                                                    </div>
                                                </ConditionalRender>
                                                <ConditionalRender renderIf={!projectIsComplete}>
                                                    <ConfirmModal
                                                        isOpen={completedIsOpen}
                                                        toggle={openCompletedModal}
                                                        onClick={() => handleCompleteProject(true)}
                                                        text={text?.projects?.details?.modal?.button}
                                                        btnStyles={{ color: colors.blue }}
                                                        width={isDesktop ? '50%' : '100%'}
                                                        height={isDesktop ? '50%' : '100%'}
                                                    >
                                                        <InventoryModalMessage
                                                            type='complete'
                                                            inventory={inventory}
                                                            projects={projects}
                                                            id={id}
                                                            deleteInventory={deleteInventory}
                                                            setDeleteInventory={setDeleteInventory}
                                                            assignedProject={assignedProject}
                                                            setAssignedProject={setAssignedProject}
                                                            showAssignedProject={showAssignedProject}
                                                            setShowAssignedProject={setShowAssignedProject}
                                                        />
                                                    </ConfirmModal>
                                                </ConditionalRender>

                                                <ConditionalRender renderIf={projectIsComplete}>
                                                    <ConfirmModal
                                                        isOpen={completedIsOpen}
                                                        toggle={openCompletedModal}
                                                        onClick={() => handleCompleteProject(false)}
                                                        text={text?.projects?.details?.modal?.button}
                                                        btnStyles={{ color: colors.blue }}
                                                        width={isDesktop ? '50%' : '100%'}
                                                        height={isDesktop ? '50%' : '100%'}
                                                    >
                                                        {text?.projects?.details?.modal?.incomplete}
                                                    </ConfirmModal>
                                                </ConditionalRender>
                                            </div>

                                            <div className={classnames({
                                                'flex': isDesktop,
                                                'flex flex-column': !isDesktop
                                            })} >

                                                {/* Info */}
                                                <Wrapper
                                                    width={isDesktop ? '50%' : '100%'}
                                                    flex={'1'}
                                                    padding={isDesktop && '0 15px 0 0'}
                                                >
                                                    <div className='mb-md'>
                                                        <SimpleFieldWithValidation
                                                            setIsEditing={setIsEditing}
                                                            className='field-style'
                                                            label={text?.projects?.details?.name}
                                                            name="projectName"
                                                            validate={required}
                                                            type={"text"}
                                                            initialValue={project?.projectName}
                                                            disabled={projectIsComplete}
                                                            onFieldChange={handleFieldChange}
                                                        />

                                                        <SelectInput
                                                            validate={required}
                                                            setIsEditing={setIsEditing}
                                                            name={`projectCategory`}
                                                            label={text?.projects?.create?.category}
                                                            options={categoryRecommendationData}

                                                            initialValue={project?.projectCategory}
                                                            placeholder={text?.projects?.create?.selectCategory}
                                                            disabled={projectIsComplete}
                                                            style={{
                                                                width: isDesktop ? '50%' : '100%'
                                                            }}
                                                            noFormat
                                                        />
                                                        <MultiFields
                                                            name="projectLocation"
                                                            component="location"
                                                            label={text?.projects?.create?.location}
                                                            block
                                                            initialValue={project?.projectLocation}
                                                            disabled={projectIsComplete}
                                                            setIsEditing={setIsEditing}
                                                        />
                                                        <SimpleFieldWithValidation
                                                            name="projectDescription"
                                                            type="textarea"
                                                            label={text?.projects?.create?.description}
                                                            block
                                                            initialValue={project?.projectDescription || ''}
                                                            disabled={projectIsComplete}
                                                            setIsEditing={setIsEditing}
                                                            onFieldChange={handleFieldChange}
                                                        />
                                                    </div>
                                                    {/* Assign employees */}
                                                    <EmployeesContainer
                                                        filteredEmployees={employees?.filter((x) => x?._id !== user?._id && selectedEmployees?.includes(x._id))}
                                                        employees={employees}
                                                        userRole={userRole}
                                                        user={user}
                                                        projects={projects}
                                                        company={company}
                                                        fetchCompanyEmployees={fetchCompanyEmployees}
                                                        project={project}
                                                        employeesIsLoading={employeesIsLoading}
                                                        title={text?.projects?.details?.employees}
                                                        checkboxSelection={false}
                                                        isDetailsProject
                                                        EmployeeIsAdmin={EmployeeIsAdmin}
                                                        projectIsComplete={projectIsComplete}
                                                        openEmployeeModal={openEmployeeModal}
                                                        employeeModalIsOpen={employeeModalIsOpen}
                                                        id={id}
                                                        handleGetProject={handleGetProject}
                                                        setExpanded={setExpanded}
                                                        expanded={expanded}
                                                    />

                                                    {/* Logs */}
                                                    <TimeLogContainer
                                                        user={user}
                                                        timeLogs={timeLogs?.filter((x) => !isEmpty(x?.dailyLog))}
                                                        id={id}
                                                        logsIsLoading={logsIsLoading}
                                                        fetchTimeLogs={fetchTimeLogs}
                                                        projectId={projectId}
                                                        projects={projects}
                                                        project={project}
                                                        isAdmin
                                                    />

                                                    {/* Files */}
                                                    <Wrapper
                                                        noDataText={isEmpty(files) && text?.projects?.details?.noFilesFound}
                                                        disableButton={projectIsComplete}
                                                        title={text?.projects?.details?.filesTitle}
                                                        button={(
                                                            <CallToAction
                                                                text={text?.projects?.details?.filesButton}
                                                                type='button'
                                                                onClick={openFileModal}
                                                            />
                                                        )}>

                                                        <RenderFiles
                                                            files={files}
                                                            roleName={user?.roleName}
                                                            disabled={projectIsComplete}
                                                            isEditing={isEditing}
                                                            removeFile={removeFile}
                                                            projectIsComplete={projectIsComplete}
                                                            handleDownloadFile={handleDownloadFile}
                                                            userId={user?._id}
                                                            readOnly={projectIsComplete}
                                                        />

                                                        <SubmitModal
                                                            onClick={(values) => handleUploadFiles(values)}
                                                            text={text?.projects?.details?.filesModal?.button}
                                                            toggle={openFileModal}
                                                            isOpen={fileModalIsOpen}
                                                            width={isDesktop ? '50%' : '100%'}
                                                            height={isDesktop ? '50%' : '100%'}
                                                            btnStyles={{
                                                                color: colors.blue
                                                            }}
                                                        >
                                                            <div>
                                                                <div className='mb-md'>
                                                                    <h4 className='mb-md'>
                                                                        {text?.projects?.details?.filesModal?.title}
                                                                    </h4>
                                                                    <p className='mb-md'>
                                                                        {text?.projects?.details?.filesModal?.text}
                                                                    </p>

                                                                    <p>
                                                                        {text?.projects?.details?.filesModal?.text2}
                                                                    </p>
                                                                    <p>
                                                                        {text?.projects?.details?.filesModal?.text3}
                                                                    </p>
                                                                </div>
                                                                <Field name="files">
                                                                    {({ input, meta }) => (
                                                                        <FileUploaderInput
                                                                            input={input}
                                                                            meta={meta}
                                                                            files={files}
                                                                            setFiles={setFiles}
                                                                        />
                                                                    )}
                                                                </Field>
                                                            </div>
                                                        </SubmitModal>
                                                    </Wrapper>

                                                    <TasksContainer
                                                        projects={projects}
                                                        adminProjects={projects}
                                                        user={user}
                                                        employees={employees}
                                                        tasks={tasks}
                                                        tasksIsLoading={tasksIsLoading}
                                                        fetchProjectTasks={fetchProjectTasks}
                                                        isProject
                                                        projectId={id}
                                                        project={project}
                                                        title={text?.dashboard?.tasks}
                                                        projectIsCompleted={projectIsComplete}
                                                        setExpanded={setExpanded}
                                                        expanded={expanded}
                                                        handleGetProject={handleGetProject}
                                                    />

                                                    {/* Advance Fields */}
                                                    <Wrapper
                                                        isLoading={attributesIsLoading}
                                                        title={
                                                            <div className='flex items-center' style={{
                                                                fontFamily: colors.openSans,
                                                                textAlign: 'left'
                                                            }}>
                                                                {text?.inventory?.create?.form?.extraFields?.title}
                                                                <Info
                                                                    text={text?.projects?.info}
                                                                    id='advanceField-project'
                                                                    placement={'top'}
                                                                    className='flex justify-center items-center'
                                                                />
                                                            </div>
                                                        }
                                                    >
                                                        <div className='flex flex-column'>
                                                            <CustomField
                                                                name={'customFieldName'}
                                                                value={newFieldName?.value}
                                                                placeholder={text?.inventory?.details?.form?.selectFieldName}
                                                                onChange={handleOnChange}
                                                                errorMessage={fieldError}
                                                                type={'text'}
                                                                wrapperStyles={{ width: 'auto' }}
                                                                fieldNames={fieldNames?.map((x) => x?.label)}
                                                                newFieldName={newFieldName?.value}
                                                                style={{
                                                                    width: '100%'
                                                                }}
                                                            />
                                                            <SelectInput
                                                                name={'customFieldType'}
                                                                options={inputTypeOptions(text)}
                                                                onChange={(e) => handleInputChange(e, form)}
                                                                placeholder={text?.inventory?.details?.form?.fieldType}
                                                                style={{
                                                                    width: '100%'
                                                                }}
                                                            />
                                                            <CallToAction
                                                                wrapperClassName={classnames({
                                                                    'mt-md': true
                                                                })}
                                                                type="button"
                                                                onClick={addField}
                                                                disabled={!isEmpty(fieldError)}
                                                                text={text?.inventory?.details?.form?.addField}
                                                                style={{
                                                                    alignSelf: 'flex-end',
                                                                    backgroundColor: theme === 'dark' ? colors.secondary : colors.black
                                                                }}
                                                            />
                                                            <span className='small error mt-md align-self-end'>
                                                                {errorMessage}
                                                            </span>
                                                            <hr />
                                                        </div>
                                                        <RenderAdvanceFields
                                                            updatedKeys={updatedKeys}
                                                            newAdvanceFields={newAdvanceFields}
                                                            setNewAdvanceFields={setNewAdvanceFields}
                                                            setUpdatedKeys={setUpdatedKeys}
                                                            project={project}
                                                            setProject={setProject}
                                                            isEditing={isEditing}
                                                            setIsEditing={setIsEditing}
                                                            removeField={removeField}
                                                        />
                                                    </Wrapper>

                                                </Wrapper>

                                                <Wrapper
                                                    width={isDesktop ? '50%' : '100%'}
                                                    flex={'1'}
                                                    padding={isDesktop && '0 0 0 15px'}
                                                >

                                                    {/* Clients */}
                                                    <Wrapper
                                                        title={text?.projects?.create?.client}
                                                        button={
                                                            <ConditionalRender renderIf={!projectIsComplete}>
                                                                <CallToAction
                                                                    text={
                                                                        isEmpty(client) ?
                                                                            text?.projects?.details?.clientModal?.assignClient : text?.projects?.details?.clientModal?.changeClient
                                                                    }
                                                                    type='button'
                                                                    onClick={openClientModal}
                                                                    wrapperWidth='100%'
                                                                />
                                                            </ConditionalRender>
                                                        }
                                                        secondaryButton={
                                                            <ConditionalRender renderIf={!projectIsComplete}>
                                                                <ConditionalRender renderIf={isEmpty(client)}>
                                                                    <CallToAction
                                                                        className='ml-md'
                                                                        text={text?.client?.create?.title}
                                                                        type='button'
                                                                        onClick={() => setAddClient(!addClient)}
                                                                    />
                                                                </ConditionalRender>
                                                            </ConditionalRender>
                                                        }
                                                    >
                                                        <ConditionalRender renderIf={!isEmpty(client)}>
                                                            <div className='client-wrapper relative '>
                                                                <HoverPopup
                                                                    className={classnames({
                                                                        'w-100': !isDesktop && !isTablet,
                                                                        'w-50': isDesktop || isTablet,
                                                                    })}
                                                                    style={{
                                                                        display: 'flex',
                                                                        justifyContent: 'flex-start',
                                                                        background: theme === 'dark' ? colors.backgroundSecondary : colors.darkGray,
                                                                        color: theme === 'dark' ? colors.white : colors.black,
                                                                    }}
                                                                    placement={'top'}
                                                                    id={'client-popup'}
                                                                    text={text?.inventory?.landing?.table?.columns?.details}
                                                                >
                                                                    <Link
                                                                        to={`/clients/details?id=${client?._id}`}>
                                                                        {client?.name}
                                                                    </Link>
                                                                </HoverPopup>
                                                                <ConditionalRender renderIf={!projectIsComplete}>
                                                                    <button
                                                                        className='remove'
                                                                        type="button"
                                                                        onClick={(e) => {
                                                                            e.stopPropagation();
                                                                            setClient({})
                                                                            setSelectedClient(null)
                                                                            setIsEditing(true)
                                                                        }}
                                                                    >
                                                                        <FontAwesomeIcon icon={faClose} />
                                                                    </button>
                                                                </ConditionalRender>
                                                            </div>
                                                        </ConditionalRender>
                                                        <ConditionalRender renderIf={isEmpty(client)}>
                                                            <p>
                                                                {text?.projects?.create?.notFoundMsgs?.client}
                                                            </p>
                                                        </ConditionalRender>

                                                        <ConditionalRender renderIf={clientModalIsOpen}>
                                                            <ConfirmModal
                                                                onClick={handleClientSubmit}
                                                                text={text?.projects?.create?.clientButton}
                                                                toggle={openClientModal}
                                                                isOpen={clientModalIsOpen}
                                                                width={isDesktop ? '80%' : '100%'}
                                                                height={isDesktop ? '80%' : '100%'}
                                                                btnStyles={{
                                                                    color: colors.blue
                                                                }}
                                                            >
                                                                <div className='w-100'>
                                                                    <h4 className='mb-md'>
                                                                        {isEmpty(client) ?
                                                                            text?.projects?.details?.clientModal?.assignClient : text?.projects?.details?.clientModal?.changeClient}
                                                                    </h4>
                                                                    <ClientsContainer
                                                                        user={user}
                                                                        fetchAllClients={fetchAllClients}
                                                                        clients={clients}
                                                                        clientsIsLoading={clientsIsLoading}
                                                                        isProject
                                                                        btnWidth={'100%'}
                                                                        showLightColors
                                                                        setSelectedClient={setSelectedClient}
                                                                        selectedClient={selectedClient}
                                                                        fetchCompanyProjects={fetchCompanyProjects}
                                                                        project={project}
                                                                        projectIsComplete={projectIsComplete}
                                                                    />
                                                                </div>
                                                            </ConfirmModal>
                                                        </ConditionalRender>

                                                        <ConditionalRender renderIf={addClient}>
                                                            <CreateNewClientContainer
                                                                isOpen={addClient}
                                                                toggle={() => setAddClient(false)}
                                                                user={user}
                                                                fetchAllClients={fetchAllClients}
                                                                setNewClient={setNewClient}
                                                                clients={clients}
                                                            />
                                                        </ConditionalRender>

                                                    </Wrapper>

                                                    {/* proposals */}
                                                    <ProposalsContainer
                                                        title={'Proposals'}
                                                        adminProjects={projects}
                                                        projects={projects}
                                                        user={user}
                                                        clients={clients}
                                                        proposals={proposals}
                                                        proposalsIsLoading={proposalsIsLoading}
                                                        fetchAllProposals={getProjectProposals}
                                                        projectIsCompleted={projectIsComplete}
                                                        isProject
                                                        projectId={id}
                                                        project={project}
                                                        setExpanded={setExpanded}
                                                        expanded={expanded}
                                                        handleGetProject={handleGetProject}
                                                    />
                                                    {/* Inventory */}
                                                    <ConditionalRender renderIf={project?.features?.inventory}>
                                                        <InventoryHomeContainer
                                                            inventory={inventory}
                                                            fetchInventoryProducts={fetchInventoryProducts}
                                                            inventorysLoading={inventorysLoading}
                                                            inventoryColumns={inventoryColumns}
                                                            setInventoryColumns={setInventoryColumns}
                                                            fetchAttributes={fetchAttributes}
                                                            isProject
                                                            projectId={id}
                                                            project={project}
                                                            title={text?.projects?.details?.inventory}
                                                            noDataText={text?.projects?.details?.noinventory}
                                                            projectIsComplete={projectIsComplete}
                                                            setExpanded={setExpanded}
                                                            expanded={expanded}
                                                        />
                                                    </ConditionalRender>

                                                    {/* Design Images */}
                                                    <Wrapper
                                                        title={text?.projects?.details?.designs}
                                                        button={
                                                            <div className='image-uploader'>
                                                                {
                                                                    !projectIsComplete && (
                                                                        <div
                                                                            className='dropzone'
                                                                            {...getRootProps()}
                                                                        >
                                                                            <input {...getInputProps()} />
                                                                            <span>
                                                                                {text?.inventory?.details?.uploadImages}
                                                                            </span>
                                                                        </div>
                                                                    )
                                                                }
                                                            </div>
                                                        }
                                                    >
                                                        <ConditionalRender renderIf={!isEmpty(designs)}>
                                                            <RenderImages
                                                                images={designs}
                                                                disabled={projectIsComplete}
                                                                isEditing={isEditing}
                                                                removeImage={removeImage}
                                                                openModal={openCarousel}
                                                                closeModal={closeModal}
                                                                setActiveIndex={setActiveIndex}
                                                                activeIndex={activeIndex}
                                                                isModalOpen={isModalOpen}
                                                            />
                                                        </ConditionalRender>

                                                        <ConditionalRender renderIf={isEmpty(designs)}>
                                                            <p className='mb-md'>
                                                                {'No Images'}
                                                            </p>
                                                        </ConditionalRender>

                                                        {
                                                            !isEmpty(imageError) && (
                                                                <span style={{
                                                                    color: colors.red
                                                                }}>
                                                                    {imageError}
                                                                </span>
                                                            )
                                                        }
                                                    </Wrapper>

                                                    {/* Events */}
                                                    <Wrapper
                                                        title={text?.calendar?.calendar}
                                                        button={
                                                            <CallToAction
                                                                text={text?.calendar?.btnViewCalendar}
                                                                type='button'
                                                                onClick={() => navigate(`/calendar`)}
                                                            />
                                                        }
                                                    >
                                                        <FullCalendarContainer
                                                            events={events}
                                                            eventsIsLoading={eventsIsLoading}
                                                            user={user}
                                                            projects={projects}
                                                            employees={employees}
                                                            employeesIsLoading={employeesIsLoading}
                                                            fetchEvents={fetchEvents}
                                                            isProject
                                                            projectId={id}
                                                            projectIsLoading={projectIsLoading}
                                                            fetchEventCategories={fetchEventCategories}
                                                        />
                                                    </Wrapper>
                                                </Wrapper>
                                            </div>

                                            <ConditionalRender renderIf={!projectIsComplete}>
                                                <ConditionalRender renderIf={!isEmpty(inventory)}>
                                                    <ConditionalRender renderIf={!isEmpty(adminProjects)}>
                                                        <div className='flex items-center justify-space-between mt-md'>
                                                            <div className='flex flex-column align-items-start'>
                                                                <p>
                                                                    {text?.projects?.details?.inventoryInfo}
                                                                </p>
                                                                <p className='mt-md w-100'>
                                                                    <SelectInput
                                                                        name='newInventoryAssignedProjectId'
                                                                        placeholder={text?.projects?.details?.selectProject}
                                                                        isSearchable={false}
                                                                        setIsEditing={setIsEditing}
                                                                        menuPlacement='top'
                                                                        options={adminProjects?.filter((x) => !x?.isCompleted)?.map((x) => {
                                                                            return {
                                                                                label: x.projectName,
                                                                                value: x.id
                                                                            }
                                                                        })}
                                                                        noFormat
                                                                        disabled={projectIsComplete}
                                                                        style={{
                                                                            width: isDesktop ? '30%' : isTablet ? '50%' : '100%'
                                                                        }}
                                                                    />
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </ConditionalRender>
                                                </ConditionalRender>
                                            </ConditionalRender>

                                            <ConditionalRender renderIf={user?.roleName === 'Admin'}>
                                                <CallToAction
                                                    style={{ alignSelf: 'flex-end', flex: '1', backgroundColor: colors.red, marginTop: '1em' }}
                                                    onClick={openRemoveModal}
                                                    text={text?.projects?.details?.removeProject}
                                                />
                                            </ConditionalRender>

                                            <ConfirmModal
                                                onClick={handleRemoveProject}
                                                text={text?.projects?.details?.deleteModal?.button}
                                                toggle={openRemoveModal}
                                                isOpen={isOpen}
                                                btnStyles={{
                                                    color: colors.red
                                                }}
                                                width={isDesktop ? '50%' : '100%'}
                                                height={isDesktop ? '50%' : '100%'}
                                            >
                                                <div>
                                                    <InventoryModalMessage
                                                        type='delete'
                                                        inventory={inventory}
                                                        projects={projects}
                                                        id={id}
                                                        deleteInventory={deleteInventory}
                                                        setDeleteInventory={setDeleteInventory}
                                                        assignedProject={assignedProject}
                                                        setAssignedProject={setAssignedProject}
                                                        showAssignedProject={showAssignedProject}
                                                        setShowAssignedProject={setShowAssignedProject}
                                                    />
                                                    <div className='mt-lg'>
                                                        <p>
                                                            {text?.projects?.details?.deleteModal?.textone}
                                                        </p>
                                                        <p>
                                                            {text?.projects?.details?.deleteModal?.texttwo}
                                                        </p>
                                                    </div>
                                                </div>
                                            </ConfirmModal>

                                            {
                                                (!projectIsComplete && isEditing) && (
                                                    <FormActions
                                                        floating
                                                        type="submit"
                                                        form={form}
                                                        submitText={text?.projects?.details?.saveButton}
                                                        btnStyle={{
                                                            color: colors.blue
                                                        }}
                                                    />
                                                )
                                            }
                                        </form>
                                    )
                                }}
                            />
                        </div>
                    </ConditionalRender>

                    {/* View for everyone else */}
                    <ConditionalRender renderIf={!isAdmin && !EmployeeIsAdmin}>
                        <DetailsProjectEmployeeContainer
                            projectIsComplete={projectIsComplete}
                            projects={projects}
                            project={project}
                            user={user}
                            employees={employees}
                            tasks={tasks}
                            tasksIsLoading={tasksIsLoading}
                            fetchProjectTasks={fetchProjectTasks}
                            fetchInventoryProducts={fetchInventoryProducts}
                            inventory={inventory}
                            inventorysLoading={inventorysLoading}
                            inventoryColumns={inventoryColumns}
                            setInventoryColumns={setInventoryColumns}
                            fetchAttributes={fetchAttributes}
                            isAdmin={isAdmin}
                            EmployeeIsAdmin={EmployeeIsAdmin}
                            isDashboard={isDashboard}
                            projectId={id}
                            autocompleteValue={autocompleteValue}
                            mapCenter={mapCenter}
                            onLoad={onLoad}
                            designs={designs}
                            openCarousel={openCarousel}
                            closeModal={closeModal}
                            setActiveIndex={setActiveIndex}
                            activeIndex={activeIndex}
                            isModalOpen={isModalOpen}
                            timeLogs={timeLogs}
                            logsIsLoading={logsIsLoading}
                            fetchTimeLogs={fetchTimeLogs}
                            userRole={userRole}
                            client={client}
                            events={events}
                            eventsIsLoading={eventsIsLoading}
                            employeesIsLoading={employeesIsLoading}
                            fetchEvents={fetchEvents}
                            projectIsLoading={projectIsLoading}
                            company={company}
                            fetchCompanyEmployees={fetchCompanyEmployees}
                            fetchEventCategories={fetchEventCategories}
                            historyStatus={historyStatus}
                            setHistoryStatus={setHistoryStatus}

                        />
                    </ConditionalRender>

                    {/* Expanded Components */}
                    <ExpandedContainer
                        expanded={expanded}
                        setExpanded={setExpanded}
                        projects={projects}
                        user={user}
                        clients={clients}
                        proposals={proposals}
                        proposalsIsLoading={proposalsIsLoading}
                        fetchAllProposals={fetchAllProposals}

                        fetchAllClients={fetchAllClients}
                        clientsIsLoading={clientsIsLoading}
                        fetchCompanyProjects={fetchCompanyProjects}

                        employees={employees}
                        tasks={tasks}
                        tasksIsLoading={tasksIsLoading}
                        fetchProjectTasks={fetchProjectTasks}
                        isProject
                        projectId={id}
                        project={project}
                        projectIsCompleted={projectIsComplete}

                        filteredEmployees={employees?.filter((x) => x?._id !== user?._id && selectedEmployees?.includes(x._id))}
                        userRole={userRole}
                        company={company}
                        fetchCompanyEmployees={fetchCompanyEmployees}
                        employeesIsLoading={employeesIsLoading}
                        checkboxSelection={false}
                        isDetailsProject
                        EmployeeIsAdmin={EmployeeIsAdmin}
                        projectIsComplete={projectIsComplete}
                        openEmployeeModal={openEmployeeModal}
                        employeeModalIsOpen={employeeModalIsOpen}
                        id={id}
                        handleGetProject={handleGetProject}

                        inventory={inventory}
                        fetchInventoryProducts={fetchInventoryProducts}
                        inventorysLoading={inventorysLoading}
                        inventoryColumns={inventoryColumns}
                        setInventoryColumns={setInventoryColumns}
                        fetchAttributes={fetchAttributes}
                        noDataText={text?.projects?.details?.noinventory}
                    />
                </StyledDiv>
                <ConfirmModal
                    isOpen={historyStatus}
                    toggle={() => setHistoryStatus(!historyStatus)}
                    width={isDesktop ? '50%' : '100%'}
                    height={isDesktop ? '50%' : '100%'}
                >
                    <div className='flex flex-column w-100'>
                        <h4 className='flex mb-md'>
                            {text?.tasks?.details?.statusHistory}
                        </h4>
                        {
                            project?.history && project?.history
                                ?.sort((a, b) => moment(b.timestamp).valueOf() - moment(a.timestamp).valueOf()) // Sort by date using moment
                                ?.map((value, index) => {
                                    return (
                                        <>
                                            <div key={index} className='flex justify-space-between pr-md'>
                                                <p className='b mr-md'>
                                                    {`${value?.action} by ${value?.changedBy?.firstName} ${value?.changedBy?.lastName}`}
                                                </p>
                                                <p>
                                                    {moment.utc(value?.timestamp).format('MM-DD-YYYY')}
                                                </p>
                                            </div>
                                            <hr />
                                        </>
                                    )
                                })
                        }
                    </div>
                </ConfirmModal>
            </ConditionalRender>
        )
}

export default DetailsProjectContainer