import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import colors from '../globalStyles.scss';

const PriorityCheckbox = ({ productId, initialValue, handlePriorityChange }) => {
    const togglePriority = () => {
        handlePriorityChange({ productId, isPriority: initialValue }); // Pass the updated value of isPriority
    };

    return (
        <div style={{ cursor: 'pointer' }} onClick={togglePriority}>
            <FontAwesomeIcon icon={faStar} style={{ color: initialValue ? colors.yellow : colors.black }} />
        </div>
    );
};

export default PriorityCheckbox;
