import React from 'react';
import styled from 'styled-components';
import colors from '../globalStyles.scss';
import logo from '../images/ardidflow_logo_small.png';
import { useLanguage } from '../context/LanguageContext';
import useScreenSize from '../context/useScreenSize';
import { Link } from 'react-router-dom';
import ConditionalRender from '../Core/ConditionalRender';

const StyledDiv = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    overflow: hidden;
    background: linear-gradient(90deg, rgba(0, 0, 0, 1) 0%, rgba(14, 14, 20, 1) 45%, rgba(11, 99, 128, 1) 66%, rgba(50, 150, 180, 1) 85%, rgba(21, 65, 82, 1) 100%);
    
    &.tablet, &.phone {
        background: ${colors.black};
        padding-bottom: 1em;
        padding-top: 1em;
        h1, h2 {
            font-size: 2em !important;
        }
        img {
            width: 200px !important;
        }
        a, .error {
            color: ${colors.white};
        }
        .wrapper {
            justify-content: flex-start;
            align-items: flex-start;
            form {
                width: 100%;
            }
        }
    }

    .left {
        flex: 1;
        img {
            width: 500px;
            margin-bottom: 1em;
        }
        h1 {
            color: ${colors.white};
            font-size: 6em;
        }
        h2 {
            color: ${colors.white};
            font-size: 2.5em;
            font-weight: 400;
        }

        &.tablet, &.phone {
            display: none;
        }
    }

    .right {
        flex: 1;
        display: flex;
        text-align: left;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
           h1 {
            color: ${colors.white};
            font-size: 6em;
        }
         h2 {
            color: ${colors.white};
            font-size: 2.5em;
            font-weight: 400;
        }
        img {
            width: 300px;
            margin-bottom: 1em;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    .wrapper {
        padding: 0 1em;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        p {
            color: ${colors.white};
            font-size: 1.2rem;
            margin-bottom: 10px;
        }
        a {
            color: ${colors.white};
            font-size: 1.2rem;
            margin-bottom: 10px;
        }
        form {
            width: 80%;
            label {
                color: ${colors.white} !important;
                font-size: 1rem;
                margin-bottom: 10px;
            }
        }
    }
`;

const WaitListContainer = ({
    route = 'login',
    loginUser,
    logoutUser,
    user,
    isLoggedIn
}) => {
    const { language } = useLanguage();
    const { isDesktop, isTablet, isPhone } = useScreenSize();

    const getScreenClass = () => {
        if (isPhone) return 'phone';
        if (isTablet) return 'tablet';
        if (isDesktop) return 'desktop';
        return '';
    };

    const screenClass = getScreenClass();

    // Define translations
    const translations = {
        en: {
            title: 'ArdidFlow',
            waitlist: 'You are on the waitlist!',
            verifyEmail: 'Please verify your email before gaining full access to the platform. You will receive a verification email.',
            thanks: 'Thank you for your interest in using our Services. Due to high demand, we are currently onboarding users in phases to ensure the best experience for everyone.',
            waitlistNotice: 'You have been placed on the waitlist, and we will notify you via email as soon as your access is granted.',
            appreciation: 'We appreciate your patience and look forward to having you on board soon!',
            contact: 'If you have any questions in the meantime, feel free to contact us at ',
            contactUs: 'For further inquiries, please visit our ',
            contactPage: 'Contact Page',
        },
        es: {
            title: 'ArdidFlow',
            waitlist: '¡Estás en la lista de espera!',
            verifyEmail: 'Por favor, verifica tu correo electrónico antes de obtener acceso completo a la plataforma. Recibirás un correo electrónico de verificación.',
            thanks: '¡Gracias por tu interés en nuestros servicios. Debido a la alta demanda, actualmente estamos incorporando usuarios en fases para garantizar la mejor experiencia para todos.',
            waitlistNotice: 'Has sido agregado a la lista de espera y te notificaremos por correo electrónico tan pronto como tu acceso sea concedido.',
            appreciation: 'Agradecemos tu paciencia y esperamos tenerte a bordo pronto.',
            contact: 'Si tienes alguna pregunta mientras tanto, no dudes en contactarnos en ',
            contactUs: 'Para más consultas, visita nuestra ',
            contactPage: 'Página de Contacto',
        }
    };

    // Get the text based on the selected language
    const t = translations[language] || translations.en;

    return (
        <StyledDiv className={screenClass}>
            <div className={`left ${screenClass}`}>
                <img src={logo} alt="logo" />
                <h1>{t.title}</h1>
                <h2>{t.waitlist}</h2>
            </div>
            <div className="right flex flex-column items-center scroll-container">
                <ConditionalRender renderIf={!isDesktop}>
                    <div className="mb-md w-100 flex flex-column items-start wrapper">
                        <img src={logo} alt="logo" />
                        <h1>{t.title}</h1>
                        <h2>{t.waitlist}</h2>
                    </div>
                </ConditionalRender>
                <div className="w-100 flex flex-column items-start wrapper items-center">
                    <div className="flex flex-column w-100 items-start">
                        <ConditionalRender renderIf={!user?.isAccountVerified}>
                            <p className="mb-sm">{t.verifyEmail}</p>
                        </ConditionalRender>
                        <p className="mb-sm">{t.thanks}</p>
                        <p className="mb-sm">{t.waitlistNotice}</p>
                        <p className="mb-sm">{t.appreciation}</p>
                        <p className="mb-sm">
                            {t.contact}
                            <a href="mailto:admin@ardidflow.com">support@ardidflow.com</a>.
                        </p>
                        <p className="mb-sm">
                            {t.contactUs}
                            <Link to="/contact-us">{t.contactPage}</Link>.
                        </p>
                    </div>
                </div>
            </div>
        </StyledDiv>
    );

};

export default WaitListContainer;
