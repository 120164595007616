import React, { useEffect } from 'react';
import LoginContainer from '../Login/LoginContainer';
import RegistrationContainer from '../Registration/RegistrationContainer';
import PasswordReset from '../Login/PasswordReset';
import ConditionalRender from '../Core/ConditionalRender';
import styled from 'styled-components';
import colors from '../globalStyles.scss';
import logo from '../images/logo.png';
import { useLanguage } from '../context/LanguageContext';
import useScreenSize from '../context/useScreenSize';
import { useNavigate } from 'react-router';

const StyledDiv = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    overflow: hidden;
    background: linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(14,14,20,1) 45%, rgba(11,99,128,1) 66%, rgba(252,255,230,1) 100%, rgba(21,65,82,1) 3458%);
    
    &.tablet, &.phone {
        background: ${colors.black};
        padding-bottom: 1em;
        padding-top: 1em;
        a, .error {
            color: ${colors.white};
        }
        .wrapper {
            justify-content: flex-start;
            align-items: flex-start;
            form {
                width: 100%;
            }
        }
    }

    .left {
        flex: 1;
        img {
            width: 500px;
            margin-bottom: 1em;
        }
        h1 {
            color: ${colors.white};
            font-size: 6em;
        }
        h2 {
            color: ${colors.white};
            font-size: 2.5em;
            font-weight: 400;
        }

        &.tablet, &.phone {
            display: none;
        }
    }

    .right {
        flex: 1;
        height: 100%;
        display: flex;
    }

    .wrapper {
        padding: 0 1em;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        form {
            width: 80%;
            label {
                color: ${colors.white} !important;
                font-size: 1rem;
                margin-bottom: 10px;
            }
        }
    }
`;

const RegisterAndLoginContainer = ({
    route = 'login',
    loginUser,
    logoutUser,
    user,
    isLoggedIn
}) => {
    const { text } = useLanguage();
    const navigate = useNavigate();
    const { isDesktop, isTablet, isPhone } = useScreenSize();

    const getScreenClass = () => {
        if (isPhone) return 'phone';
        if (isTablet) return 'tablet';
        if (isDesktop) return 'desktop';
        return '';
    };

    const screenClass = getScreenClass();

    useEffect(() => {
        if (isLoggedIn) {
            navigate('/');
        }
    }, [])

    return (
        <StyledDiv className={screenClass}>
            <div className={`left ${screenClass}`}>
                <img src={logo} alt='logo' />
                <h1>
                    {'ArdidFlow'}
                </h1>
                <h2>
                    {text?.homeLandingContainer?.title}
                </h2>
            </div>
            <div className='right scroll-container'>
                <ConditionalRender renderIf={route === 'login'}>
                    <LoginContainer
                        route={'login'}
                        loginUser={loginUser}
                        logoutUser={logoutUser}
                        user={user}
                        isLoggedIn={isLoggedIn}
                    />
                </ConditionalRender>
                <ConditionalRender renderIf={route === 'register'}>
                    <RegistrationContainer route={route} />
                </ConditionalRender>
                <ConditionalRender renderIf={route === 'passwordReset'}>
                    <PasswordReset />
                </ConditionalRender>
            </div>
        </StyledDiv>
    );
};

export default RegisterAndLoginContainer;
