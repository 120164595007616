import React from 'react';
import { isEmpty } from 'lodash';
import styled from 'styled-components'
import colors from '../globalStyles.scss'
import CallToAction from './CallToAction';
import AutocompleteDropdown from './Inputs/AutocompleteDropdown';
import { useTheme } from '../context/ThemeContext'
import useScreenSize from '../context/useScreenSize';
import classnames from 'classnames';

const StyledInput = styled.div`
    &.desktop, &.tablet {
        display: flex;
        position: relative;
        justify-content: space-between;
        align-items: center;
        align-self: self-start;
    }
    width: 100%;
    margin-bottom: 15px;
    div label {
        top: 10px;
        left: 0;
        -webkit-transition: all 0.25s ease;
        transition: all 0.25s ease;
        pointer-events: none;
    }
    .error {
        right: 0;
        margin: 5px 0;
    }
    input {
        border: none;
        border-bottom: ${(props) => props.value ? '1px solid' + colors.blue : '1px solid black'};
        width: 100%;
        ~ label {
            font-size: ${(props) => props.value ? '0.75rem' : '1rem'};
            color: ${(props) => props.value ? '#999' : 'black'};;
            top: ${(props) => props.value ? '-10px' : '0'};;
            -webkit-transition: all 0.225s ease;
            transition: all 0.225s ease;
        }
        }
    }
    input:focus,
    textarea:focus {
    outline: 0;
    border-bottom: 1px solid ${colors.blue};
    }
    input:focus ~ label,
    textarea:focus ~ label {
    font-size: 0.75rem;
    color: #999;
    top: -10px;
    -webkit-transition: all 0.225s ease;
    transition: all 0.225s ease;
    }
`

const CustomField = ({
    value,
    onChange,
    wrapperStyles,
    fieldNames,
    newFieldName,
    name = 'customFieldName',
    placeholder,
    label,
    style
}) => {
    const { theme } = useTheme();
    const { isDesktop, isTablet, isPhone } = useScreenSize()

    return (
        <StyledInput
            theme={theme}
            value={value}
            className={classnames({
                'CustomField': true,
                'desktop': isDesktop,
                'tablet': isTablet,
                'phone': isPhone
            })}
        >
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    position: 'relative',
                    flex: '1',
                    ...wrapperStyles
                }}>

                <AutocompleteDropdown
                    name={name}
                    label={label}
                    options={fieldNames}
                    placeholder={placeholder || 'Select a field'}
                    onChange={onChange}
                    newFieldName={newFieldName}
                    width={100}
                    style={style}

                />
            </div>
        </StyledInput>
    )
}

export default CustomField