import React, { useState } from 'react';
import { Form } from 'react-final-form';
import FormActions from '../Core/FormActions';
import FieldWithValidation from '../Core/FieldWithValidation';
import {
    registerCompany,
    setRole,
    createUser
} from '../utils/calls'
import SelectInput from '../Core/SelectInput';
import CallToAction from '../Core/CallToAction';
import { isEmpty } from 'lodash';
import { composeValidators, isRequired, minLength, matchesPattern, isValidEmail } from '../utils/validators';
import { useLanguage } from '../context/LanguageContext';
import colors from '../globalStyles.scss'
import ConditionalRender from '../Core/ConditionalRender';

const RegistrationContainer = () => {
    const { text } = useLanguage();

    const [emailSent, setEmailSent] = useState(false);
    const [error, setError] = useState('');
    const [showPassword, setShowPassword] = useState(false);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const errorMessage = text?.registration?.errorMessage


    const onSubmit = (values) => {
        registerCompany(values)
            .then((companyRes) => {
                if (companyRes.status !== 200) {
                    throw new Error('User registration failed');
                }
                return companyRes
            })
            .then((roleRes) => {
                const permissions = ['all']
                const roleValues = {
                    companyId: roleRes?.data?._id,
                    roleName: 'Admin',
                    permissions
                };
                return setRole(roleValues)
            })
            .then((userRes) => {
                const roleId = userRes?.data?.id
                const roleName = userRes?.data?.roleName
                const companyId = userRes?.data?.companyId
                if (userRes.status !== 200) {
                    throw new Error('Role assignment failed');
                }
                return createUser({
                    ...values,
                    roleId,
                    roleName,
                    companyId
                })
            })
            .then(() => {
                setEmailSent(true);
            })
            .catch((error) => {
                console.error(error);
                setEmailSent(false);
                setError(errorMessage);
            });
    };


    const passwordValidator = composeValidators(
        isRequired(text?.registration?.passwordValidators?.required),
        minLength(8)(text?.registration?.passwordValidators?.length),
        matchesPattern(/\d/, text?.registration?.passwordValidators?.number),
        matchesPattern(/[a-z]/, text?.registration?.passwordValidators?.lowercase),
        matchesPattern(/[\W_]/, text?.registration?.passwordValidators?.special)
    );
    const confirmPasswordValidator = (value, allValues) => {
        if (value !== allValues.password) {
            return text?.registration?.passwordValidators?.match;
        }
        return undefined;
    };
    const emailValidator = composeValidators(
        isRequired(text?.registration?.emailValidators?.required),
        isValidEmail(text?.registration?.emailValidators?.valid)
    )

    return (
        <div className='RegistrationContainer wrapper'>
            <ConditionalRender renderIf={emailSent}>
                <p style={{
                    color: colors.white,
                    textAlign: 'center',
                    fontSize: '1.2rem',
                    fontFamily: colors.openSans
                }}>{text?.registration?.verifyEmail}</p>
            </ConditionalRender>
            <ConditionalRender renderIf={!emailSent}>
                <Form
                    onSubmit={onSubmit}
                    render={({ handleSubmit }) => (
                        <form onSubmit={handleSubmit} className='pt-md pb-md h-100'>
                            <small style={{
                                textAlign: 'left',
                                display: 'flex',
                                color: colors.white,
                            }}>
                                {text?.registration?.allRequired}
                            </small>
                            <FieldWithValidation
                                className='field-style'
                                validate={composeValidators(
                                    isRequired(text?.registration?.required))}
                                label={text?.registration?.fields?.firstName}
                                name='firstName'
                                component={'input'} type='text'
                            />
                            <FieldWithValidation
                                className='field-style'
                                validate={composeValidators(
                                    isRequired(text?.registration?.required))}
                                label={text?.registration?.fields?.lastName}
                                name='lastName'
                                component={'input'}
                                type='text'
                            />
                            <FieldWithValidation
                                className='field-style'
                                validate={emailValidator}
                                label={text?.registration?.fields?.email}
                                name='email'
                                component={'input'}
                                type='email'
                            />
                            <FieldWithValidation
                                className='field-style'
                                validate={isRequired(text?.registration?.required)}
                                label={text?.registration?.fields?.companyName}
                                name='companyName'
                                component={'input'}
                                type='text'
                            />
                            <SelectInput
                                style={{
                                    marginTop: '1em',
                                    width: '100%',
                                }}
                                isClearable={false}
                                isSearchable={false}
                                validate={composeValidators(
                                    isRequired(text?.registration?.required))}
                                name={'companyIndustry'}
                                options={[
                                    { value: 'construction', label: text?.registration?.fields?.companyIndustry?.options?.construction },
                                    { value: 'retail', label: text?.registration?.fields?.companyIndustry?.options?.retail },
                                    { value: 'manufacturing', label: text?.registration?.fields?.companyIndustry?.options?.manufacturing },
                                    { value: 'wholesale', label: text?.registration?.fields?.companyIndustry?.options?.wholesale },
                                    { value: 'distribution', label: text?.registration?.fields?.companyIndustry?.options?.distribution },
                                    { value: 'logistics', label: text?.registration?.fields?.companyIndustry?.options?.logistics },
                                    { value: 'hospitality', label: text?.registration?.fields?.companyIndustry?.options?.hospitality },
                                    { value: 'automotive', label: text?.registration?.fields?.companyIndustry?.options?.automotive },
                                    { value: 'healthcare', label: text?.registration?.fields?.companyIndustry?.options?.healthcare },
                                    { value: 'supply', label: text?.registration?.fields?.companyIndustry?.options?.supply },
                                    { value: 'other', label: text?.registration?.fields?.companyIndustry?.options?.other }
                                ]}
                                placeholder={text?.registration?.fields?.companyIndustry?.label}

                            />
                            <FieldWithValidation
                                className='field-style'
                                togglePasswordVisibility={togglePasswordVisibility}
                                showPassword={showPassword}
                                validate={passwordValidator}
                                label={text?.registration?.fields?.password}
                                name='password'
                                component={'input'}
                                type={showPassword ? 'text' : 'password'}
                            />
                            <FieldWithValidation
                                className='field-style'
                                togglePasswordVisibility={togglePasswordVisibility}
                                showPassword={showPassword}
                                validate={confirmPasswordValidator}
                                label={text?.registration?.fields?.confirmPassword}
                                name='confirmPassword'
                                component={'input'}
                                type={showPassword ? 'text' : 'password'}
                            />
                            <FormActions
                                type="submit"
                                callToActionText={text?.registration?.button}
                                position='center'
                                btnStyle={{
                                    border: `2px solid ${colors.black}`,
                                    color: colors.white,
                                    hoverBackgroundColor: colors.white,
                                    padding: '0.5em 1em',
                                    fontWeight: '600',
                                }}
                            />
                            <CallToAction
                                btnWidth='auto'
                                btnHeight={'auto'}
                                text={text?.registration?.login}
                                linkTo={'/login'}
                                btnStyle={{
                                    padding: '0.3em 1em',
                                    border: `2px solid ${colors.white}`
                                }}
                                style={{
                                    alignSelf: 'center',
                                    justifyContent: 'center',
                                    backgroundColor: 'none',
                                    marginTop: '1em'
                                }}
                            />
                            {
                                !isEmpty(error) && (
                                    <div className='small error'>
                                        {error}
                                    </div>
                                )
                            }
                        </form>
                    )}
                />
            </ConditionalRender>
        </div>
    );
};

export default RegistrationContainer;
