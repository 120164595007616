import { uploadImagesAndSendMessage } from './uploadImagesAndSendMessage';

export const createMessage = async (
    conversationId,
    messageContent,
    messageType = 'text',
    userId,
    imagePreviews,
    callback,
    setIsSendingMessage,
    setImagePreviews,
    setMessages,
    ws
) => {

    if (!ws.current) {
        console.error('WebSocket is not connected.');
        setIsSendingMessage(false);
        return;
    }

    try {
        // Step 1: Create the initial message
        const hasImages = imagePreviews.length > 0;
        const newMessage = {
            conversationId,
            sender: userId,
            messageContent,
            messageType,
            hasImages,
        };

        ws.current.emit('createMessage', newMessage, async (response) => {
            if (response.success) {
                const createdMessage = response.message;

                // Step 2: Handle image uploads if necessary
                setIsSendingMessage(true);
                if (hasImages) {
                    await uploadImagesAndSendMessage(
                        ws,
                        conversationId,
                        createdMessage._id,
                        imagePreviews,
                        (finalizedMessage) => {
                            if (finalizedMessage) {
                                // Update local state with the finalized message
                                setMessages((prevMessages) => {
                                    const updatedMessages = prevMessages.map((msg) =>
                                        msg._id === createdMessage._id ? finalizedMessage : msg
                                    );

                                    if (!updatedMessages.some((msg) => msg._id === createdMessage._id)) {
                                        updatedMessages.push(finalizedMessage);
                                    }

                                    return updatedMessages;
                                });

                                callback?.(finalizedMessage);
                                setIsSendingMessage(false);
                                setImagePreviews([]);
                            } else {
                                callback?.(null, 'Failed to upload images.');
                            }

                        }
                    );
                } else {
                    const updatedMessage = { ...createdMessage, messageContent };
                    setMessages((prevMessages) => {
                        const updatedMessages = prevMessages.map((msg) =>
                            msg._id === createdMessage._id ? { ...msg, ...updatedMessage } : msg
                        );

                        if (!updatedMessages.some((msg) => msg._id === createdMessage._id)) {
                            updatedMessages.push(updatedMessage);
                        }

                        return updatedMessages;
                    });
                    setIsSendingMessage(false);
                    callback?.(createdMessage);
                }
                // Notify other participants
                ws.current.emit('notifyUnreadMessage', {
                    conversationId,
                    sender: userId,
                    newMessage
                });
            } else {
                console.error('Failed to create message:', response.message);
                callback?.(null, response.message || 'Failed to create message');
                setIsSendingMessage(false);
            }
        });
    } catch (error) {
        console.error('Error creating message:', error);
        callback?.(null, 'Failed to send message due to an error.');
        setIsSendingMessage(false);
    }
};

