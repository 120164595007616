export const handleUpdateParticipants = (
    conversationId,
    updatedUserParticipants,
    updatedClientParticipants,
    user,
    ws,
    setConversations,
    setParticipants,
    setClientParticipants,
    setSelectedConversation,
    setSelectedConversationId,
    setMessages,
    selectedConversationId
) => {
    if (!ws.current) {
        console.error('WebSocket is not connected.');
        return;
    }

    ws.current.emit(
        'updateParticipants',
        { conversationId, updatedUserParticipants, updatedClientParticipants, companyId: user?.companyId, currentUserId: user?._id },
        (response) => {
            if (response.success) {

                // Update the local state immediately to reflect changes
                setConversations((prevConversations) =>
                    prevConversations.map((conversation) =>
                        conversation._id === response.conversation._id
                            ? response.conversation
                            : conversation
                    )
                );
                setParticipants(response.conversation.participants.map((p) => p._id));
                setClientParticipants(response.conversation.clientParticipants.map((c) => c._id));

                if (selectedConversationId === response.conversation._id) {
                    setSelectedConversation(response.conversation);
                    setSelectedConversationId(response.conversation._id);
                } else {
                    setParticipants(response.conversation.participants.map((p) => p._id));
                    setClientParticipants(response.conversation.clientParticipants.map((c) => c._id));
                    setSelectedConversation(response.conversation);
                    setSelectedConversationId(response.conversation._id);

                    // update messages
                    setMessages(response.conversation.messages);
                }
            } else {
                console.error('Failed to update participants:', response.message);
            }
        }
    );
};