import React, { useState, useEffect } from 'react';
import SelectInput from '../SelectInput'
import { Field } from 'react-final-form';
import { FieldStyles } from './FieldStyles';
import FieldWithValidation from '../FieldWithValidation';
import { volumeUnits } from '../../utils/helperFunctions';
import { useLanguage } from '../../context/LanguageContext';
import useScreenSize from '../../context/useScreenSize';


const VolumeField = ({
    label, name, setIsEditing, input, ...rest
}) => {
    const { text } = useLanguage();
    const { isDesktop, isTablet, isPhone } = useScreenSize();


    const initialValue = input?.value?.includes('-') ? input?.value?.split('-')[0] : input?.value;
    const initialUnit = input?.value?.includes('-') ? input?.value?.split('-')[1] : 'l';

    const [value, setValue] = useState(initialValue);
    const [unit, setUnit] = useState(initialUnit);

    const [initialVolumeValue, setInitialVolumeValue] = useState(volumeUnits(text)?.find(u => u?.value === unit));

    useEffect(() => {
        setInitialVolumeValue(volumeUnits(text)?.find(u => u?.value === unit));
    }, [])


    useEffect(() => {
        if (input?.value?.includes('-')) {
            setValue(input?.value.split('-')[0]);
            setUnit(input?.value.split('-')[1]);
        } else {
            setValue(input?.value);
        }
    }, [input?.value]);

    const handleUnitChange = (selectedUnit) => {
        setUnit(selectedUnit?.value);
        setIsEditing && setIsEditing(true)
        // If you need to trigger any action when the unit changes, do it here.
    };

    // When value changes, update the input.onChange
    useEffect(() => {
        input?.onChange(value);
    }, [value]);

    return (
        <div className='flex flex-column'>
            <FieldWithValidation
                className='field-style'
                type="text"
                name={name}
                input={{ ...input, value }}
                setIsEditing={setIsEditing}
                fieldName={'volume'}
                {...rest}
            />
            <SelectInput
                placeholder={'Liters'}
                options={volumeUnits(text)}
                name={`${name}-unit`}
                initialValue={initialVolumeValue}
                onChange={handleUnitChange}
                disabled={rest?.disabled}
                isClearable={false}
                isValidNewOption={() => false}
                style={
                    isDesktop ? { width: '30%' } : isTablet ? { width: '50%' } : { width: '100%' }
                }
            />
        </div>
    )
}


const VolumeInput = ({ name, label, validate, ...rest }) => {
    return (
        <FieldStyles>
            <label className='custom-label'>{label}</label>
            <div className='custom-field'>
                <Field name={name} validate={validate} {...rest}>
                    {({ input, meta }) => {
                        return (
                            <VolumeField
                                input={input}
                                meta={meta}
                                label={label}
                                name={input?.name}
                                onChange={input?.onChange}
                                {...rest}
                            />
                        );
                    }}
                </Field>
            </div>
        </FieldStyles>
    )
}

export default VolumeInput