// App.js (React App)
import React, { useEffect, useState, useRef } from "react";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import { verify } from '../utils/calls'
import colors from '../globalStyles.scss'
import { useTheme } from '../context/ThemeContext'
import styled from 'styled-components'
import useScreenSize from '../context/useScreenSize';
import logo from '../images/logo.png';
import ConditionalRender from "../Core/ConditionalRender";


const StyledDiv = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    overflow: hidden;
    background: linear-gradient(90deg, rgba(0, 0, 0, 1) 0%, rgba(14, 14, 20, 1) 45%, rgba(11, 99, 128, 1) 66%, rgba(50, 150, 180, 1) 85%, rgba(21, 65, 82, 1) 100%);
    
    &.tablet, &.phone {
        background: ${colors.black};
        padding-bottom: 1em;
        padding-top: 1em;
        h1, h2 {
            font-size: 2em !important;
        }
        img {
            width: 200px !important;
        }
        a, .error {
            color: ${colors.white};
        }
        .wrapper {
            justify-content: flex-start;
            form {
                width: 100%;
            }
        }
    }

    .left {
        flex: 1;
        img {
            width: 500px;
            margin-bottom: 1em;
        }
        h1 {
            color: ${colors.white};
            font-size: 6em;
        }
        h2 {
            color: ${colors.white};
            font-size: 2.5em;
            font-weight: 400;
        }

        &.tablet, &.phone {
            display: none;
        }
    }

    .right {
        flex: 1;
        display: flex;
        text-align: left;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
           h1 {
            color: ${colors.white};
            font-size: 6em;
        }
         h2 {
            color: ${colors.white};
            font-size: 2.5em;
            font-weight: 400;
        }
        img {
            width: 300px;
            margin-bottom: 1em;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    .wrapper {
        padding: 0 1em;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        p {
            color: ${colors.white};
            font-size: 1.2rem;
            margin-bottom: 10px;
        }
        a {
            color: ${colors.white};
            font-size: 1.2rem;
            margin-bottom: 10px;
        }
        form {
            width: 80%;
            label {
                color: ${colors.white} !important;
                font-size: 1rem;
                margin-bottom: 10px;
            }
        }
    }
`;

const VerifyEmail = ({
    isLoggedIn
}) => {
    const [message, setMessage] = useState("");
    const location = useLocation();
    const { theme } = useTheme();

    const { isDesktop, isTablet, isPhone } = useScreenSize();

    const getScreenClass = () => {
        if (isPhone) return 'phone';
        if (isTablet) return 'tablet';
        if (isDesktop) return 'desktop';
        return '';
    };

    const screenClass = getScreenClass();

    // Extract the token from the query string
    const { token } = queryString.parse(location.search);

    const hasRun = useRef(false);

    useEffect(() => {
        const verifyEmail = async () => {
            try {
                const res = await verify(token);
                if (res.status === 200) {
                    setMessage(res?.data?.message);
                } else {
                    setMessage("Error verifying email");
                }
            } catch (error) {
                console.error('error', error);
                setMessage("Error verifying email");
            }
        };

        if (token && !hasRun.current) {
            verifyEmail();
            hasRun.current = true;
        } else if (!token) {
            setMessage("No token found");
        }
    }, [token]);

    return (
        <StyledDiv className={screenClass}>
            <ConditionalRender renderIf={!isLoggedIn}>
                <div className={`left ${screenClass}`}>
                    <img src={logo} alt='logo' />
                    <h1>
                        {'ArdidFlow'}
                    </h1>
                </div>
            </ConditionalRender>
            <div className='right flex flex-column items-center scroll-container'>
                <ConditionalRender renderIf={!isDesktop}>
                    <div className='mb-md w-100 flex flex-column wrapper'>
                        <img src={logo} alt='logo' />
                        <h1>
                            {'ArdidFlow'}
                        </h1>
                    </div>
                </ConditionalRender>
                <div className='w-100 flex flex-column items-start wrapper items-center'>
                    <p>{message}</p>
                </div>
            </div>
        </StyledDiv>
    );
};

export default VerifyEmail;
