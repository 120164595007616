import axios from 'axios';
import { getToken, getUserId, getCompanyId } from './auth';

let API_BASE_URL = `${process.env.REACT_APP_SERVER_URL}/api`;
let isRedirecting = false;

const instance = axios.create({
    baseURL: API_BASE_URL,
    headers: {
        'Content-Type': 'application/json',
        'Bypass-Tunnel-Reminder': 'test'
    }
});

// Add request interceptor
instance.interceptors.request.use((config) => {
    const token = getToken();
    const userId = getUserId();
    const companyId = getCompanyId();

    if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
    }

    if (userId) {
        config.headers['User-Id'] = userId;
    }

    if (companyId) {
        config.headers['Company-Id'] = companyId;
    }

    return config;
}, (error) => {
    return Promise.reject(error);
});

// Handle response or errors
const handleErrorResponse = (error) => {
    if (error.response) {
        const errorMessage = error.response.data?.message || 'An unexpected error occurred';

        if (errorMessage === 'Not authorized token expired, login again' && !isRedirecting) {
            isRedirecting = true;

            // Clear local storage and redirect to login
            localStorage.removeItem('authToken');
            localStorage.removeItem('companyId');
            localStorage.removeItem('userId');

            if (window.location.pathname !== '/login') {
                alert('Your session has expired. Please log in again.');
                window.location.href = '/login';
            }
        }

        const customError = new Error(errorMessage);
        customError.status = error.response.status;
        throw customError;
    } else if (axios.isCancel(error)) {
        console.warn('Request was canceled', error.message);
    } else {
        throw new Error('An error occurred while processing your request.');
    }
};

export const requestNoTimeOut = async (method, endpoint, data) => {
    try {
        const response = await instance({
            method,
            url: endpoint,
            data,
        });
        return response;
    } catch (error) {
        handleErrorResponse(error);
    }
};

export default requestNoTimeOut;
