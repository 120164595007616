import base64ToBlob from '../utils/base64ToBlob';

export const uploadImagesAndSendMessage = async (
    ws,
    conversationId,
    messageId,
    imagePreviews,
    callback
) => {
    const images = imagePreviews.map((image) => ({
        buffer: base64ToBlob(image?.dataUrl),
        mimetype: image?.type,
        originalName: image?.originalName,
    }));

    ws.current.emit(
        'uploadMessageImage',
        { messageId, conversationId, images }, // Send all images in one go
        (response) => {
            if (response.success) {
                callback?.(response.message);
            } else {
                console.error('Failed to finalize message:', response.message);
                callback?.(null, response.message);
            }
        }
    );
};

