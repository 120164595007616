export const selectConversation = (
    conversation,
    companyId,
    userId,
    ws,
    setSelectedConversationId,
    setSelectedConversation,
    setMessages,
    setConversationIdIsLoading
) => {
    setConversationIdIsLoading(true);
    ws.current.emit('join', { conversationId: conversation._id, companyId, userId }, (response) => {
        if (response.success) {
            setSelectedConversationId(conversation._id);
            setSelectedConversation(response.conversation);
            setMessages(response.conversation.messages);
            setConversationIdIsLoading(false);
        } else {
            setConversationIdIsLoading(false);
            console.error('Failed to join conversation:', response.message);
        }
    });
};