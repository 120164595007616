import request from '../request';

/**
 * Sends a user registration request to the API.
 * @param {String} fieldId - the id of the field
 * @returns {Promise<Object>} - A Promise that resolves to the API response data.
 * @throws {Error} - If the API request fails, an Error is thrown.
 */
export const updateField = async (fieldId, field) => {
    try {
        const response = await request('PATCH', `/fields/update-field/${fieldId}`, { field });
        let result = {}
        if (response?.data) {
            result.data = response?.data
        }
        if (response?.status) {
            result.status = response?.status
        }
        return result
    } catch (error) {
        throw new Error(`Failed to update Field: ${error.message}`);
    }
};

export default updateField;
