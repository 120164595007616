import React from 'react';
import styled from 'styled-components';
import colors from '../globalStyles.scss'
import people from '../images/people.png'
import arrow from '../images/arrow.png'
import { useNavigate } from "react-router-dom";
import ArrowDown from '../Core/ArrowDown'
import Wave from 'react-wavify'
import book from '../images/book_icon.png'
import dollar from '../images/dollar_icon.png'
import web from '../images/web_icon.png'
import reading from '../images/reading.png'
import CustomAccordion from '../Core/CustomAccordion';
import multicolorBg from '../images/multicolor_bg.png'
import guy from '../images/guy.png'
import { Parallax, ParallaxProvider } from 'react-scroll-parallax';
import { useLanguage } from '../context/LanguageContext'
import _ from 'lodash';


import CallToAction from '../Core/CallToAction'

const StyledDiv = styled.div`
    margin-bottom: 50px;
    display: flex;
    flex-direction: column;
    height: 100%;
    scroll-behavior: smooth;
    #landing {
        background-color: ${colors.black};
        .wrapper {
            display: flex;
            width: 100%;
            height: 100vh;
            .left {
                flex: 1;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                .heading_wrapper {
                    margin: 1em;
                    .heading {
                        color: ${colors.white};
                        font-size: 9em;
                    }
                    .subheading {
                        color: ${colors.white};
                        font-size: 3em;
                        display: flex;
                    }
                }
            }
            .right {
                flex: 1;
                height: 100%;
                .img-wrapper {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 100%;
                    img {
                        width: 100%;
                    }
                }
            }
        }
    }
    #tech {
        background-color: ${colors.white};
        .wrapper {
            margin-top: 50px;
            margin-bottom: 50px;
            display: flex;
            width: 100%;
            .left {
                width: 50%;
                height: 100%;
                .img-wrapper {
                    display: flex;
                    justify-content: center;
                    align-items: flex-start;
                    height: 100%;
                    img {
                        margin-top: 50px;
                        width: 100%;
                    }
                }
            }
            .right {
                width: 50%;
                height: 100%;
                .text_wrapper {
                    display: flex;
                    h3 {
                        color: ${colors.black};
                        font-size: 4em;
                        text-align: right;
                        margin: 1em;
                        font-weight: 600;
                    }
                }
            }
        }
    }

    #discover {
        background-color: ${colors.black};
        .wrapper {
            display: flex;
            justify-content: space-evenly;
            margin-top: 50px;
            margin-bottom: 50px;
            width: 100%;
            height: 100%;
            > div {
                flex: 1;
                padding: 1em;
                display: flex;
                flex-direction: column;
                align-items: center;
            }
            img {
                height: 100px;
                width: 100px;
                object-fit: cover;
            }
            h4 {
                font-size: 2em;
                margin-top: 1em;
                color: ${colors.white};
            }
            p {
                margin-top: 1em;
                font-size: 1.5em;
                color: ${colors.white};
                max-width: 50%;
            }
        }
    }

    #info {
        background-color: ${colors.white};
        .wrapper {
            display: flex;
            width: 100%;
            height: 100%;
            margin-top: 50px;
            margin-bottom: 50px;
            .left {
                width: 50%;
                height: 100%;
                display: flex;
                justify-content: center;
                .img-wrapper {
                    display: flex;
                    justify-content: center;
                    align-items: flex-start;
                    height: 100%;
                    img {
                        margin-top: 50px;
                        width: 100%;
                    }
                }
                .text_wrapper {
                    display: flex;
                    flex-direction: column;
                    align-items: baseline;
                    h3 {
                        color: ${colors.black};
                        font-size: 4em;
                        text-align: right;
                        font-weight: 600;
                    }
                }
            }
            .right {
                width: 50%;
                height: 100%;
                display: flex;
                justify-content: center;
                > div div {
                    padding: 1em;
                    width: 80%;
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    hr {
                        border-top: 2px solid ${colors.black};
                        opacity: 1;
                        margin: 15px 0;
                        z-index: 0;
                        width: 100%;
                    }
                }
                    h4 {
                        font-size: 2.5em;
                        font-weight: 600;
                    }
                    p {
                        font-size: 1.5em;
                        margin-top: 1em;
                        margin-bottom: 1em;
                        text-align: left;
                    }
                }

        }

    }

    #services {
        background-color: ${colors.black};
        .wrapper {
            display: flex;
            width: 100%;
        }
            p {
                color: ${colors.white};
            }
    }

    #testimonial {
        // background: url(${multicolorBg});
        background: linear-gradient(360deg, hsla(0, 0%, 0%, 1) 0%, hsla(198, 100%, 40%, 1) 100%);
        background-repeat: no-repeat;
        position: relative;
        .wrapper {
            margin-bottom: 50px;
            h4 {
                font-size: 2em;
                text-align: left;
                color: ${colors.white};
            }
            p {
                font-size: 1.2em;
                text-align: left;
                color: ${colors.white};
            }
        }
    }
    #terrain {
        background-color: ${colors.black};
        position: relative;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-bottom: 50px;
        .wrapper {
            padding: 1em;
            display: flex;
            width: 50%;
            h1 {
                color: ${colors.white};
                font-size: 3em;
            }
            h2 {
                color: ${colors.white};
                font-size: 2em;
                font-weight: 600;
                margin-top: 1em;
            }
            h3 {
                color: ${colors.white};
                font-size: 1.5em;
                margin-top: 1em;
                font-weight: 600;
            }
        }
        .map {
            overflow: hidden;
            canvas {
                width: 100% !important;
                height: 100% !important;
            }
        }
    }
`;


const HomeLandingContainerDesktop = ({

}) => {
    const navigate = useNavigate();
    const { text } = useLanguage();


    const handleClickScroll = (id) => {
        const element = document.getElementById(id);
        if (element) {
            element.scrollIntoView({ behavior: "smooth" });
        }
    };

    return (
        <StyledDiv className='scroll-container'>
            <ParallaxProvider
                scrollAxis={'vertical'}
            >
                <div id='landing' className='w-100'>
                    <div className='wrapper'>
                        <div className='left'>
                            <div className='heading_wrapper'>
                                <Parallax speed={10}>
                                    <h1 className='heading'>{'ArdidFlow'}</h1>
                                    <h2 className='subheading'>
                                        {text?.homeLandingContainer?.title}
                                    </h2>
                                </Parallax>
                                <ArrowDown
                                    color={colors.white}
                                    onClick={() => handleClickScroll('wave1')}
                                    className='mt-lg flex'
                                    style={{
                                        color: colors.white,
                                        fontSize: '4em',
                                    }}
                                />
                            </div>
                        </div>
                        <div className='right'>
                            <div className='img-wrapper'>
                                <Parallax speed={10}>
                                    <img src={people} alt='people' />
                                </Parallax>
                            </div>
                        </div>
                    </div>
                    <Wave
                        id='wave1'
                        className='wave'
                        fill={colors.white}
                        paused={false}
                        style={{ display: 'flex' }}
                        options={{
                            height: 50,
                            amplitude: 80,
                            speed: 0.1,
                            points: 3
                        }}
                    />
                </div>
                <div id='tech' className='w-100'>
                    <div className='wrapper'>
                        <div className='left'>
                            <div className='flex flex-column justify-center items-center'>
                                <Parallax translateX={[-10, 10]}>
                                    <div className='img-wrapper'>
                                        <img src={arrow} alt='arrow' />
                                    </div>
                                </Parallax>
                                <Parallax translateY={[20, -30]} speed={10} className='flex justify-center'>
                                    <p style={{
                                        fontSize: '1.5em',
                                        maxWidth: '50%',
                                    }}>
                                        {text?.homeLandingContainer?.tech?.description}
                                    </p>
                                </Parallax>
                                <Parallax translateY={[10, -10]} speed={8} className='flex justify-center'>
                                    <CallToAction
                                        btnWidth='auto'
                                        btnHeight={'auto'}
                                        btnStyle={{
                                            padding: '0.3em 1em',
                                            border: `2px solid ${colors.blue}`,
                                            color: colors.black,
                                            hoverBackgroundColor: colors.white,
                                            fontSize: '2.5em',
                                        }}
                                        style={{
                                            alignSelf: 'center',
                                            justifyContent: 'center',
                                            backgroundColor: colors.white,
                                            marginTop: '3em'
                                        }}
                                        text={text?.homeLandingContainer?.tech?.button}
                                        onClick={() => handleClickScroll('wave2')}
                                    />
                                </Parallax>
                            </div>

                        </div>
                        <div className='right'>
                            <div className='text_wrapper'>
                                <h3>
                                    {text?.homeLandingContainer?.tech?.title}
                                </h3>
                            </div>
                        </div>
                    </div>
                    <Wave
                        id='wave2'
                        className='wave'
                        fill={colors.black}
                        paused={false}
                        style={{ display: 'flex' }}
                        options={{
                            height: 50,
                            amplitude: 80,
                            speed: 0.1,
                            points: 3
                        }}
                    />
                </div>
                <div id='discover'>
                    <div className='flex justify-center flex-column items-center'>
                        <div className='wrapper'>
                            <div>
                                <img src={web} alt={''} />
                                <h4>
                                    {text?.homeLandingContainer?.discover?.titleOne}
                                </h4>
                                <p>
                                    {text?.homeLandingContainer?.discover?.descriptionOne}
                                </p>
                            </div>
                            <div>
                                <img src={book} alt={''} />
                                <h4>
                                    {text?.homeLandingContainer?.discover?.titleTwo}
                                </h4>
                                <p>
                                    {text?.homeLandingContainer?.discover?.descriptionTwo}
                                </p>
                            </div>
                            <div>
                                <img src={dollar} alt={''} />
                                <h4>
                                    {text?.homeLandingContainer?.discover?.titleThree}
                                </h4>
                                <p>
                                    {text?.homeLandingContainer?.discover?.descriptionThree}
                                </p>
                            </div>

                        </div>
                        <ArrowDown
                            onClick={() => handleClickScroll('wave3')}
                            className='mt-lg mb-lg flex'
                            style={{
                                color: colors.blue,
                                fontSize: '4em',
                                marginBottom: '50px'
                            }}
                        />
                    </div>
                    <Wave
                        id='wave3'
                        className='wave'
                        fill={colors.white}
                        paused={false}
                        style={{ display: 'flex' }}
                        options={{
                            height: 50,
                            amplitude: 80,
                            speed: 0.1,
                            points: 3
                        }}
                    />
                </div>
                <div id='info'>
                    <div className='wrapper'>
                        <div className='left'>
                            <div>
                                <div className='text_wrapper'>
                                    <h3>
                                        {text?.homeLandingContainer?.info?.titleTop}
                                    </h3>
                                    <h3>
                                        {text?.homeLandingContainer?.info?.titleBottom}
                                    </h3>
                                </div>
                                <div className='img-wrapper'>
                                    <Parallax speed={10}>
                                        <img src={reading} alt='reading' />
                                    </Parallax>
                                </div>
                            </div>
                        </div>
                        <div className='right'>
                            <div>
                                <div>
                                    <h4>
                                        {text?.homeLandingContainer?.info?.right?.titleOne}
                                    </h4>
                                    <p>
                                        {text?.homeLandingContainer?.info?.right?.descriptionOne}
                                    </p>
                                </div>
                                <div>
                                    <h4>
                                        {text?.homeLandingContainer?.info?.right?.titleTwo}
                                    </h4>
                                    <p>
                                        {text?.homeLandingContainer?.info?.right?.descriptionTwo}
                                    </p>
                                </div>
                                <div>
                                    <h4>
                                        {text?.homeLandingContainer?.info?.right?.titleThree}
                                    </h4>
                                    <p>
                                        {text?.homeLandingContainer?.info?.right?.descriptionThree}
                                    </p>
                                </div>
                                <Parallax translateY={[10, -10]} speed={1} className='flex justify-center'>
                                    <CallToAction
                                        btnWidth='auto'
                                        btnHeight={'auto'}
                                        btnStyle={{
                                            padding: '0.3em 1em',
                                            border: `2px solid ${colors.blue}`,
                                            color: colors.black,
                                            hoverBackgroundColor: colors.white,
                                            fontSize: '2.5em',
                                        }}
                                        style={{
                                            alignSelf: 'flex-start',
                                            justifyContent: 'flex-start',
                                            backgroundColor: colors.white,
                                            marginTop: '3em',
                                            padding: '0'
                                        }}
                                        text={text?.homeLandingContainer?.info?.right?.button}
                                        onClick={() => navigate(`/register`)}
                                    />
                                </Parallax>
                            </div>
                        </div>

                    </div>
                </div>

                <Wave
                    id='wave3'
                    className='wave'
                    fill={colors.black}
                    paused={false}
                    style={{ display: 'flex', transform: 'rotate(-360deg)' }}
                    options={{
                        height: 20,
                        amplitude: 20,
                        speed: 0.1,
                        points: 3
                    }}
                />
                <div id="services">
                    <div className='wrapper'>
                        <div className='flex-one' />
                        <div className={'flex-two'}>
                            <CustomAccordion items={[
                                { title: text?.homeLandingContainer?.services?.projectManagement?.title, body: text?.homeLandingContainer?.services?.projectManagement?.body },
                                { title: text?.homeLandingContainer?.services?.employeeManagement?.title, body: text?.homeLandingContainer?.services?.employeeManagement?.body },
                                { title: text?.homeLandingContainer?.services?.timeTracker?.title, body: text?.homeLandingContainer?.services?.timeTracker?.body },
                                { title: text?.homeLandingContainer?.services?.chat?.title, body: text?.homeLandingContainer?.services?.chat?.body },
                                { title: text?.homeLandingContainer?.services?.analytics?.title, body: text?.homeLandingContainer?.services?.analytics?.body },
                                { title: text?.homeLandingContainer?.services?.calendar?.title, body: text?.homeLandingContainer?.services?.calendar?.body },
                            ]} />
                        </div>
                        <div className={'flex-two'}>
                            <CustomAccordion items={[
                                { title: text?.homeLandingContainer?.services?.dailyLogs?.title, body: text?.homeLandingContainer?.services?.dailyLogs?.body },
                                { title: text?.homeLandingContainer?.services?.proposals?.title, body: text?.homeLandingContainer?.services?.proposals?.body },
                                { title: text?.homeLandingContainer?.services?.clients?.title, body: text?.homeLandingContainer?.services?.clients?.body },
                                { title: text?.homeLandingContainer?.services?.tasks?.title, body: text?.homeLandingContainer?.services?.tasks?.body },
                                { title: text?.homeLandingContainer?.services?.notifications?.title, body: text?.homeLandingContainer?.services?.notifications?.body },
                                { title: text?.homeLandingContainer?.services?.inventory?.title, body: text?.homeLandingContainer?.services?.inventory?.body },
                            ]} />
                        </div>
                        <div className='flex-one' />
                    </div>

                    <p className='mb-md'>
                        {text?.homeLandingContainer?.services?.title}
                    </p>
                    <p>
                        {text?.homeLandingContainer?.services?.description}
                    </p>
                </div>
                <div id='testimonial'>
                    <Wave
                        id='wave3'
                        className='wave'
                        fill={colors.black}
                        paused={false}
                        style={{ display: 'flex', transform: 'rotate(180deg)' }}
                        options={{
                            height: 50,
                            amplitude: 50,
                            speed: 0.1,
                            points: 3
                        }}
                    />
                    <div className="wrapper flex items-center">
                        <div className='flex-one' />
                        <div style={{ flex: 1, minWidth: '50%' }}>
                            <h4>
                                {text?.homeLandingContainer?.testimonial?.title}
                            </h4>
                            <p className='mt-md' >
                                {text?.homeLandingContainer?.testimonial?.description}
                            </p>
                            <p className='mt-md' style={{ fontWeight: 'bold' }}>
                                {text?.homeLandingContainer?.testimonial?.author}
                            </p>
                        </div>
                        <img src={guy} alt="guy" style={{
                            borderRadius: '50%',
                            objectFit: 'cover',
                            float: 'left',
                            shapeOutside: 'circle(50%)',
                            clipPath: 'circle(50%)',
                            width: '250px',
                            height: '250px',
                            marginLeft: '20px', // Add margin for spacing
                            marginBottom: '20px',
                            overflow: 'hidden'

                        }} />
                        <div className='flex-one' />
                    </div>

                </div>

                <div id='terrain' style={{ position: 'relative' }}>
                    <div className='wrapper flex flex-column' style={{
                        fontSize: '12px'
                    }}>
                        <div>
                            <h1 className='mt-md' style={{
                                color: colors.white,
                            }}>
                                {text?.homeLandingContainer?.terrain?.title}
                            </h1>
                            <h2 className='mt-md' style={{
                                color: colors.white,
                            }}>
                                {text?.homeLandingContainer?.terrain?.description}
                            </h2>
                            <h3 className='mt-md' style={{
                                color: colors.white,
                            }}>
                                {text?.homeLandingContainer?.terrain?.registerToday}
                            </h3>
                        </div>
                        <CallToAction
                            btnWidth='auto'
                            btnHeight={'auto'}
                            btnStyle={{
                                padding: '0.3em 1em',
                                border: `2px solid ${colors.blue}`,
                                color: colors.white,
                                hoverBackgroundColor: colors.lightblue,
                                fontSize: '2.5em',

                            }}
                            style={{
                                alignSelf: 'flex-start',
                                justifyContent: 'flex-start',
                                backgroundColor: colors.black,
                                padding: '0',
                                marginTop: '3em',
                                alignSelf: 'center',


                            }}
                            text={text?.homeLandingContainer?.terrain?.button}
                            onClick={() => navigate(`/register`)}
                        />
                    </div>

                    <iframe width="560" height="315" src="https://www.youtube.com/embed/qFGaDPSCCpY?si=mQrU4kTZZ6w92fz8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>

                </div>
            </ParallaxProvider>

        </StyledDiv >
    )
}

export default HomeLandingContainerDesktop