import requestNoTimeOut from '../requestNoTimeOut';

/**
 * Sends a product images request to the API.
 * @param {FormData} formData - The FormData object containing images and product IDs.
 * @returns {Promise<Object>} - A Promise that resolves to the API response data.
 * @throws {Error} - If the API request fails, an Error is thrown.
 */
export const createCommentImages = async (formData) => {
    try {
        const config = {
            headers: { 'content-type': 'multipart/form-data' },
        };
        const { data, status } = await requestNoTimeOut('POST', '/tasks/add-comment-image', formData, config);
        return { data, status };
    } catch (error) {
        throw new Error(`Failed to create comment images: ${error.message}`);
    }
};

export default createCommentImages;
