import React, { useState, useEffect } from 'react';
import ConfirmModal from '../Core/ConfirmModal';
import { useNavigate } from 'react-router';
import CallToAction from '../Core/CallToAction';
import ConditionalRender from '../Core/ConditionalRender';
import { useLanguage } from '../context/LanguageContext';
import FieldWithValidation from '../Core/FieldWithValidation';
import Loader from '../Core/Loader';
import FieldWrapper from '../Core/FieldWrapper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCreditCard } from '@fortawesome/free-solid-svg-icons';
import SubmitModal from '../Core/SubmitModal';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import useScreenSize from '../context/useScreenSize';
import { useTheme } from '../context/ThemeContext';
import {
    retrieveBillingInfo,
    updateCard,
    cancelSubscription
} from '../utils/calls';
import {
    composeValidators,
    isRequired
} from "../utils/validators";
import colors from '../globalStyles.scss';
import styled from 'styled-components';

const StyledCardElement = styled(CardElement)`
    border-bottom: 1px solid ${({ theme }) => theme === 'light' ? colors.black : colors.white};
    color: ${({ theme }) => theme === 'light' ? colors.black : colors.white};
    background-color: ${({ theme }) => theme === 'light' ? 'transparent' : colors.white};

  padding-bottom: 10px;
  margin-bottom: 20px;
  margin-top: 10px;

.InputElement {
        color: ${({ theme }) => theme === 'light' ? colors.black : colors.white};
    }

  &:last-child {
    margin-bottom: 0;
  }

  &.StripeElement--invalid {
    border-color: ${colors.red};
  }
    &.StripeElement--is-invalid {
        border-color: ${colors.red};
        color: ${({ theme }) => theme === 'light' ? colors.black : colors.white};
  }
`;

const StyledForm = styled.form`
    display: ${({ isLoading }) => isLoading ? 'none' : 'block'};
`;

const SubscriptionContainer = ({
    isCompanyPremium,
    userRole,
    subscription,
    user,
    fetchUserDetails,
    company,
    fetchCompanyDetails
}) => {
    const { text, language } = useLanguage();
    const { isDesktop } = useScreenSize();
    const { theme } = useTheme();
    const navigate = useNavigate();
    const stripe = useStripe();
    const elements = useElements();
    const [updatingCard, setUpdatingCard] = useState(false); // State for card update
    const [cancel, setCancel] = useState(false);
    const [cancelIsLoading, setCancelIsLoading] = useState(false);

    const [updatingCardIsLoading, setUpdatingCardIsLoading] = useState(false);
    const [billingInfo, setBillingInfo] = useState({}); // State for billing info

    const isRequiredValidator = composeValidators(
        isRequired(text?.payment?.billing?.validations?.required)
    )

    // Helper function to get status text based on subscription status
    const getSubscriptionStatusText = () => {
        switch (subscription?.status) {
            case 'active':
                return text?.subscription?.subscriptionActive || 'Your subscription is active.';
            case 'trialing':
                return text?.subscription?.subscriptionTrial || 'You are currently in a free trial.';
            case 'canceled':
                return text?.subscription?.subscriptionCanceled || 'Your subscription has been canceled.';
            case 'incomplete':
                return text?.subscription?.subscriptionIncomplete || 'Your subscription is incomplete. Please update your payment details.';
            case 'past_due':
                return text?.subscription?.subscriptionPastDue || 'Your subscription payment is overdue. Please update your payment method.';
            default:
                return text?.subscription?.subscriptionUnknown || 'Subscription status unavailable.';
        }
    };

    const fetchBillingInfo = async () => {
        try {
            const res = await retrieveBillingInfo();
            if (res.status === 200) {
                setBillingInfo(res.data);
            }
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        fetchBillingInfo();
    }, []);

    // Handle card update submission
    const handleUpdateCard = async (values) => {
        if (!stripe || !elements) return;

        const cardElement = elements.getElement(CardElement);
        if (!cardElement) return;
        // Create new payment method
        const { error, paymentMethod } = await stripe.createPaymentMethod({
            type: 'card',
            card: cardElement,
            billing_details: {
                name: values?.name,
                email: user?.email,
                address: {
                    line1: values?.addressLine1,
                    line2: values?.addressLine2,
                    city: values?.city,
                    state: values?.state,
                    postal_code: values?.zipCode,
                },
            },
        });

        if (error) {
            setUpdatingCardIsLoading(false);
            console.error('Error creating payment method:', error);
            return;
        }

        // Send the payment method ID to the backend to update the subscription's payment method
        await handleUpdatePaymentMethod(paymentMethod.id);
    };

    const handleCancelMemnbership = async () => {
        setCancelIsLoading(true)
        try {
            const res = await cancelSubscription(false, null)
            if (res.status === 200) {
                fetchUserDetails()
                fetchCompanyDetails(company?.id)
                setCancel(false);
                setCancelIsLoading(false);
                navigate("/");
                window.location.reload();

            } else {
                setCancelIsLoading(false);
                console.error('error found')
            }
        } catch (error) {
            setCancelIsLoading(false);
            console.error(error)
        }
    }

    // Handle sending the payment method to backend (this should call your API)
    const handleUpdatePaymentMethod = async (paymentMethodId) => {
        setUpdatingCardIsLoading(true)

        try {
            const res = await updateCard(paymentMethodId);
            if (res.status === 200) {
                setUpdatingCardIsLoading(false);
                setUpdatingCard(false);
                fetchBillingInfo();
            }
        } catch (error) {
            setUpdatingCardIsLoading(false);
            console.error(error);
        }
    };

    return (
        <div className='w-100 flex flex-column'>
            <div style={{
                display: 'flex',
                alignSelf: 'end',
                alignItems: 'center'
            }}>
                <h4>{text?.subscription?.title}</h4>
                <FontAwesomeIcon
                    style={{
                        marginRight: '15px',
                        marginLeft: '15px',
                        fontSize: '3rem',
                    }}
                    icon={faCreditCard}
                />
            </div>

            {/* Subscription Status */}
            <div className='mt-md'>
                <h5 className='mb-md'>{getSubscriptionStatusText()}</h5>

                <ConditionalRender renderIf={subscription?.status === 'trialing'}>
                    <p>
                        {subscription?.status === 'trialing' && subscription?.current_period_end && `${text?.subscription?.trialWillEndOn} ${new Date(subscription?.current_period_end * 1000).toLocaleDateString(language)}`}
                    </p>
                </ConditionalRender>

                <ConditionalRender renderIf={!company?.subscriptionCancelAtPeriodEnd}>
                    <p className='mb-md'>
                        {subscription?.status === 'active' && subscription?.current_period_end && `${text?.subscription?.willRenewOn} ${new Date(subscription?.current_period_end * 1000).toLocaleDateString(language)}`}
                    </p>
                </ConditionalRender>
                <ConditionalRender renderIf={company?.subscriptionCancelAtPeriodEnd && subscription.status !== 'canceled'}>
                    <p>
                        {subscription?.status === 'active' && subscription?.current_period_end && `${text?.subscription?.canceled?.willEndOn} ${new Date(subscription?.current_period_end * 1000).toLocaleDateString(language)}`}
                    </p>
                    <p>
                        {text?.subscription?.canceled?.textOne}
                    </p>
                    <p>
                        {text?.subscription?.canceled?.textTwo}
                    </p>
                </ConditionalRender>

                {/* Show "Cancel Membership" if subscription is active or trialing */}
                <ConditionalRender renderIf={isCompanyPremium && userRole === 'Admin' && (subscription?.status === 'active' || subscription?.status === 'trialing') && !company?.subscriptionCancelAtPeriodEnd}>
                    <CallToAction
                        style={{ alignSelf: 'flex-end', flex: '1', backgroundColor: colors.red }}
                        onClick={() => setCancel(!cancel)}
                        text={text?.profile?.cancel}
                    />
                </ConditionalRender>

                {/* Show "Reactivate Membership" if subscription is canceled */}
                <ConditionalRender renderIf={subscription?.status === 'canceled'}>
                    <CallToAction
                        style={{ alignSelf: 'flex-end', flex: '1', backgroundColor: colors.green }}
                        onClick={() => navigate('/membership')}
                        text={text?.subscription?.reactivateBtn || 'Reactivate Membership'}
                    />
                </ConditionalRender>

                {/* Show "Update Card Info" button and card form */}
                <ConditionalRender renderIf={subscription?.status !== 'canceled' && !company?.subscriptionCancelAtPeriodEnd}>
                    <CallToAction
                        className='mt-md'
                        onClick={() => setUpdatingCard(!updatingCard)}
                        text={text?.subscription?.updateBtn || 'Update Card Information'}
                    />
                </ConditionalRender>

                {/* Card Update Form */}
                <ConditionalRender renderIf={updatingCard}>
                    <SubmitModal
                        onClick={handleUpdateCard}
                        text={text?.subscription?.updateModal?.button}
                        disabled={updatingCardIsLoading}
                        toggle={() => setUpdatingCard(!updatingCard)}
                        isOpen={updatingCard}
                        width={isDesktop ? '50%' : '100%'}
                        height={isDesktop ? '50%' : '100%'}
                    >
                        <div className='w-100 h-100'>
                            <ConditionalRender renderIf={updatingCardIsLoading} isLoading={updatingCardIsLoading}>
                                <Loader />
                            </ConditionalRender>
                            <StyledForm isLoading={updatingCardIsLoading}>
                                <h3>
                                    {text?.payment?.billing?.title}
                                </h3>
                                <FieldWithValidation
                                    validate={isRequiredValidator}
                                    // showLightColors
                                    label={text?.payment?.billing?.name}
                                    name="name"
                                    component={"input"}
                                    initialValue={billingInfo?.billing_details?.name}
                                    type="text"
                                    block
                                />
                                <FieldWithValidation
                                    // showLightColors
                                    validate={isRequiredValidator}
                                    label={text?.payment?.billing?.address1}
                                    initialValue={billingInfo?.billing_details?.address?.line1}
                                    name="addressLine1"
                                    component={"input"}
                                    type="text"
                                    block
                                />
                                <FieldWithValidation
                                    // showLightColors
                                    initialValue={billingInfo?.billing_details?.address?.line2}
                                    label={text?.payment?.billing?.address2}
                                    name="addressLine2"
                                    component={"input"}
                                    type="text"
                                    block
                                />
                                <FieldWithValidation
                                    // showLightColors
                                    validate={isRequiredValidator}
                                    initialValue={billingInfo?.billing_details?.address?.city}
                                    label={text?.payment?.billing?.city}
                                    name="city"
                                    component={"input"}
                                    type="text"
                                    block
                                />
                                <FieldWithValidation
                                    // showLightColors
                                    validate={isRequiredValidator}
                                    initialValue={billingInfo?.billing_details?.address?.state}
                                    label={text?.payment?.billing?.state}
                                    name="state"
                                    component={"input"}
                                    type="text"
                                    block
                                />
                                <FieldWithValidation
                                    className='mb-md'
                                    // showLightColors
                                    validate={isRequiredValidator}
                                    initialValue={billingInfo?.billing_details?.address?.postal_code}
                                    label={text?.payment?.billing?.zip}
                                    name="zipCode"
                                    component={"input"}
                                    type="text"
                                    block
                                />

                                <FieldWrapper
                                    label={
                                        <div>
                                            <p>
                                                {`XXXX-XXXX-XXXX-${billingInfo?.card?.last4}`}
                                            </p>
                                            <p>
                                                {`${text?.subscription?.updateModal?.expires} ${billingInfo?.card?.exp_month}/${billingInfo?.card?.exp_year}`}
                                            </p>
                                        </div>}
                                    name={text?.subscription?.updateModal?.currentCard}
                                />

                                <StyledCardElement
                                    theme={theme}
                                    options={{
                                        iconStyle: "solid",
                                        style: {
                                            base: {
                                                fontWeight: 500,
                                                fontFamily: colors.roboto,
                                                fontSize: "1rem",
                                                fontSmoothing: "antialiased",
                                                ":-webkit-autofill": {
                                                    color: "#fce883",
                                                },
                                                "::placeholder": {
                                                    color: "#87bbfd",
                                                },
                                            },
                                            invalid: {
                                                iconColor: colors.red,
                                                color: colors.red,
                                            },
                                            valid: {
                                                color: theme === 'light' ? colors.black : colors.white
                                            }
                                        },
                                        hidePostalCode: true
                                    }
                                    }
                                />
                            </StyledForm>
                        </div>
                    </SubmitModal>
                </ConditionalRender>

                {/* Confirmation Modal for Cancelation */}
                <ConfirmModal
                    onClick={handleCancelMemnbership}
                    text={text?.profile?.modal?.button}
                    toggle={() => setCancel(!cancel)}
                    disabled={cancelIsLoading}
                    isOpen={cancel}
                    btnStyles={{
                        color: colors.red
                    }}
                    width={isDesktop ? '50%' : '100%'}
                    height={isDesktop ? '50%' : '100%'}
                >
                    <div className='w-100 h-100'>
                        <ConditionalRender renderIf={cancelIsLoading} isLoading={cancelIsLoading}>
                            <Loader />
                        </ConditionalRender>
                        <StyledForm isLoading={cancelIsLoading}>
                            <h3 className='mb-md'>
                                {text?.profile?.modal?.title}
                            </h3>
                            <p>
                                {text?.subscription?.cancelModal?.textOne}
                            </p>
                            <p>
                                {text?.subscription?.cancelModal?.textTwo}
                            </p>
                            <p>
                                {text?.subscription?.cancelModal?.textThree}
                            </p>
                        </StyledForm>
                    </div>
                </ConfirmModal>
            </div>
        </div>
    );
};

export default SubscriptionContainer;
