import React, { useState, useEffect, useRef } from 'react'
import { v4 as uuidv4 } from 'uuid'; // Install uuid if you don't have it

import queryString from "query-string";
import { useLocation, useNavigate } from "react-router-dom";
import { isRequired, composeValidators } from '../utils/validators';
import EditableField from '../Core/Inputs/EditableField';
import styled from 'styled-components'
import { Form, FormSpy } from 'react-final-form';
import { isEmpty } from 'lodash';
import ConfirmModal from '../Core/ConfirmModal';
import SubmitModal from '../Core/SubmitModal';
import FormActions from '../Core/FormActions';
import MultiFields from '../Core/MultiFields';
import NotFound from '../Home/NotFound';
import ConditionalRender from '../Core/ConditionalRender';
import FieldWithNoValidation from '../Core/FieldWithNoValidation';
import HoverPopup from '../Core/HoverPopup';
import CheckBox from '../Core/Inputs/CheckBox';
import ImageUploader from '../Core/ImageUploader';
import SelectInput from '../Core/SelectInput';
import FieldWrapper from '../Core/FieldWrapper';
import { status, priority } from '../utils/helperFunctions';
import { useLanguage } from '../context/LanguageContext'
import { useTheme } from '../context/ThemeContext'
import { useToast } from '../context/ToastContext';
import useScreenSize from '../context/useScreenSize';
import TimeEditFields from '../Core/Inputs/TimeEditForm';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faClose, faEdit, faHistory, faAdd } from '@fortawesome/free-solid-svg-icons';
import CommentWithImages from './CommentWithImages';

import moment from 'moment';
import {
    getTask,
    addCommentToTask,
    createCommentImages,
    updateTask,
    deleteComment,
    deleteTask,
    createTask,
    updateComment,
    updateHistory
} from '../utils/calls'
import base64ToBlob from '../utils/base64ToBlob';
import { btnUnderline } from '../mixins';
import colors from '../globalStyles.scss'
import classNames from 'classnames';

const StyledDiv = styled.div`

.delete {
    padding: 15px 0 5px;
    position: relative;
    display: flex;
    cursor: pointer;
    color: ${colors.red};
    font-family: ${colors.openSans};
    ${btnUnderline(colors.red)};
}
.clone {
    padding: 15px 0 5px;
    position: relative;
    display: flex;
    cursor: pointer;
    font-family: ${colors.openSans};
}
    .image-uploader {
        .body {
            margin: 0;
        }
    }
`
const StyledTodos = styled.div`
    .wrapper {
        padding: 0.5em;
        background-color: ${props => props.theme === 'dark' ? colors.backgroundSecondary : colors.lighterGray};
    }
`

const TaskDetailsContainer = ({
    user,
    projects,
    employees,
    fetchProjectTasks
}) => {
    const location = useLocation();
    const navigate = useNavigate();
    const { isDesktop, isTablet, isPhone } = useScreenSize()
    const { text, formatDate } = useLanguage();
    const { theme } = useTheme();
    const { notify } = useToast()
    const inputRefs = useRef([]);


    const [task, setTask] = useState({});
    const [taskIsLoading, setTaskIsLoading] = useState(false)
    const [disabledTaskEdit, setDisabledTaskEdit] = useState(false)
    const [isEditing, setIsEditing] = useState(false);
    const [totalTimeEdit, setTotalTimeEdit] = useState(false);
    const [historyStatus, setHistoryStatus] = useState(false);
    const queryId = queryString.parse(location.search).id;
    const [id, setId] = useState(queryId)

    const [todos, setTodos] = useState([]);

    useEffect(() => {
        setId(queryString.parse(location.search).id)
    }, [queryId])

    const [taskIsComplete, setTaskIsComplete] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false);

    useEffect(() => {
        if (task?.taskStatus === 'completed') {
            setTaskIsComplete(true)
        } else {
            setTaskIsComplete(false)
        }
    }, [task.taskStatus])

    const required = composeValidators(
        isRequired(text?.tasks?.details?.validations?.required),
    )

    const handleGetTask = async () => {
        setTaskIsLoading(true)
        try {
            const res = await getTask(id)
            if (res.status === 200) {
                setTaskIsLoading(false)
                setTask(res.data)
                setTodos(res.data?.taskTodos)
                const isAdmin = res.data?.taskProject?.adminEmployees?.some(employee => employee?.id === user?.id) || user?.roleName === 'Admin';
                setIsAdmin(isAdmin)
                setDisabledTaskEdit(res.data?.isAdmin)
            }
        } catch (error) {
            setTaskIsLoading(false)
            console.error(error)
        }
    }

    const [toggleDeleteTaskModal, setToggleDeleteTaskModal] = useState(false)

    const handleDeleteTask = async () => {
        try {
            const res = await deleteTask(id)
            const projectId = res?.data?.projectId;

            if (res.status === 200) {
                fetchProjectTasks()
                navigate(`/project/details?id=${projectId}`)
            }
        } catch (error) {
            console.error(error)
        }
    }

    useEffect(() => {
        handleGetTask()
    }, [id])

    const getTitleFromMilliseconds = (milliseconds) => {
        const totalMinutes = Math.floor(milliseconds / 60000);
        const totalHours = Math.floor(totalMinutes / 60);
        const days = Math.floor(totalHours / 24);
        const hours = totalHours % 24;
        const remainingMinutes = totalMinutes % 60;

        let daysText = days === 1 ? text?.analytics?.day : text?.analytics?.days;
        let hoursText = hours === 1 ? text?.analytics?.hour : text?.analytics?.hours;
        let minutesText = remainingMinutes === 1 ? text?.analytics?.minute : text?.analytics?.minutes;

        let result = [];

        if (days > 0) {
            result.push(`${days} ${daysText}`);
        }
        if (hours > 0) {
            result.push(`${hours} ${hoursText}`);
        }
        if (remainingMinutes > 0 || result.length === 0) { // Ensure that at least minutes are displayed if all are zero
            result.push(`${remainingMinutes} ${minutesText}`);
        }

        return result.join(` ${text?.analytics?.and} `);
    }


    const getDeadlineStatus = (date) => {
        const deadlineDate = moment(date);
        const currentDate = moment();
        const diffInDays = deadlineDate.diff(currentDate, 'days');
        let color;
        let title;

        if (diffInDays >= 5) {
            color = 'green';
            title = `${text?.tasks?.details?.dueIn} ${diffInDays} ${text?.tasks?.details?.days}`;
        } else if (diffInDays >= 3) {
            color = 'yellow';
            title = `${text?.tasks?.details?.dueIn} ${diffInDays} ${text?.tasks?.details?.days}`;
        } else if (diffInDays >= 0) {
            color = 'red';
            title = `${text?.tasks?.details?.dueIn} ${diffInDays} ${text?.tasks?.details?.days}`;
        } else {
            color = 'red';
            title = text?.tasks?.details?.pastDue;
        }

        return { color, title };
    };

    const saveTotalTime = async (values) => {
        const now = new Date();
        const nowLocal = new Date(now.getTime() - (now.getTimezoneOffset() * 60000)).toISOString().split('.')[0] + 'Z';

        let body = {};
        setTaskIsLoading(true);

        // Iterate over the keys in the values object
        for (const key in values) {
            if (values[key] && typeof values[key] === 'object' && 'value' in values[key]) {
                body[key] = values[key].value;
            } else {
                body[key] = values[key];
            }
        }

        // Transform the values into the desired format
        const logs = Object.keys(values).reduce((acc, key) => {
            const [type, id] = key.split('_');
            if (!acc[id]) {
                acc[id] = { days: 0, hours: 0, minutes: 0 };
            }
            if (type === 'days' && values[key]) {
                acc[id].days += parseInt(values[key], 10);
            } else if (type === 'hours' && values[key]) {
                acc[id].hours += parseInt(values[key], 10);
            } else if (type === 'minutes' && values[key]) {
                acc[id].minutes += parseInt(values[key], 10);
            }
            return acc;
        }, {});

        // Calculate totalTime in milliseconds
        let totalTime = 0;
        for (const id in logs) {
            const { days, hours, minutes } = logs[id];
            if (days > 0 || hours > 0 || minutes > 0) { // Only add if there are days, hours, or minutes provided
                totalTime += (days * 24 * 60 * 60 * 1000) + (hours * 60 * 60 * 1000) + (minutes * 60 * 1000); // Convert to milliseconds
            }
        }

        try {
            body.localTime = nowLocal;
            body.totalTime = totalTime;
            const res = await updateTask(id, body);
            if (res.status === 200) {
                fetchProjectTasks();
                setIsEditing(false);
                handleGetTask();
                setTaskIsLoading(false);
                setTotalTimeEdit(false);
                notify(text?.notificationsUI?.task?.updated, 'success');
            }
        } catch (error) {
            setTaskIsLoading(false);
            notify(`Error: ${error.message}`, 'error');
            console.error(error);
        }
    }


    const onSubmit = async (values) => {
        const now = new Date();
        const nowLocal = new Date(now.getTime() - (now.getTimezoneOffset() * 60000)).toISOString().split('.')[0] + 'Z';

        let body = {};
        setTaskIsLoading(true);

        // Iterate over the keys in the values object
        for (const key in values) {
            if (values[key] && typeof values[key] === 'object' && 'value' in values[key]) {
                body[key] = values[key].value;
            } else {
                body[key] = values[key];
            }
        }

        try {
            body.localTime = nowLocal;
            body.taskTodos = todos.filter(todo => todo.text.trim() !== '') || [];
            const res = await updateTask(id, body);
            if (res.status === 200) {
                fetchProjectTasks();
                setIsEditing(false);
                handleGetTask();
                setTaskIsLoading(false);
                notify(text?.notificationsUI?.task?.updated, 'success');
                if (body.taskStatus) {
                    await updateHistory(body.taskProject, `${text?.projects?.history?.taskTitle} ${body?.taskTitle} ${text?.projects?.history?.statusChangedTo} ${status(text).find(s => s.value === body.taskStatus)?.label}`);
                }
            }
        } catch (error) {
            setTaskIsLoading(false);
            notify(`Error: ${error.message}`, 'error');
            console.error(error);
        }
    }

    const [imagePreviews, setImagePreviews] = useState([]);
    const handleImageChange = (imageData) => {
        setImagePreviews(imageData);
    };
    const handleCommentSubmit = async (values, form) => {
        try {
            const res = await addCommentToTask({ id, comment: values?.comment })
            if (res.status === 200) {
                const commentId = res.data?.comment?._id;
                const projectId = res.data?.projectId;

                const imageUploadPromises = imagePreviews.map(async (image) => {
                    const imageBlob = base64ToBlob(image?.dataUrl); // Convert base64 data to a Blob
                    const formData = new FormData();
                    formData.append("image", imageBlob, image?.originalName); // Add the Blob to FormData, along with a file name
                    formData.append("taskId", id);
                    formData.append("commentId", commentId);
                    formData.append('projectId', projectId);

                    console.log("Uploading image...");

                    // Send the image to a separate endpoint
                    return createCommentImages(formData)
                        .then((imageRes) => {
                            if (imageRes?.status === 200) {
                                console.log("Image uploaded");
                            } else {
                                throw new Error("Image upload failed");
                            }
                        });

                }) || []

                await Promise.all(imageUploadPromises)
                form.reset()
                handleGetTask()
            }
        } catch (error) {
            console.error(error)
        }
    }

    const [toggleDeleteModal, setToggleDeleteModal] = useState(false);
    const [selectedCommentId, setSelectedCommentId] = useState(null);

    const handleToggleDelete = (commentId) => {
        setSelectedCommentId(commentId);
        setToggleDeleteModal(!toggleDeleteModal);
    };

    const handleDeleteComment = async () => {
        try {
            const res = await deleteComment({ id, commentId: selectedCommentId })
            if (res.status === 200) {
                handleGetTask()
                setToggleDeleteModal(false)
            }
        } catch (error) {
            console.error(error)
        }
    }

    const [toggleCloneModal, setToggleCloneModal] = useState(false)

    const handleCloneProduct = async () => {
        const {
            _id,
            companyId,
            __v,
            createdAt,
            updatedAt,
            taskCreatedBy,
            taskComments,
            taskProject,
            taskAssignedTo,
            ...clonedTask
        } = task
        clonedTask.taskStatus = { value: task.taskStatus }
        clonedTask.taskPriority = { value: task.taskPriority }
        clonedTask.taskAssignedTo = { value: task.taskAssignedTo?._id }
        clonedTask.taskProject = { value: task.taskProject?._id }
        try {
            const res = await createTask(clonedTask)
            if (res.status === 200) {

                // Update state and navigate to the new task's details page
                setToggleCloneModal(!toggleCloneModal);
                setImagePreviews([]);
                navigate(`/tasks/details?id=${res.data._id}`);

                setId(res.data._id);
            }
        } catch (error) {
            console.error(error)
        }
    }

    const [initialProjectValue, setInitialProjectValue] = useState({
        label: task?.taskProject?.projectName,
        value: task?.taskProject?._id,
    });
    const [initialStatusValue, setInitialStatusValue] = useState(status(text).find(s => s.value === task?.taskStatus));
    const [initialPriorityValue, setInitialPriorityValue] = useState(priority(text).find(s => s.value === task?.taskStatus)
    );
    const assignedTo = !task?.taskAssignedTo ? {
        label: text?.tasks?.details?.unassigned,
        value: '000000000000000000000000'
    } : {
        label: `${task?.taskAssignedTo?.firstName} ${task?.taskAssignedTo?.lastName}`,
        value: task?.taskAssignedTo?._id
    }

    const [initialIAssignedToValue, setInitialAssignedToValue] = useState(assignedTo);
    useEffect(() => {
        setInitialProjectValue({
            label: task?.taskProject?.projectName,
            value: task?.taskProject?._id,
        });
        setInitialAssignedToValue(assignedTo);
        setInitialStatusValue(status(text).find(s => s.value === task?.taskStatus))
        setInitialPriorityValue(priority(text).find(s => s.value === task?.taskPriority))
    }, [task]);

    const adminProjects = projects?.filter(project => {
        // Check if the user is an admin
        return project.adminEmployees?.some(employee => employee?.id === user?.id) || user?.roleName === 'Admin';
    });


    const handleSave = (newValue, commentId) => {
        try {
            const res = updateComment({ id, commentId, commentText: newValue })
            if (res.status === 200) {
                handleGetTask()
            }
        } catch (error) {
            console.error(error)
        }
    };

    const handleCancel = () => {
        console.log('Edit canceled');
    };

    const [error, setError] = useState(null);


    const addTodo = () => {
        // Check if the last todo is not empty
        if (todos.length > 0 && todos[todos.length - 1]?.text.trim() === '') {
            setError(text?.tasks?.create?.error);
            return;
        }

        setError(''); // Reset error
        setTodos((prevTodos) => [...prevTodos, { id: uuidv4(), text: '', completed: false }]);
        setIsEditing(true);

        // Focus on the new input field after adding a new todo
        setTimeout(() => {
            const nextField = inputRefs.current[todos.length];
            if (nextField) {
                nextField.focus();
            }
        }, 0); // Delay to ensure the new input field is rendered
    };

    const updateTodo = (id, key, value) => {
        const updatedTodos = todos.map((todo) =>
            todo.id === id ? { ...todo, [key]: value } : todo
        );
        setTodos(updatedTodos);
        setIsEditing(true);

    };

    const removeTodo = (id) => {
        const updatedTodos = todos.filter((todo) => todo.id !== id);
        setTodos(updatedTodos);
        setIsEditing(true);

        // Update the inputRefs array by removing the reference of the deleted todo
        inputRefs.current = inputRefs.current.filter((_, i) => todos[i]?.id !== id);
    };

    return !taskIsLoading && isEmpty(task) ? <NotFound isCompanyPremium /> :
        (
            <StyledDiv>
                <ConditionalRender
                    count={2}
                    customStyled={{
                        height: '100vh',
                        display: 'flex'
                    }}
                    renderIf={true}
                    isLoading={taskIsLoading}
                >
                    <div className='mb-lg tx-left'>
                        <h4>
                            {text?.projects?.details?.details}
                        </h4>
                        <hr />
                        {task?.taskStatus === 'completed' && (
                            <div className='flex items-center'>
                                <FontAwesomeIcon
                                    style={{
                                        color: theme === 'dark' ? colors.secondary : colors.green
                                    }} icon={faCheck}
                                />
                                <p className='ml-md'>
                                    {moment(task?.dateCompleted).format('MM-DD-YYYY')}
                                </p>
                            </div>
                        )}
                    </div>

                    {/* admin */}
                    <ConditionalRender renderIf={disabledTaskEdit}>
                        <Form
                            onSubmit={(values) => onSubmit(values)}
                            render={({ handleSubmit, form }) => (
                                <form onSubmit={handleSubmit}>
                                    <ConditionalRender renderIf={task?.taskStatus === 'in-progress'}>
                                        <p className='flex mb-sm'>
                                            {text?.tasks?.details?.timeInfo}
                                        </p>
                                    </ConditionalRender>
                                    <div className='flex items-center'>
                                        <FieldWrapper
                                            name={text?.logs?.totalTime}
                                            label={getTitleFromMilliseconds(task?.totalTime)}
                                            callToAction={task?.taskStatus !== 'in-progress' && <FontAwesomeIcon
                                                onClick={() => setTotalTimeEdit(!totalTimeEdit)}
                                                style={{
                                                    color: theme === 'dark' ? colors.secondary : colors.blue,
                                                    cursor: 'pointer'
                                                }} icon={faEdit}
                                            />}
                                        />
                                    </div>
                                    <SelectInput
                                        className='field-style'
                                        isValidNewOption={() => false}
                                        isClearable={false}
                                        isSearchable={false}
                                        name='taskStatus'
                                        label={text?.tasks?.details?.status}
                                        options={status(text)}
                                        setIsEditing={setIsEditing}
                                        initialValue={initialStatusValue}
                                        callToAction={<FontAwesomeIcon
                                            onClick={() => setHistoryStatus(!historyStatus)}
                                            style={{
                                                color: theme === 'dark' ? colors.secondary : colors.blue,
                                                cursor: 'pointer'
                                            }} icon={faHistory}
                                        />}
                                    />
                                    <FormSpy subscription={{ values: true }}>
                                        {({ form }) => (
                                            <>
                                                <SelectInput
                                                    validate={required}
                                                    name={'taskProject'}
                                                    label={text?.tasks?.details?.project}
                                                    isValidNewOption={() => false}
                                                    disabled={taskIsComplete}
                                                    isClearable={false}
                                                    setIsEditing={setIsEditing}
                                                    isSearchable
                                                    controlWidth='100%'
                                                    options={
                                                        adminProjects?.filter((x) => !x?.isCompleted)?.map((x) => {
                                                            return {
                                                                label: x.projectName,
                                                                value: x._id
                                                            };
                                                        })}
                                                    initialValue={initialProjectValue}
                                                    style={{ marginBottom: '1em' }}
                                                    onChange={(selectedOption) => {
                                                        form.change('taskAssignedTo', null);
                                                    }}
                                                />
                                                <FormSpy subscription={{ values: true }}>
                                                    {({ values }) => {
                                                        const selectedProject = projects?.find((x) => x?._id === values?.taskProject?.value);
                                                        const assignedEmployees = employees?.filter((employee) =>
                                                            selectedProject?.assignedEmployees?.some((assignedEmployee) => assignedEmployee.id === employee.id)
                                                        );

                                                        return (
                                                            <ConditionalRender renderIf={!isEmpty(selectedProject)}>
                                                                <SelectInput
                                                                    className='field-style'
                                                                    menuPlacement='top'
                                                                    name='taskAssignedTo'
                                                                    isClearable={false}
                                                                    label={text?.tasks?.details?.assignedTo}
                                                                    disabled={taskIsComplete}
                                                                    validate={required}
                                                                    initialValue={initialIAssignedToValue}
                                                                    setIsEditing={setIsEditing}
                                                                    isSearchable
                                                                    isValidNewOption={() => false}
                                                                    controlWidth='100%'
                                                                    options={
                                                                        employees && [
                                                                            { label: text?.tasks?.details?.unassigned, value: '000000000000000000000000' },
                                                                            ...assignedEmployees?.map((employee) => ({
                                                                                label: `${employee?.firstName} ${employee?.lastName}`,
                                                                                value: employee?.id
                                                                            }))
                                                                        ]}
                                                                />
                                                            </ConditionalRender>
                                                        );
                                                    }}
                                                </FormSpy>

                                            </>
                                        )}
                                    </FormSpy>



                                    <MultiFields
                                        className='field-style'
                                        name="taskTitle"
                                        validate={required}
                                        component="input"
                                        type="text"
                                        label={text?.tasks?.details?.title}
                                        block
                                        disabled={taskIsComplete}
                                        setIsEditing={setIsEditing}
                                        initialValue={task?.taskTitle}
                                    />
                                    <MultiFields
                                        className='field-style'
                                        name="taskDescription"
                                        validate={required}
                                        component="textarea"
                                        type="text"
                                        label={text?.tasks?.details?.description}
                                        block
                                        disabled={taskIsComplete}
                                        setIsEditing={setIsEditing}
                                        initialValue={task?.taskDescription}
                                    />
                                    <SelectInput
                                        className='field-style'
                                        isClearable={false}
                                        disabled={taskIsComplete}
                                        name='taskPriority'
                                        label={text?.tasks?.details?.priority}
                                        validate={required}
                                        setIsEditing={setIsEditing}
                                        options={priority(text)}
                                        initialValue={initialPriorityValue}
                                    />
                                    <MultiFields
                                        className='field-style'
                                        name="taskDeadline"
                                        component="date"
                                        type="text"
                                        label={text?.tasks?.details?.deadline}
                                        block
                                        disabled={taskIsComplete}
                                        setIsEditing={setIsEditing}
                                        initialValue={task?.taskDeadline}
                                    />
                                    <div className='flex items-center mt-md mb-md'>
                                        <p
                                            style={{
                                                width: '10px',
                                                height: '10px',
                                                borderRadius: '50%',
                                                backgroundColor: getDeadlineStatus(task?.taskDeadline).color,
                                                marginRight: '10px'
                                            }}
                                        />
                                        <p>
                                            {getDeadlineStatus(task?.taskDeadline).title}
                                        </p>
                                    </div>
                                    <hr />
                                    <StyledTodos theme={theme} style={{
                                        width: '100%',
                                        display: isDesktop ? 'flex' : 'block',
                                        marginBottom: '1em'
                                    }} >
                                        <div className={classNames({
                                            'wrapper': true,
                                            'mr-md': isDesktop,
                                            'mb-md': !isDesktop
                                        })} style={{
                                            width: isDesktop ? '50%' : '100%',
                                        }}>
                                            <p
                                                className='mb-md flex'
                                                style={{
                                                    fontFamily: colors.openSans
                                                }}
                                            >
                                                {text?.tasks?.create?.todos}
                                            </p>
                                            {todos.filter((x => !x.completed)).map((todo, index) => (
                                                <div key={index}
                                                    style={{
                                                        alignItems: 'center',
                                                        display: 'flex',
                                                        marginBottom: '10px',
                                                        padding: '10px',
                                                    }}>
                                                    <FontAwesomeIcon
                                                        style={{
                                                            cursor: 'pointer',
                                                            color: colors.red,
                                                            marginRight: '1em'
                                                        }}
                                                        onClick={() => removeTodo(todo.id)}
                                                        icon={faClose}
                                                    />
                                                    <ConditionalRender renderIf={todo.text.trim() !== ''}>
                                                        <CheckBox
                                                            name={`todos[${index}].completed`}
                                                            component="input"
                                                            type="checkbox"
                                                            checked={todo.completed}
                                                            onChange={(e) => updateTodo(todo.id, 'completed', e.target.checked)}
                                                            className='mr-md'
                                                        />
                                                    </ConditionalRender>
                                                    <FieldWithNoValidation
                                                        name={`todos[${index}].text`}
                                                        component="input"
                                                        type="text"
                                                        ref={(el) => (inputRefs.current[index] = el)}
                                                        value={todo.text}
                                                        placeholder={text?.tasks?.create?.placeholder}
                                                        onChange={(e) => updateTodo(todo.id, 'text', e.target.value)}
                                                        onKeyDown={(e) => {
                                                            if (e.key === 'Enter') {
                                                                e.preventDefault();
                                                                addTodo();
                                                            }
                                                        }}
                                                    />
                                                </div>
                                            ))
                                            }
                                            {error && <p style={{ color: colors.red }}>{error}</p>}
                                            {
                                                (todos.length === 0 || todos.every(todo => todo.completed)) && (
                                                    <p className='mb-md'>
                                                        {text?.tasks?.create?.noTodos}
                                                    </p>
                                                )

                                            }

                                            <FontAwesomeIcon
                                                style={{
                                                    cursor: 'pointer',
                                                    marginBottom: '1em',
                                                }}
                                                onClick={addTodo}
                                                icon={faAdd}
                                            />
                                        </div>
                                        {/* completed */}
                                        <div className='wrapper' style={{
                                            width: isDesktop ? '50%' : '100%',
                                        }}>
                                            <p className='mb-md flex'
                                                style={{
                                                    fontFamily: colors.openSans
                                                }}>
                                                {text?.tasks?.create?.completed}
                                            </p>
                                            <ConditionalRender renderIf={todos.filter((x => x.completed)).length === 0}>
                                                <p style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                }}>
                                                    {text?.tasks?.create?.noCompleted}
                                                </p>
                                            </ConditionalRender>
                                            {
                                                todos.filter((x => x.completed)).map((todo, index) => (
                                                    <div key={index}
                                                        style={{
                                                            alignItems: 'center',
                                                            display: 'flex',
                                                            marginBottom: '10px'
                                                        }}>
                                                        <CheckBox
                                                            className='mr-md'
                                                            name={`todos[${index}].completed`}
                                                            component="input"
                                                            type="checkbox"
                                                            checked={todo.completed}
                                                            onChange={(e) => updateTodo(todo.id, 'completed', e.target.checked)}
                                                        />
                                                        <p style={{
                                                            textDecoration: 'line-through',
                                                        }}>
                                                            {todo.text}
                                                        </p>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </StyledTodos>
                                    {
                                        (isEditing) && (
                                            <FormActions
                                                form={form}
                                                floating
                                                type={'submit'}
                                                submitText={text?.projects?.details?.saveButton}
                                                btnStyle={{
                                                    color: colors.blue
                                                }}
                                            />
                                        )
                                    }
                                </form>
                            )}
                        />

                    </ConditionalRender>

                    {/* Non admin */}
                    <ConditionalRender renderIf={!disabledTaskEdit}>
                        <Form
                            onSubmit={(values) => onSubmit(values)}
                            render={({ handleSubmit }) => (
                                <form onSubmit={handleSubmit}>
                                    <ConditionalRender renderIf={task?.taskStatus === 'in-progress'}>
                                        <p className='flex mb-sm'>
                                            {text?.tasks?.details?.timeInfo}
                                        </p>
                                    </ConditionalRender>
                                    <div className='flex items-center'>
                                        <FieldWrapper
                                            name={text?.logs?.totalTime}
                                            label={getTitleFromMilliseconds(task?.totalTime)}
                                            callToAction={task?.taskStatus !== 'in-progress' && <FontAwesomeIcon
                                                onClick={() => setTotalTimeEdit(!totalTimeEdit)}
                                                style={{
                                                    color: theme === 'dark' ? colors.secondary : colors.blue,
                                                    cursor: 'pointer'
                                                }} icon={faEdit}
                                            />}
                                        />
                                    </div>
                                    <div className='flex items-center'>
                                        <SelectInput
                                            className='field-style'
                                            isValidNewOption={() => false}
                                            name='taskStatus'
                                            label={text?.tasks?.details?.status}
                                            initialValue={initialStatusValue}
                                            isClearable={false}
                                            options={status(text)}
                                            setIsEditing={setIsEditing}
                                            callToAction={<FontAwesomeIcon
                                                onClick={() => setHistoryStatus(!historyStatus)}
                                                style={{
                                                    color: theme === 'dark' ? colors.secondary : colors.blue,
                                                    cursor: 'pointer'
                                                }} icon={faHistory}
                                            />}
                                        />
                                        {task?.taskStatus === 'completed' && (
                                            <FontAwesomeIcon
                                                style={{
                                                    color: theme === 'dark' ? colors.secondary : colors.green,
                                                    marginLeft: '1em'
                                                }} icon={faCheck}
                                            />
                                        )}
                                    </div>
                                    {
                                        (isEditing) && (
                                            <FormActions
                                                floating
                                                type={'submit'}
                                                submitText={text?.projects?.details?.saveButton}
                                                btnStyle={{
                                                    color: colors.blue
                                                }}
                                            />
                                        )
                                    }
                                </form>
                            )}
                        />

                        <FieldWrapper
                            name={text?.tasks?.details?.project}
                            label={task?.taskProject?.projectName}
                        />
                        <FieldWrapper
                            name={text?.tasks?.details?.title}
                            label={task?.taskTitle}
                        />
                        <FieldWrapper
                            name={text?.tasks?.details?.description}
                            label={task?.taskDescription}
                        />

                        <FieldWrapper
                            name={text?.tasks?.details?.priority}
                            label={priority(text).find(p => p.value === task?.taskPriority)?.label}
                        />
                        <FieldWrapper
                            name={text?.tasks?.details?.deadline}
                            label={formatDate(task?.taskDeadline, 'MM-DD-YYYY')}
                        />
                        <div className='flex items-center mb-md'>
                            <p
                                style={{
                                    width: '10px',
                                    height: '10px',
                                    borderRadius: '50%',
                                    backgroundColor: getDeadlineStatus(task?.taskDeadline).color,
                                    marginRight: '10px'
                                }}
                            />
                            <p>
                                {getDeadlineStatus(task?.taskDeadline).title}
                            </p>
                        </div>
                        <hr />

                        <div style={{
                            width: '100%',
                            display: isDesktop ? 'flex' : 'block',
                            marginBottom: '1em'
                        }}>
                            <div style={{
                                width: isDesktop ? '50%' : '100%',
                            }}>
                                <p
                                    className='mb-md flex'
                                    style={{
                                        fontFamily: colors.openSans
                                    }}
                                >
                                    {text?.tasks?.create?.todos}
                                </p>
                                {todos.filter((x => !x.completed)).map((todo, index) => (
                                    <div key={index}
                                        style={{
                                            alignItems: 'center',
                                            display: 'flex',
                                            marginBottom: '10px',
                                            padding: '10px',
                                        }}>
                                        <CheckBox
                                            name={`todos[${index}].completed`}
                                            component="input"
                                            type="checkbox"
                                            checked={todo.completed}
                                            onChange={(e) => updateTodo(todo.id, 'completed', e.target.checked)}
                                            className='mr-md'
                                        />
                                        <p>
                                            {todo.text}
                                        </p>
                                    </div>
                                ))
                                }
                                {
                                    (todos.length === 0 || todos.every(todo => todo.completed)) && (
                                        <p className='mb-md'>
                                            {text?.tasks?.create?.noTodos}
                                        </p>
                                    )

                                }
                            </div>
                            {/* completed */}
                            <div style={{
                                width: isDesktop ? '50%' : '100%',
                                padding: '0.5em',
                                backgroundColor: theme === 'dark' ? colors.backgroundSecondary : colors.lightGray,
                            }}>
                                <ConditionalRender renderIf={todos.filter((x => x.completed)).length > 0}>
                                    <p className='mb-md flex'
                                        style={{
                                            fontFamily: colors.openSans
                                        }}>
                                        {text?.tasks?.create?.completed}
                                    </p>
                                </ConditionalRender>
                                {
                                    todos.filter((x => x.completed)).map((todo, index) => (
                                        <div key={index}
                                            style={{
                                                alignItems: 'center',
                                                display: 'flex',
                                                marginBottom: '10px'
                                            }}>
                                            <CheckBox
                                                className='mr-md'
                                                name={`todos[${index}].completed`}
                                                component="input"
                                                type="checkbox"
                                                checked={todo.completed}
                                                onChange={(e) => updateTodo(todo.id, 'completed', e.target.checked)}
                                            />
                                            <p style={{
                                                textDecoration: 'line-through',
                                            }}>
                                                {todo.text}
                                            </p>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </ConditionalRender>

                    <ImageUploader
                        onImageUpload={handleImageChange}
                        maxFiles={10}
                        customMessage={text?.tasks?.details?.uploadImages}
                        title={text?.tasks?.details?.comments}
                    >
                        <ConditionalRender renderIf={!taskIsComplete}>
                            <Form
                                onSubmit={(values, form) => handleCommentSubmit(values, form)}
                                render={({ handleSubmit, form }) => (
                                    <form onSubmit={handleSubmit}>
                                        <MultiFields
                                            className='field-style'
                                            name="comment"
                                            label={text?.tasks?.details?.saySomething}
                                            type="text"
                                            block
                                            validate={required}
                                        />
                                        <FormActions
                                            form={form}
                                            type={'submit'}
                                            submitText={text?.tasks?.details?.button}
                                        />
                                    </form>
                                )}
                            />
                        </ConditionalRender>
                    </ImageUploader>

                    <SubmitModal
                        onClick={saveTotalTime}
                        toggle={() => setTotalTimeEdit(!totalTimeEdit)}
                        text={'Save'}
                        isOpen={totalTimeEdit}
                        width={isDesktop ? '50%' : '100%'}
                        height={isDesktop ? '50%' : '100%'}
                    >
                        <TimeEditFields
                            id={task?._id}
                            initialTimeInMilliseconds={task?.totalTime}
                            label={text?.logs?.totalTime}
                            showDays
                        />
                    </SubmitModal>

                    <ConfirmModal
                        isOpen={historyStatus}
                        toggle={() => setHistoryStatus(!historyStatus)}
                        width={isDesktop ? '50%' : '100%'}
                        height={isDesktop ? '50%' : '100%'}
                    >
                        <div className='flex flex-column w-100'>
                            <h4 className='flex mb-md'>
                                {text?.tasks?.details?.statusHistory}
                            </h4>
                            {
                                task?.statusHistory
                                    ?.sort((a, b) => moment(b.statusSetAt).valueOf() - moment(a.statusSetAt).valueOf()) // Sort by date using moment
                                    .map((value, index) => {
                                        return (
                                            <>
                                                <div key={index} className='flex justify-space-between pr-md'>
                                                    <p className='b mr-md'>
                                                        {status(text).find(s => s.value === value?.status)?.label}
                                                    </p>
                                                    <p>
                                                        {moment.utc(value?.statusSetAt).format('MM-DD-YYYY')}
                                                    </p>
                                                </div>
                                                <hr />
                                            </>
                                        )
                                    })
                            }
                        </div>
                    </ConfirmModal>



                    <hr />
                    <ConditionalRender renderIf={!isEmpty(task?.taskComments)}>
                        {task?.taskComments?.map((comment, index) => (
                            <div
                                key={index}
                                style={{
                                    padding: '1em',
                                    borderRadius: '5px',
                                    marginBottom: '1.5em',
                                    position: 'relative',
                                    background: theme === 'dark' ? colors.backgroundSecondary : colors.darkGray
                                }}
                            >
                                <div className=' w-100 flex items-center justify-space-between'>
                                    <div className='flex items-center'>
                                        <img
                                            src={comment?.commentedBy?.profilePhoto || 'https://cdn.pixabay.com/photo/2014/04/02/10/25/man-303792_960_720.png'}
                                            alt='profile'
                                            style={{
                                                height: '50px',
                                                width: '50px',
                                                objectFit: 'cover',
                                                borderRadius: '1000%',
                                                marginRight: '1em'
                                            }}
                                        />

                                        <EditableField
                                            value={comment?.commentText}
                                            onSave={(value) => handleSave(value, comment?._id)}
                                            onCancel={handleCancel}
                                            placeholder="Enter your name"
                                            allowEdit={user?.id === comment?.commentedBy?.id}
                                        />
                                    </div>
                                    <div className='ml-md'>
                                        <p>
                                            {formatDate(comment?.commentedAt, 'MM-DD-YYYY')}
                                        </p>
                                        <p>
                                            {`${comment?.commentedBy?.firstName} ${comment?.commentedBy?.lastName}`}
                                        </p>
                                    </div>
                                </div>

                                {/* Delete Comment */}
                                <ConditionalRender renderIf={user?.id === comment?.commentedBy?.id}>
                                    <HoverPopup
                                        onClick={() => handleToggleDelete(comment._id)}
                                        placement={'top'}
                                        id={`delete-comment-${comment?._id}`}
                                        text={text?.tasks?.details?.delete}
                                        wrapperStyle={{
                                            position: 'absolute',
                                            top: '-15px',
                                            right: '-10px',
                                            background: colors.red,
                                            padding: '0.5em',
                                            borderRadius: '100%',
                                            cursor: 'pointer',
                                            width: '25px',
                                            height: '25px',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            border: 'none'

                                        }}
                                    >
                                        <FontAwesomeIcon
                                            icon={faClose}
                                            style={{
                                                color: colors.white
                                            }}
                                        />
                                    </HoverPopup>
                                    {toggleDeleteModal && selectedCommentId === comment._id && (
                                        <ConfirmModal
                                            onClick={() => handleDeleteComment(comment._id)}
                                            text={text?.tasks?.details?.deleteModal?.button}
                                            toggle={() => handleToggleDelete(null)}
                                            isOpen={toggleDeleteModal}
                                            btnStyles={{
                                                color: colors.red
                                            }}
                                            width={isDesktop ? '50%' : '100%'}
                                            height={isDesktop ? '50%' : '100%'}
                                        >
                                            {text?.tasks?.details?.deleteModal?.title}
                                        </ConfirmModal>
                                    )}
                                </ConditionalRender>

                                <CommentWithImages
                                    key={comment._id}
                                    comment={comment}
                                    disabled={user?.id === comment?.commentedBy?.id}
                                    projectId={task?.taskProject?._id}
                                    taskId={task?._id}
                                    handleGetTask={handleGetTask}
                                />
                            </div>
                        ))}
                    </ConditionalRender>
                    <ConditionalRender renderIf={isAdmin}>
                        <div className='flex' style={{
                            justifyContent: 'space-between'
                        }}>
                            <div style={{ display: 'flex' }}>
                                <p className='clone' onClick={() => setToggleCloneModal(!toggleCloneModal)}>
                                    {text?.inventory?.details?.clone}
                                </p>
                            </div>
                        </div>

                        <ConfirmModal
                            onClick={handleDeleteTask}
                            text={text?.inventory?.details?.modal?.button}
                            toggle={() => setToggleDeleteTaskModal(!toggleDeleteTaskModal)}
                            isOpen={toggleDeleteTaskModal}
                            btnStyles={{
                                color: colors.red
                            }}
                            width={isDesktop ? '50%' : '100%'}
                            height={isDesktop ? '50%' : '100%'}
                        >
                            {text?.inventory?.details?.modal?.title}
                        </ConfirmModal>
                        <ConfirmModal
                            onClick={handleCloneProduct}
                            text={text?.inventory?.details?.modal?.button}
                            toggle={() => setToggleCloneModal(!toggleCloneModal)}
                            isOpen={toggleCloneModal}
                            width={isDesktop ? '50%' : '100%'}
                            height={isDesktop ? '50%' : '100%'}
                        >
                            {text?.inventory?.details?.modal?.title2}
                        </ConfirmModal>
                    </ConditionalRender>
                </ConditionalRender>
            </StyledDiv >
        )
}

export default TaskDetailsContainer