import React, { useRef, useEffect, useState } from 'react';
import { Form } from 'react-final-form';
import FieldWithValidation from '../Core/FieldWithValidation';
import CallToAction from '../Core/CallToAction';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose, faInfo, faArrowAltCircleLeft } from '@fortawesome/free-solid-svg-icons';
import { useTheme } from '../context/ThemeContext'
import { useLanguage } from '../context/LanguageContext';
import logo from '../images/ardidflow_logo_small.png';
import ConditionalRender from '../Core/ConditionalRender';
import { isEmpty } from 'lodash'
import styled from 'styled-components'
import colors from '../globalStyles.scss'
import ThinkingDots from '../Core/ThinkingDots';
import HoverPopup from '../Core/HoverPopup';
import useScreenSize from '../context/useScreenSize';
import Info from '../Core/Info';
import parse from 'html-react-parser';

const StyledChat = styled.div`
label {
    color: ${colors.black} !important;
}
.form-group input {
    background: ${colors.white};
    color: ${colors.black};
}
caret-color: ${colors.black};
.toggle-chat {
    cursor: pointer;
}
svg {
    color: ${colors.white};
}
button {
    color: ${colors.white};
    background: ${colors.black};
}

&.desktop {
    .chat {
        margin: 50px 30px;
        position: absolute;
        .toggle-close {
            position: absolute;
            top: -20px;
            right: -0.5em;
            cursor: pointer;
            font-size: 1em;
            background: ${colors.red};
            padding: 15px;
            border-radius: 100%;
            width: 30px;
            height: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            svg {
                color: ${colors.white};
            }
        }
        .chat-conversation {
            height: 200px;
        }
    }
}
&.tablet, &.phone {
    .chat {
        width: 100%;
        height: 95%;
        top: 5%;
        justify-content: space-between;
        position: fixed;
        box-shadow: 0px 0px 10px 0px ${(props) => props.theme === 'dark' ? 'rgba(255,255,255,0.75)' : 'rgba(0,0,0,0.75)'};        
        .chat-conversation {
            height: 100%;
        }
        .toggle-close {
            position: absolute;
            bottom: 0px;
            right: 0px;
            cursor: pointer;
            font-size: 1rem;
            background: ${colors.red};
            padding: 15px;
            width: 30px;
            height: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            svg {
                color: ${colors.white};
            }
    }
    }
}

.chat {
    .toggle-advance {
        display: flex;
        align-items: center;
        justify-content: end;
        .Mui-checked+.MuiSwitch-track{
            background-color: ${colors.backgroundSecondary} !important;
        }
        .MuiSwitch-track {
            background-color: ${colors.backgroundSecondary} !important;
        }
        .MuiSwitch-thumb {
            background: ${colors.black} !important;
        }
    }
    display: flex;
    flex-direction: column;
    position: absolute;
    right: 0;
    bottom: 0;
    padding: 10px;
    z-index: 1000;
    background: ${(props) => !props.isAdvance ? colors.darkGray : colors.lightGray};
    box-shadow: ${(props => !props.isAdvance ? '0px 2px 10px ' + colors.lightBlue : '0px 2px 10px ' + colors.darkGray)};
    transform: translateY(${props => (props.show ? "0px" : "-50%")});
    width: ${props => (props.show ? "500px" : "0")};
    opacity: ${props => (props.show ? "1" : "0.4")};
    visibility: ${props => (props.show ? "visible" : "hidden")};
    transition: transform 0.3s ease-in-out, opacity 0.2s ease 0.2s;
    .chat-conversation {
        overflow: hidden;
        overflow-y: scroll;
        display: flex;
        flex-direction: column;
        p {
            color: ${colors.black};
        }
        .conversation-wrapper {
            display: flex;
            justify-content: space-between;
            margin: 15px 0;
            margin-bottom: 0;
            padding: 15px;
            .person {
                font-family: ${colors.roboto};
                flex: 1;
            }
            .content {
                font-family: ${colors.openSans};
                flex: 1;
                word-break: break-word;
             }
        }
        .conversation-wrapper.user {
            width: 50%;
            background-color: ${colors.backgroundSecondary};
            p {
                color: ${colors.white};
            }
            text-align: left;
        }
        .conversation-wrapper.assistant {
            background-color: ${colors.lightGray};
            align-self: end;
            text-align: left;
            width: 100%;
            display: flex;
            img {
                height: 40px;
                margin-right: 3em;
                object-fit: contain;
            }
        }
    }
}
`;

const MessageComponent = ({
    content,
    shouldSimulateTyping,
    scrollToLastMessage
}) => {

    const [displayedMessage, setDisplayedMessage] = useState(shouldSimulateTyping ? "" : content);
    const [hasTyped, setHasTyped] = useState(false);

    useEffect(() => {
        if (!shouldSimulateTyping || hasTyped) return;

        let currentMessage = "";
        const messageChunks = content && content?.split(/(?<=\W)/);

        messageChunks.forEach((chunk, i) => {
            setTimeout(() => {
                currentMessage += chunk;
                setDisplayedMessage(currentMessage);
                if (i === messageChunks.length - 1) {
                    setHasTyped(true);
                }
            }, i * 100);
        });
    }, [content, shouldSimulateTyping, hasTyped]);

    useEffect(() => {
        scrollToLastMessage();
    }, [displayedMessage, scrollToLastMessage]);

    return displayedMessage;  // Modified this for simplicity, adjust as needed
};

const HelpContainer = ({
    handleToggleChat,
    showHelp,
    handleSendMessage,
    conversationHistory,
    thinking,
    typing,
    chatErrorMessage
}) => {
    const { theme } = useTheme();
    const { text } = useLanguage();
    const { isDesktop, isTablet } = useScreenSize();


    const lastMessageRef = useRef(null);
    const [isAdvance, setIsAdvance] = useState(false);

    const handleOnSubmit = (values, form) => {
        handleSendMessage(values, form, lastMessageRef, !isAdvance)
    }

    useEffect(() => {
        if (lastMessageRef.current) {
            lastMessageRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [conversationHistory]);

    const scrollToLastMessage = () => {
        if (lastMessageRef.current) {
            lastMessageRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    useEffect(() => {
        if (showHelp) {
            setIsAdvance(false);
        }
    }, [showHelp]);

    return (
        <StyledChat
            show={showHelp}
            isAdvance={isAdvance}
            theme={theme}
            className={isDesktop ? 'desktop' : isTablet ? 'tablet' : 'phone'}

        >
            {
                <div className="chat">
                    <ConditionalRender renderIf={isDesktop}>
                        <div className='toggle-close' onClick={handleToggleChat}>
                            <FontAwesomeIcon icon={faClose} />
                        </div>
                    </ConditionalRender>
                    <ConditionalRender renderIf={!isDesktop}>
                        <HoverPopup
                            style={{
                                background: theme === 'dark' ? colors.secondary : colors.backgroundSecondary,
                                position: 'absolute',
                                right: '5%',
                                bottom: '5%',
                                padding: '0.5em',
                                borderRadius: '50%',
                                display: 'flex',
                                alignItems: 'center',
                                zIndex: 1000
                            }}
                            placement={'right'}
                            onClick={handleToggleChat}
                            id='toggle-side-two-popup-chat'
                        >
                            <FontAwesomeIcon icon={
                                !isDesktop ? faArrowAltCircleLeft : faClose
                            } />
                        </HoverPopup>
                    </ConditionalRender>
                    <div className='chat-conversation scroll-container'>
                        {
                            (!thinking) && (
                                <div className='flex' style={{
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }}>
                                    <p>
                                        {text?.chat?.info?.question}
                                    </p>
                                    <Info
                                        id={'chat-info'}
                                        placement={'top'}
                                        className='flex justify-center items-center'
                                        text={<ConditionalRender renderIf={!isAdvance}>
                                            <div style={{ margin: '15px', flex: '1' }}>
                                                <p>
                                                    {text?.chat?.info?.textOne}
                                                </p>

                                            </div>
                                        </ConditionalRender>}
                                    />
                                </div>
                            )
                        }
                        {conversationHistory?.map((message, index) => {
                            const isLastMessage = index === conversationHistory.length - 1;
                            const shouldSimulateTyping = message?.role === 'assistant' && isLastMessage;

                            return (
                                <div key={index} className={` ${message?.role === 'user' ? 'user' : message.role} conversation-wrapper`}>
                                    {
                                        message?.role === 'assistant' && <img src={logo} />
                                    }
                                    <p className='content' style={{ display: 'inline', whiteSpace: 'pre-wrap' }}>
                                        {message?.content
                                            ? (shouldSimulateTyping
                                                ? <MessageComponent
                                                    content={message?.content}
                                                    scrollToLastMessage={scrollToLastMessage}
                                                    shouldSimulateTyping={shouldSimulateTyping}
                                                />
                                                : parse(message?.content))
                                            : text?.chat?.sorry
                                        }
                                    </p>
                                </div>
                            )
                        })}
                        {
                            !isEmpty(chatErrorMessage) && (
                                <div className='small error' style={{ marginTop: '15px' }}>
                                    {chatErrorMessage}
                                </div>
                            )
                        }
                        <div ref={lastMessageRef} />

                    </div>
                    <div className={'chat-form'}>
                        <Form
                            onSubmit={(values, form) => handleOnSubmit(values, form)}
                            render={({ handleSubmit, values }) => (
                                <form onSubmit={handleSubmit}>
                                    <div style={{
                                        height: '50px'
                                    }}>
                                        {
                                            // todo remove
                                            (thinking) ? <ThinkingDots /> : (
                                                <FieldWithValidation
                                                    label={text?.chat?.message}
                                                    name="message"
                                                    component={"input"}
                                                    type="text"
                                                    block
                                                    auto
                                                    wrapperStyles={{ width: 'auto' }}
                                                    showLightColors
                                                />
                                            )
                                        }
                                    </div>
                                    {/* todo undo */}
                                    <CallToAction
                                        className={'mt-sm'}
                                        text={text?.chat?.button}
                                        type='submit'
                                        label={text?.chat?.chat}
                                        disabled={false}
                                    />
                                </form>
                            )}
                        />
                    </div>
                </div>
            }
            <ConditionalRender renderIf={isDesktop}>
                {
                    !showHelp && (
                        <div className='toggle-chat' onClick={handleToggleChat}>
                            <HoverPopup placement={'left'} id='toggle-chat-popup' text={text?.chat?.chat}>
                                <FontAwesomeIcon icon={faInfo} />
                            </HoverPopup>
                        </div>
                    )
                }
            </ConditionalRender>
        </StyledChat>
    );
};

export default HelpContainer;
