import React, { createContext, useContext, useEffect, useRef, useState } from 'react';
import { io } from 'socket.io-client';
import { GlobalContext } from './GlobalContext';
import {
    createReply,
    selectConversation,
    findSuperAdminConversation,
    claimSuperAdminConversation,
    closeSuperAdminConversation,
    createMessage,
    createSuperAdminMessage,
    updateMessageImage,
    deleteMessage,
    updateMessage,
    handleDeleteConversation,
    createPrivateConversation,
    handleUpdateParticipants,
    markAsRead,
    updateReaction,
    disabledNotification
} from '../ws';

const WebSocketContext = createContext(null);

export const WebSocketProvider = ({ children, userId, projectId }) => {
    const { setHasNewNotifications, hasNewNotifications } = useContext(GlobalContext);
    const [notifications, setNotifications] = useState([]);
    const [messages, setMessages] = useState([]);
    const [selectedConversationId, setSelectedConversationId] = useState(null);
    const [conversations, setConversations] = useState([]);
    const [participants, setParticipants] = useState([]);
    const [clientParticipants, setClientParticipants] = useState([]);
    const [projectConversations, setProjectConversations] = useState([]);
    const [selectedConversation, setSelectedConversation] = useState(null);
    const [imagePreviews, setImagePreviews] = useState([]);
    const [onlineUsers, setOnlineUsers] = useState([]);
    const [conversationIdIsLoading, setConversationIdIsLoading] = useState(false);
    const [isSendingMessage, setIsSendingMessage] = useState(false);
    const [newMessageReceived, setNewMessageReceived] = useState(false);


    const ws = useRef(null);
    const websocketUrl = process.env.REACT_APP_SERVER_URL;

    useEffect(() => {
        if (!ws.current) return;

        const handleOnlineUsers = (serverUsers) => {
            setOnlineUsers(serverUsers); // Directly update the list with the server's list
        };

        ws.current.on('onlineUsers', handleOnlineUsers);

        return () => {
            ws.current.off('onlineUsers', handleOnlineUsers);
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ws.current]);



    useEffect(() => {
        if (!ws.current) return;

        const handleConversationUpdated = ({ conversationId, hasUnreadMessages, }) => {
            setConversations((prevConversations) =>
                prevConversations.map((conversation) =>
                    conversation._id === conversationId
                        ? { ...conversation, hasUnreadMessages }
                        : conversation
                )
            );

            setProjectConversations((prevProjectConversations) =>
                prevProjectConversations.map((conversation) =>
                    conversation._id === conversationId
                        ? { ...conversation, hasUnreadMessages }
                        : conversation
                )
            );
        };

        ws.current.on('conversationUpdated', handleConversationUpdated);

        return () => {
            ws.current.off('conversationUpdated', handleConversationUpdated);
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ws.current]);

    useEffect(() => {
        if (!ws.current) return;

        const handleAdminConversationClaimed = ({ conversationId, superAdmin, status, messages }) => {
            if (conversationId === selectedConversationId) {
                setSelectedConversation((prev) => {
                    if (prev?._id === conversationId) {
                        return { ...prev, status, superAdmin };
                    }
                    return prev;
                });
            }
        };

        // Attach event listener
        ws.current.on('adminConversationClaimed', handleAdminConversationClaimed);

        return () => {
            // Cleanup listener
            ws.current.off('adminConversationClaimed', handleAdminConversationClaimed);
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ws.current, selectedConversationId]);


    useEffect(() => {
        if (!ws.current) return;

        const handleMessageDeleted = ({ messageId, conversationId: deletedConversationId }) => {
            // Ensure the event is for the current conversation
            if (deletedConversationId === selectedConversationId) {
                setMessages((prev) => prev.filter((msg) => msg._id !== messageId));
            }
        };

        // Attach listener
        ws.current.on('messageDeleted', handleMessageDeleted);

        return () => {
            // Cleanup listener
            ws.current.off('messageDeleted', handleMessageDeleted);
        };
    }, [selectedConversationId, setMessages]);

    useEffect(() => {
        if (!ws.current) return;

        const handleMessageUpdated = ({ conversationId: updatedConversationId, updatedMessage }) => {

            if (updatedConversationId === selectedConversationId) {
                setMessages((prevMessages) => {
                    const newMessages = prevMessages.map((msg) =>
                        msg._id === updatedMessage._id
                            ? { ...msg, ...updatedMessage }
                            : msg
                    );
                    return newMessages;
                });
            }
        };

        ws.current.on('messageUpdated', handleMessageUpdated);

        return () => {
            ws.current.off('messageUpdated', handleMessageUpdated);
        };
    }, [selectedConversationId, setMessages]);



    useEffect(() => {
        if (!ws.current) return;

        // Listen for delete conversation events
        ws.current.on('deleteConversation', ({ conversationId }) => {

            // Remove the deleted conversation from the state
            setConversations((prevConversations) =>
                prevConversations.filter((conversation) => conversation._id !== conversationId)
            );

            // Clear selected conversation if it was the one deleted
            setSelectedConversationId((prevId) =>
                prevId === conversationId ? null : prevId
            );

            // Clear participants if the current conversation was deleted
            setParticipants((prevParticipants) =>
                selectedConversationId === conversationId ? [] : prevParticipants
            );
            setClientParticipants((prevClientParticipants) =>
                selectedConversationId === conversationId ? [] : prevClientParticipants
            );
        });

        return () => {
            ws.current.off('deleteConversation'); // Cleanup listener
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ws.current]);

    useEffect(() => {
        if (!ws.current) return;

        ws.current.on('conversationClosed', ({ conversationId, status, isArchived }) => {
            // If the closed conversation is currently selected, deselect it
            setSelectedConversationId((prevId) => (prevId === conversationId ? null : prevId));
            setSelectedConversation((prev) => {
                if (prev?._id === conversationId) {
                    return { ...prev, status, isArchived };
                }
                return prev;
            });


        });

        return () => {
            ws.current.off('conversationClosed');
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ws.current, selectedConversationId]);



    useEffect(() => {
        if (!ws.current) return;

        // Listen for new conversations
        ws.current.on('newConversation', (data) => {
            const { conversation } = data;

            // Update the conversations list
            setConversations((prevConversations) => {
                const exists = prevConversations.some((c) => c._id === conversation._id);
                if (exists) {
                    return prevConversations.map((c) =>
                        c._id === conversation._id ? conversation : c
                    );
                }
                return [...prevConversations, conversation];
            });

            // Update participants list
            setParticipants(conversation.participants.map((p) => p._id));
            setClientParticipants(conversation.clientParticipants.map((c) => c._id));
        });

        return () => {
            ws.current.off('newConversation'); // Clean up listener
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ws.current]);


    useEffect(() => {
        if (!ws.current) return;

        // Listen for updated reactions
        ws.current.on('reactionUpdated', (data) => {
            const { messageId, reactions } = data;

            // Update the message with the new reactions
            setMessages((prevMessages) =>
                prevMessages.map((message) =>
                    message._id === messageId
                        ? { ...message, reactions } // Update reactions for the specific message
                        : message // Leave other messages unchanged
                )
            );
        });

        return () => {
            ws.current.off('reactionUpdated'); // Clean up listener
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ws.current, setMessages]);


    useEffect(() => {
        // Initialize WebSocket connection
        ws.current = io(websocketUrl, {
            transports: ['websocket'],
        });

        ws.current.on('connect', () => {
            ws.current.emit('register', userId);
            // Join project room if projectId is provided
            if (projectId) {
                ws.current.emit('join', projectId);
            }
        });

        ws.current.on('connect_error', (error) => {
            console.error('WebSocket connection error:', error);
        });

        return () => {
            if (ws.current) {
                ws.current.disconnect();
            }
        };
    }, [websocketUrl, userId, projectId]);


    useEffect(() => {
        const handleExistingNotifications = (existingNotifications) => {
            setNotifications(existingNotifications);
            const oldNotificationCount = parseInt(localStorage.getItem('notificationCount'), 10) || 0;

            if (existingNotifications.length > oldNotificationCount) {
                setHasNewNotifications(true);
            }

            localStorage.setItem('notificationCount', JSON.stringify(existingNotifications.length));
        };

        const handleNewNotification = (newNotification) => {
            setNotifications((prev) => {
                const updatedNotifications = [newNotification, ...prev];
                localStorage.setItem('notificationCount', JSON.stringify(updatedNotifications.length));
                if (!hasNewNotifications) {
                    setHasNewNotifications(true);
                }

                return updatedNotifications;
            });
        };

        ws.current?.on('notifications', handleExistingNotifications);
        ws.current?.on('notification', handleNewNotification);

        return () => {
            ws.current?.off('notifications', handleExistingNotifications);
            ws.current?.off('notification', handleNewNotification);
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [notifications]);

    useEffect(() => {
        if (!ws.current) return;

        const handleConversation = (conversation) => {
            if (conversation._id === selectedConversationId) {
                setMessages(conversation.messages);
            }
        };

        const handleNewMessage = ({ message, userId }) => {
            if (!message) {
                console.error('Message object is missing from the payload.');
                return;
            }

            if (message.conversationId === selectedConversationId) {
                setMessages((prev) => [...prev.filter((msg) => msg._id !== message._id), message]);

                // Check if the message was sent by another user
                if (message.sender?._id !== userId) {
                    setNewMessageReceived(true);
                }
            }
        };
        const handleSuperAdminMessage = ({ message, userId }) => {
            console.log('message', message);
            if (!message) {
                console.error('Message object is missing from the payload.');
                return;
            }

            if (message.conversationId === selectedConversationId) {
                setMessages((prev) => [...prev.filter((msg) => msg._id !== message._id), message]);

                // Check if the message was sent by another user
                if (message.sender?._id !== userId) {
                    setNewMessageReceived(true);
                }
            }
        };


        // Attach listeners
        ws.current.on('conversation', handleConversation);
        ws.current.on('newMessage', handleNewMessage);
        ws.current.on('newSuperAdminMessage', handleSuperAdminMessage);


        return () => {
            // Cleanup listeners
            ws.current.off('conversation', handleConversation);
            ws.current.off('newMessage', handleNewMessage);
            ws.current.off('newSuperAdminMessage', handleSuperAdminMessage);
        };
    }, [selectedConversationId]);

    useEffect(() => {
        if (!ws.current) return;

        const handleNewReply = ({ parentId, reply, replyId, deletedReplyId, updatedReactions, userId }) => {
            if (!parentId) {
                console.error('ParentId is missing from the payload.');
                return;
            }

            setMessages((prevMessages) =>
                prevMessages.map((msg) => {
                    if (msg._id === parentId) {
                        // Ensure replies array exists
                        const replies = msg.replies || [];

                        // Handle reply deletion
                        if (deletedReplyId) {
                            return {
                                ...msg,
                                replies: replies.filter((r) => r._id !== deletedReplyId),
                            };
                        }

                        // Handle reaction updates for a reply
                        if (updatedReactions && replyId) {
                            return {
                                ...msg,
                                replies: replies.map((r) =>
                                    r._id === replyId ? { ...r, reactions: updatedReactions } : r
                                ),
                            };
                        }

                        // Handle new or updated reply
                        if (reply) {
                            const replyIndex = replies.findIndex((r) => r._id === reply._id);

                            if (replyIndex === -1) {
                                // If the reply does not exist, add it
                                return {
                                    ...msg,
                                    replies: [...replies, reply],
                                };
                            } else if (replyIndex >= 0) {
                                // If the reply exists, update it
                                return {
                                    ...msg,
                                    replies: replies.map((r) =>
                                        r._id === reply._id ? { ...r, ...reply } : r
                                    ),
                                };
                            }
                        }
                    }
                    return msg;
                })
            );


            if (reply && reply.sender?._id !== userId && reply.clientSender?._id !== userId) {
                setNewMessageReceived(true);
            }
        };


        ws.current.on('newReply', handleNewReply);

        return () => {
            ws.current.off('newReply', handleNewReply);
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ws.current, selectedConversationId]);

    useEffect(() => {
        if (!ws.current) return;


        const handleParticipantsUpdated = ({ conversationId, participants, clientParticipants, conversationName }) => {
            setConversations((prevConversations) =>
                prevConversations.map((conversation) =>
                    conversation._id === conversationId
                        ? { ...conversation, participants, conversationName }
                        : conversation
                )
            );

            // If the selected conversation is updated, update its details
            if (selectedConversationId === conversationId) {
                setParticipants(participants?.map((p) => p._id));
                setClientParticipants(clientParticipants?.map((c) => c._id));
                setSelectedConversation((prev) => ({ ...prev, participants, conversationName }));
            }
        };

        ws.current.on('participantsUpdated', handleParticipantsUpdated);

        // Cleanup listener on unmount
        return () => {
            ws.current.off('participantsUpdated');
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ws.current, selectedConversationId]);

    useEffect(() => {
        if (!ws.current) return;

        const handleConversationRemoved = ({ conversationId }) => {
            setConversations((prevConversations) =>
                prevConversations.filter((conversation) => conversation._id !== conversationId)
            );

            // If the removed conversation is currently selected, reset selection
            if (selectedConversationId === conversationId) {
                setSelectedConversationId(null);
                setSelectedConversation(null);
                setParticipants([]);
                setClientParticipants([]);
                setMessages([]);
            }
        };

        ws.current.on('conversationRemoved', handleConversationRemoved);

        // Cleanup listener on unmount
        return () => {
            ws.current.off('conversationRemoved', handleConversationRemoved);
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ws.current, selectedConversationId]);



    return (
        <WebSocketContext.Provider value={{
            onlineUsers,
            notifications,
            setNotifications,
            messages,
            setMessages,
            selectedConversationId,
            setSelectedConversationId,
            selectConversation: (conversation, companyId, userId) =>
                selectConversation(
                    conversation,
                    companyId,
                    userId,
                    ws,
                    setSelectedConversationId,
                    setSelectedConversation,
                    setMessages,
                    setConversationIdIsLoading
                ),
            setProjectConversations,
            projectConversations,
            createPrivateConversation: (userIds, companyId, currentUserId, clientIds, callback) =>
                createPrivateConversation(
                    userIds,
                    companyId,
                    currentUserId,
                    clientIds,
                    callback,
                    ws
                ),
            setConversations,
            conversations,
            setParticipants,
            participants,
            setClientParticipants,
            clientParticipants,
            handleUpdateParticipants: (conversationId, updatedUserParticipants, updatedClientParticipants, user) =>
                handleUpdateParticipants(
                    conversationId,
                    updatedUserParticipants,
                    updatedClientParticipants,
                    user,
                    ws,
                    setConversations,
                    setParticipants,
                    setClientParticipants,
                    setSelectedConversation,
                    setSelectedConversationId,
                    setMessages,
                    selectedConversationId
                ),
            setSelectedConversation,
            selectedConversation,
            createMessage: (conversationId, messageContent, messageType, userId, imagePreviews, callback) =>
                createMessage(
                    conversationId,
                    messageContent,
                    messageType,
                    userId,
                    imagePreviews,
                    callback,
                    setIsSendingMessage,
                    setImagePreviews,
                    setMessages,
                    ws
                ),
            createSuperAdminMessage: (conversationId, companyId, messageContent, messageType, userId, imagePreviews, callback) =>
                createSuperAdminMessage(
                    conversationId,
                    companyId,
                    messageContent,
                    messageType,
                    userId,
                    imagePreviews,
                    callback,
                    setIsSendingMessage,
                    setImagePreviews,
                    setMessages,
                    ws,
                    selectedConversationId
                ),
            createReply: (conversationId, parentId, messageContent, messageType, userId, imagePreviews, callback) =>
                createReply(
                    conversationId,
                    parentId,
                    messageContent,
                    messageType,
                    userId,
                    imagePreviews,
                    callback,
                    setIsSendingMessage,
                    setImagePreviews,
                    setMessages,
                    selectedConversationId,
                    ws
                ),
            markAsRead: (conversationId, messageIds, user) =>
                markAsRead(conversationId, messageIds, user, ws),
            disabledNotification: (conversationId, userId, disable) =>
                disabledNotification(
                    conversationId,
                    userId,
                    disable,
                    ws,
                    setConversations,
                    setProjectConversations,
                    selectedConversationId,
                    setSelectedConversation
                ),
            conversationIdIsLoading,
            newMessageReceived,
            setNewMessageReceived,
            handleDeleteConversation: (conversationId, userId, companyId) =>
                handleDeleteConversation(
                    conversationId,
                    userId,
                    companyId,
                    ws,
                    setConversations,
                    setSelectedConversationId,
                    setParticipants,
                    setClientParticipants
                ),
            isSendingMessage,
            setIsSendingMessage,
            setImagePreviews,
            imagePreviews,
            deleteMessage: (messageId) =>
                deleteMessage(
                    messageId,
                    ws,
                    setMessages,
                    selectedConversationId
                ),
            updateMessage: (messageId, updatedContent, senderId, callback) =>
                updateMessage(
                    messageId,
                    updatedContent,
                    senderId,
                    callback,
                    ws,
                    setMessages,
                    selectedConversationId
                ),
            updateMessageImage: (data, callback) =>
                updateMessageImage(
                    data,
                    callback,
                    ws,
                    setMessages,
                    selectedConversationId
                ),
            updateReaction: (messageId, reactionType, userId) =>
                updateReaction(
                    messageId,
                    reactionType,
                    userId,
                    ws,
                    setMessages
                ),
            findSuperAdminConversation: (userId, companyId, callback) =>
                findSuperAdminConversation(
                    userId,
                    companyId,
                    callback,
                    ws,
                    setMessages,
                    setSelectedConversation,
                    setSelectedConversationId,
                    setConversationIdIsLoading
                ),
            claimSuperAdminConversation: (superAdminConversationId, adminId, callback) =>
                claimSuperAdminConversation(
                    superAdminConversationId,
                    adminId,
                    callback,
                    ws,
                    setConversations
                ),
            closeSuperAdminConversation: (superAdminConversationId, callback) =>
                closeSuperAdminConversation(
                    superAdminConversationId,
                    callback,
                    ws,
                    setConversations,
                    setSelectedConversation
                ),

        }}>
            {children}
        </WebSocketContext.Provider>
    );
};

export const useWebSocket = () => {
    return useContext(WebSocketContext);
};
