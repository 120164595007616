import React from 'react';
import { Field } from 'react-final-form';
import { startCase, camelCase } from 'lodash'
import CreatableInput from './CreatableInput';

const AutocompleteDropdown = ({ name, label, options, validate, noFormat, ...props }) => {
    if (!noFormat) {
        options = options?.map(option => {
            return { label: startCase(option), value: camelCase(option) }
        })
    }

    return (
        <Field
            name={name}
            component={CreatableInput}
            options={options}
            validate={validate}
            label={label}
            {...props}
        />
    );
};

export default AutocompleteDropdown;
