import requestNoTimeOut from '../requestNoTimeOut';

/**
 * Sends a message to query open ai
 * @returns {Promise<Object>} - A Promise that resolves to the API response data.
 * @throws {Error} - If the API request fails, an Error is thrown.
 */
export const searchInventoryRecommendations = async (content) => {
    try {
        const { data, status } = await requestNoTimeOut('POST', '/search/inventory-recommendation', { content });
        return { data, status };
    } catch (error) {
        throw new Error(`Failed to query chat: ${error.message}`);
    }
};

export default searchInventoryRecommendations;
