export const handleDeleteConversation = async (
    conversationId,
    userId,
    companyId,
    ws,
    setConversations,
    setSelectedConversationId,
    setParticipants,
    setClientParticipants
) => {
    if (!ws.current) {
        console.error('WebSocket is not connected.');
        return;
    }

    ws.current.emit(
        'deletePrivateConversation',
        { conversationId, currentUserId: userId, companyId },
        (response) => {
            if (response.success) {

                setConversations((prevConversations) =>
                    prevConversations.filter((conversation) => conversation._id !== conversationId)
                );
                setSelectedConversationId(null);
                setParticipants([]);
                setClientParticipants([]);
            } else {
                console.error('Failed to delete conversation:', response.message);
            }
        }
    );
};