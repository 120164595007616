import React from 'react';
import { Field } from 'react-final-form';
import 'bootstrap/dist/css/bootstrap.min.css';
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';
import moment from 'moment';
import { FieldStyles } from './FieldStyles';
import useScreenSize from '../../context/useScreenSize';
import ConditionalRender from '../ConditionalRender';

const TimePickerInput = ({ input, meta, setIsEditing, onChange, ...rest }) => {
    const { isPhone } = useScreenSize();
    const handleTimeChange = (time) => {
        if (onChange) onChange(time && time.format('HH:mm'))
        input.onChange(time ? time.format('HH:mm') : '');
        if (setIsEditing) {
            setIsEditing(true);
        }
    };

    return (
        <>
            <ConditionalRender renderIf={!isPhone}>
                <TimePicker
                    onChange={handleTimeChange}
                    value={input.value ? moment.utc(input.value, 'HH:mm') : null}
                    showSecond={false}
                    minuteStep={1}
                    use12Hours
                    placeholder={'Select Time'}
                    allowEmpty={false}
                    inputReadOnly
                    {...rest}
                />
                {meta.touched && meta.error && <span>{meta.error}</span>}
            </ConditionalRender>
            <ConditionalRender renderIf={isPhone}>
                <input
                    type="time"
                    onChange={(e) => handleTimeChange(moment(e.target.value, 'HH:mm'))}
                    value={input.value ? moment.utc(input.value, 'HH:mm').format('HH:mm') : ''}
                    {...rest}
                />
            </ConditionalRender>
        </>
    );
};

export const SelectTime = ({ name, label, ...rest }) => {
    return (
        <FieldStyles>
            {
                label && (
                    <label className='custom-label'>{label}</label>
                )
            }
            <div className='custom-field'>
                <Field name={name} component={TimePickerInput} {...rest} />
            </div>
        </FieldStyles>
    );
};

export default SelectTime;
