import React, { useRef, useState, useCallback, useEffect } from 'react';
import ImageUploader from '../ImageUploader';// Adjust the import path
import { Editor } from '@tinymce/tinymce-react';
import { Form } from 'react-final-form';
import ConditionalRender from '../ConditionalRender';
import colors from '../../globalStyles.scss'; // Adjust the import path
import { useLanguage } from '../../context/LanguageContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose, faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import HoverPopup from '../HoverPopup';
import axios from 'axios';
import ConfirmModal from '../ConfirmModal';
import useScreenSize from '../../context/useScreenSize';
import styled from 'styled-components';
import { isEmpty } from 'lodash';
import FieldWithNoValidation from '../FieldWithNoValidation';
import CallToAction from '../CallToAction';

const StyledDiv = styled.div`
    .emoji-picker {
       button {
        cursor: pointer;
        transition: transform 0.2s ease;
         &:hover {
            transform: scale(1.2);
        }
       }
    }
`

const RichEditorWithImageUploader = ({
    onImageUpload,
    maxFiles,
    customMessage,
    title,
    showForm = true,
    onSubmit,
    submitText = 'Submit',
    name = 'message',
    isEditing,
    initialValue,
    onCancel,
    customButton,
    isSendingMessage,
    imagePreviews,
    setImagePreviews,
    isDark = false,
    noGif = false,
    onChange,
    height = 150,
    noKeyDown = false,
    noButton = false,
    hideSave = false,
}) => {
    const { isDesktop } = useScreenSize();
    const formRef = useRef(null); // Ref to store the form instance
    const { text } = useLanguage();

    const editorRef = useRef(null);
    const [editorValue, setEditorValue] = useState(initialValue || '');

    const handleKeyDownRef = useRef();
    const imagePreviewsRef = useRef(imagePreviews);

    const [showGifModal, setShowGifModal] = useState(false);
    const [gifSearchResults, setGifSearchResults] = useState([]);
    const [gifSearchQuery, setGifSearchQuery] = useState('');


    useEffect(() => {
        // Sync `imagePreviews` to the ref
        imagePreviewsRef.current = imagePreviews;
    }, [imagePreviews]);

    const handleKeyDown = useCallback(
        async (e) => {
            if (noKeyDown) return;
            const content = editorRef.current?.getContent({ format: 'html' })?.trim();

            if (e.key === 'Enter' && !e.shiftKey) {
                e.preventDefault();

                const currentImagePreviews = imagePreviewsRef.current; // Always use the latest value

                if (content) {
                    if (showForm) {
                        const form = formRef.current;
                        if (form) {
                            form.change(name, content);
                            await form.submit();
                        }
                    } else {
                        // Pass `currentImagePreviews` to ensure correct images are used
                        if (onSubmit) {
                            await onSubmit({ [name]: content, images: currentImagePreviews });
                        }
                    }

                    editorRef.current?.setContent(''); // Clear editor content

                    // Delay clearing of `imagePreviews`
                    setTimeout(() => {
                        setImagePreviews && setImagePreviews([]);
                    }, 500); // Adjust delay as needed
                }
            } else if (e.key === 'Escape') {
                e.preventDefault();
                if (onCancel) {
                    onCancel();
                }
            }
        },
        [name, onSubmit, onCancel, showForm] // Do not include `imagePreviews` here
    );
    // Update the ref with the latest handleKeyDown function
    useEffect(() => {
        handleKeyDownRef.current = handleKeyDown;
    }, [handleKeyDown]);
    // Ensure proper listener setup
    useEffect(() => {
        const editor = editorRef.current;
        if (!editor) return;

        const keyDownHandler = (e) => {
            handleKeyDownRef.current?.(e); // Use the latest handler
        };
        editor.on('keydown', keyDownHandler);

        return () => {
            editor.off('keydown', keyDownHandler); // Cleanup listener
        };
    }, []); // Attach listener only once


    const handleEmojiPicker = (editor) => {
        const pickerId = `emoji-picker-${editor.id}`;
        let emojiPicker = document.getElementById(pickerId);

        const closeEmojiPicker = () => {
            if (emojiPicker) {
                emojiPicker.remove();
                document.removeEventListener('click', handleOutsideClick);
                editor.iframeElement?.contentWindow?.removeEventListener('click', closeEmojiPicker);
            }
        };

        const handleOutsideClick = (e) => {
            const editorContainer = editor.getContainer(); // Get TinyMCE editor container
            if (
                emojiPicker &&
                !emojiPicker?.contains(e.target) &&
                !editorContainer?.contains(e.target) // Closes when clicking inside the editor
            ) {
                closeEmojiPicker();
            }
        };

        const parentDiv = editor.getContainer();
        if (!parentDiv) {
            console.error('Editor container not found.');
            return;
        }

        if (emojiPicker) {
            closeEmojiPicker();
        } else {
            emojiPicker = document.createElement('div');
            emojiPicker.id = pickerId;
            emojiPicker.classList.add('emoji-picker');
            emojiPicker.classList.add('scroll-container');
            emojiPicker.style.position = 'absolute';
            emojiPicker.style.height = '250px'; // Limit height with scroll
            emojiPicker.style.overflowY = 'auto';
            emojiPicker.style.top = '-60px';
            emojiPicker.style.left = '50%';
            emojiPicker.style.transform = 'translate(-50%, 0)';
            emojiPicker.style.zIndex = '9999';
            emojiPicker.style.background = '#fff';
            emojiPicker.style.padding = '10px';
            emojiPicker.style.borderRadius = '5px';
            emojiPicker.style.boxShadow = '0px 4px 6px rgba(0, 0, 0, 0.1)';
            emojiPicker.style.display = 'grid';
            emojiPicker.style.gridTemplateColumns = 'repeat(8, 1fr)';
            emojiPicker.style.gap = '5px';

            // List of emojis
            const emojis = [
                '😀', '😁', '😂', '🤣', '😃', '😄', '😅', '😆', '😉', '😊', '😋', '😎', '😍', '😘', '🥰', '😗', '😙', '😚',
                '🙂', '🤗', '🤩', '🤔', '🤨', '😐', '😑', '😶', '🙄', '😏', '😣', '😥', '😮', '🤐', '😯', '😪', '😫', '🥱',
                '😴', '😌', '😛', '😜', '😝', '🤤', '😒', '😓', '😔', '😕', '🙃', '🤑', '😲', '☹️', '🙁', '😖', '😞', '😟',
                '😤', '😢', '😭', '😦', '😧', '😨', '😩', '🤯', '😬', '😰', '😱', '🥵', '🥶', '😳', '🤪', '😵', '🥴', '😠',
                '😡', '🤬', '🤡', '👹', '👺', '💀', '👻', '👽', '🤖', '💩', '😺', '😸', '😹', '😻', '😼', '😽', '🙀', '😿',
                '😾', '👋', '🤚', '🖐', '✋', '🖖', '👌', '🤌', '🤏', '✌️', '🤞', '🤟', '🤘', '🤙', '👈', '👉', '👆', '🖕',
                '👇', '☝️', '👍', '👎', '✊', '👊', '🤛', '🤜', '👏', '🙌', '👐', '🤲', '🤝', '🙏', '💅', '🤳', '💪', '🦾'
            ];

            emojis.forEach((emoji) => {
                const button = document.createElement('button');
                button.textContent = emoji;
                button.style.border = 'none';
                button.style.background = 'transparent';
                button.style.fontSize = '20px';
                button.style.cursor = 'pointer';
                button.style.padding = '5px';

                button.addEventListener('click', (e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    closeEmojiPicker();
                    editor.insertContent(emoji);
                });

                emojiPicker.appendChild(button);
            });

            parentDiv.appendChild(emojiPicker);
            document.addEventListener('click', handleOutsideClick);

            // Add event listener inside the TinyMCE editor's iframe to close picker when clicking inside the editor
            setTimeout(() => {
                const iframe = editor.iframeElement?.contentWindow;
                if (iframe) {
                    iframe.addEventListener('click', closeEmojiPicker);
                }
            }, 500); // Small delay to ensure TinyMCE is ready
        }
    };





    const handleEditorInit = (editor) => {
        editor.ui.registry.addButton('customInsertEmoji', {
            text: '😀',
            onAction: () => handleEmojiPicker(editor),
        });
    };

    const fetchGifs = async (query, isShuffle = false) => {
        const apiKey = '3IXxgfxGRu8TIQAWKQD7MDt96VuJ8JIF';
        try {
            const randomOffset = isShuffle ? Math.floor(Math.random() * 100) : 0; // Use random offset for shuffle
            const response = await axios.get(`https://api.giphy.com/v1/gifs/search`, {
                params: {
                    api_key: apiKey,
                    q: query,
                    limit: 5,
                    offset: randomOffset,
                },
            });
            setGifSearchResults(response.data.data);
        } catch (error) {
            console.error('Error fetching GIFs:', error);
        }
    };



    const handleInsertGif = (gifUrl) => {
        const editor = editorRef.current;
        if (editor) {
            editor.insertContent(`<img src="${gifUrl}" alt="GIF" style="max-width: 100%;" />`);
            setShowGifModal(false);
        }
        setGifSearchQuery('');
        setGifSearchResults([]);
    };


    return (
        <ImageUploader
            onImageUpload={onImageUpload}
            maxFiles={maxFiles}
            customMessage={customMessage}
            title={title}
            isEditing={isEditing}
            imageSize={'100px'}
            customButton={customButton}
            isSendingMessage={isSendingMessage}
            imagePreviews={imagePreviews}
            isDark={isDark}
            noButton={noButton}
            hideSave={hideSave}

        >
            <StyledDiv className="chat-text relative" style={{
                zIndex: 0,
            }}>
                <ConditionalRender renderIf={showForm}>
                    <Form
                        onSubmit={(values, form) => {
                            onSubmit(values, form)
                            form.reset();
                            setEditorValue('');
                        }}
                        render={({ handleSubmit, form, values }) => {
                            formRef.current = form;
                            return (
                                <form onSubmit={handleSubmit}>
                                    <Editor
                                        apiKey="ufnzdbkgsq9gt4qexsl47ib3zlt5dr49kfnxd00tp8vxa5m0"
                                        value={editorValue}
                                        onInit={(evt, editor) => (editorRef.current = editor)}
                                        init={{
                                            height,
                                            resize: false,
                                            menubar: false,
                                            statusbar: false,
                                            toolbar: !noGif
                                                ? 'bold italic strikethrough blockquote bullist numlist outdent indent | customInsertEmoji | customInsertGif'
                                                : 'bold italic strikethrough blockquote bullist numlist outdent indent',
                                            plugins: 'lists advlist',

                                            setup: (editor) => {
                                                handleEditorInit(editor);
                                                editor.ui.registry.addButton('customInsertGif', {
                                                    text: 'GIF',
                                                    onAction: () => setShowGifModal(true),
                                                });
                                                editor.on('keydown', handleKeyDown);

                                            },
                                        }}
                                        onEditorChange={(content) => {
                                            setEditorValue(content);
                                            form.change(name, content);
                                        }}
                                    />
                                    <div className='flex mt-sm items-center w-100 justify-content-end'>

                                        <HoverPopup
                                            btnClassName={'secondary-button'}
                                            className='popup'
                                            placement={'bottom'}
                                            id={`send-${name}`}
                                            text={submitText}
                                            onClick={handleSubmit}
                                            wrapperStyle={{
                                                background: isDark ? colors.backgroundSecondary : colors.primary,
                                            }}
                                        >
                                            <FontAwesomeIcon
                                                icon={faPaperPlane}
                                                style={{ color: colors.white }}
                                            />
                                        </HoverPopup>
                                        <ConditionalRender renderIf={isEditing && onCancel}>
                                            <HoverPopup
                                                btnClassName={'secondary-button'}
                                                className='popup mr-md'
                                                placement={'bottom'}
                                                id={`close-${name}`}
                                                text={text?.inventory?.create?.cancel} onClick={onCancel}
                                                wrapperStyle={{
                                                    background: isDark ? colors.backgroundSecondary : colors.primary,
                                                }}
                                            >
                                                <FontAwesomeIcon
                                                    icon={faClose}
                                                    style={{ color: colors.red }}
                                                />
                                            </HoverPopup>
                                        </ConditionalRender>
                                    </div>
                                </form>
                            )
                        }}
                    />

                </ConditionalRender>

                <ConditionalRender renderIf={!showForm}>
                    <Editor
                        apiKey="ufnzdbkgsq9gt4qexsl47ib3zlt5dr49kfnxd00tp8vxa5m0"
                        value={editorValue}
                        onInit={(evt, editor) => (editorRef.current = editor)}
                        init={{
                            height,
                            resize: false,
                            menubar: false,
                            statusbar: false,
                            plugins: 'lists advlist',
                            toolbar: !noGif
                                ? 'bold italic strikethrough blockquote bullist numlist outdent indent | customInsertEmoji | customInsertGif'
                                : 'bold italic strikethrough blockquote bullist numlist outdent indent',
                            setup: (editor) => {
                                handleEditorInit(editor);
                                editor.ui.registry.addButton('customInsertGif', {
                                    text: 'GIF',
                                    onAction: () => setShowGifModal(true),
                                });
                                editor.on('keydown', handleKeyDown);
                            },
                        }}
                        onEditorChange={(content) => {
                            setEditorValue(content);
                            onChange && onChange(content);
                        }}
                    />
                    <div className='flex mt-sm items-center w-100 justify-content-end'>
                        <ConditionalRender renderIf={isEditing}>
                            <HoverPopup
                                btnClassName={'secondary-button'}
                                className='popup mr-md'
                                placement={'bottom'}
                                id={`close-${name}`}
                                text={text?.inventory?.create?.cancel} onClick={onCancel}
                                wrapperStyle={{
                                    background: isDark ? colors.backgroundSecondary : colors.primary,
                                }}
                            >
                                <FontAwesomeIcon
                                    icon={faClose}
                                    style={{ color: colors.red }}
                                />
                            </HoverPopup>
                        </ConditionalRender>
                        <ConditionalRender renderIf={onSubmit}>
                            <HoverPopup
                                btnClassName={'secondary-button'}
                                className='popup'
                                placement={'bottom'}
                                id={`send-${name}`}
                                text={submitText}
                                wrapperStyle={{
                                    background: isDark ? colors.backgroundSecondary : colors.primary,
                                }}
                                onClick={() => {
                                    onSubmit({ [name]: editorValue });
                                    setEditorValue('');
                                }}
                            >
                                <FontAwesomeIcon
                                    icon={faPaperPlane}
                                    style={{ color: colors.white }}
                                />
                            </HoverPopup>
                        </ConditionalRender>

                    </div>
                </ConditionalRender>
                <ConditionalRender renderIf={showGifModal}>
                    <ConfirmModal
                        isOpen={showGifModal}
                        toggle={() => {
                            setShowGifModal(false);
                            setGifSearchQuery('');
                            setGifSearchResults([]);
                        }}
                        width={isDesktop ? '50%' : '100%'}
                        height={isDesktop ? '60%' : '100%'}
                        onClick={!isEmpty(gifSearchQuery) ? () => fetchGifs(gifSearchQuery, false) : undefined}
                        text={text?.inventory?.landing?.table?.search}
                    >
                        <div className="gif-modal w-100">
                            <div className="gif-modal-content">
                                <FieldWithNoValidation
                                    type="text"
                                    name="gifSearchQuery"
                                    value={gifSearchQuery}
                                    onChange={(e) => setGifSearchQuery(e.target.value)}
                                    label={text?.chat?.searchForAGif}
                                />
                                <div className="gif-results mt-md">
                                    {gifSearchResults.map((gif) => (
                                        <img
                                            key={gif.id}
                                            src={gif.images.fixed_height.url}
                                            alt={gif.title}
                                            onClick={() => handleInsertGif(gif.images.fixed_height.url)}
                                            style={{ cursor: 'pointer', margin: '5px' }}
                                        />
                                    ))}
                                </div>
                            </div>
                            <ConditionalRender renderIf={!isEmpty(gifSearchResults)}>
                                <CallToAction
                                    className="shuffle-button mt-md"
                                    onClick={() => fetchGifs(gifSearchQuery, true)}
                                    text={'Shuffle'}
                                />
                            </ConditionalRender>
                        </div>
                    </ConfirmModal>
                </ConditionalRender>
            </StyledDiv>
        </ImageUploader>
    );
};

export default RichEditorWithImageUploader;
