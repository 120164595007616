import React, { useState, useEffect, useRef } from 'react'
import { v4 as uuidv4 } from 'uuid'; // Install uuid if you don't have it
import { useNavigate } from 'react-router-dom';
import SubmitModal from '../Core/SubmitModal';
import { isRequired, composeValidators } from '../utils/validators';
import MultiFields from '../Core/MultiFields';
import { FormSpy } from 'react-final-form';
import FieldWithNoValidation from '../Core/FieldWithNoValidation';
import { isEmpty } from 'lodash';
import SelectInput from '../Core/SelectInput';
import { status, priority } from '../utils/helperFunctions';
import colors from '../globalStyles.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAdd, faClose } from '@fortawesome/free-solid-svg-icons';
import {
    createTask,
    updateUpdatedDate,
    updateHistory
} from '../utils/calls';
import ConditionalRender from '../Core/ConditionalRender';
import { useLanguage } from '../context/LanguageContext';
import { useToast } from '../context/ToastContext';
import useScreenSize from '../context/useScreenSize';
import { useTheme } from '../context/ThemeContext';
import classnames from 'classnames';
import styled from 'styled-components';

const StyledTodo = styled.div`
        background-color: ${(props) => props.theme === 'light' ? colors.lightGray : colors.black};
        padding: 0.5em;

        .wrapper {
            align-items: center;
            display: flex;
            margin-bottom: 0.5em;
            padding: 0.5em;
        }
    `

const CreateNewTaskContainer = ({
    isOpen,
    toggle,
    projects,
    adminProjects,
    employees,
    fetchProjectTasks,
    isProject,
    projectId,
    tasks,
    handleGetProject
}) => {
    const inputRefs = useRef([]);
    const { theme } = useTheme();
    const navigate = useNavigate();

    const { text } = useLanguage();
    const { notify } = useToast();
    const { isDesktop, isTablet, isPhone } = useScreenSize();
    const [todos, setTodos] = useState([{ id: uuidv4(), text: '', completed: false }]);

    const required = composeValidators(
        isRequired(text?.tasks?.create?.validations?.required),
    )

    const [initialProjectValue, setInitialProjectValue] = useState(
        isProject ? { label: projects?.find((x) => x?._id === projectId)?.projectName, value: projectId } : null
    )
    useEffect(() => {
        setInitialProjectValue(
            isProject ? { label: projects?.find((x) => x?._id === projectId)?.projectName, value: projectId } : null
        )
    }, [projectId])

    const [error, setError] = useState(null);


    const onSubmit = async (values) => {
        const now = new Date();
        const nowLocal = new Date(now.getTime() - (now.getTimezoneOffset() * 60000)).toISOString().split('.')[0] + 'Z';

        values.localTime = nowLocal;
        if (!isEmpty(todos)) {
            values.taskTodos = todos.filter(todo => todo.text.trim() !== '');
        }

        try {
            toggle()
            const res = await createTask(values)
            if (res.status === 200) {
                fetchProjectTasks && fetchProjectTasks(projectId)
                if (isProject) {
                    updateUpdatedDate(projectId);
                    handleGetProject(`${text?.projects?.history?.taskCreated} ${values.taskTitle}`)
                }
                if (!isProject) {
                    if (values?.taskProject?.value !== '000000000000000000000000') {
                        await updateHistory(values?.taskProject?.value, `${text?.projects?.history?.taskCreated} ${values.taskTitle}`);
                        navigate(`/tasks/details?id=${res?.data?._id}&uuid=${res?.data?.uuid}`)
                    }
                }
                notify(text?.notificationsUI?.task?.created, 'success')
            }
        } catch (error) {
            notify(`Error: ${error.message}`, 'error')
            console.error(error)
        }
    }

    const addTodo = () => {
        // Check if the last todo is not empty
        if (todos.length > 0 && todos[todos.length - 1]?.text.trim() === '') {
            setError(text?.tasks?.create?.error);
            return;
        }

        setError(''); // Reset error
        setTodos((prevTodos) => [...prevTodos, { id: uuidv4(), text: '', completed: false }]);

        // Focus on the new input field after adding a new todo
        setTimeout(() => {
            const nextField = inputRefs.current[todos.length];
            if (nextField) {
                nextField.focus();
            }
        }, 0); // Delay to ensure the new input field is rendered
    };

    const updateTodo = (id, key, value) => {
        const updatedTodos = todos.map((todo) =>
            todo.id === id ? { ...todo, [key]: value } : todo
        );
        setTodos(updatedTodos);
    };

    const removeTodo = (id) => {
        const updatedTodos = todos.filter((todo) => todo.id !== id);
        setTodos(updatedTodos);

        // Update the inputRefs array by removing the reference of the deleted todo
        inputRefs.current = inputRefs.current.filter((_, i) => todos[i]?.id !== id);
    };


    return (
        <SubmitModal
            onClick={onSubmit}
            text={text?.tasks?.create?.button}
            toggle={toggle}
            width={isDesktop ? '80%' : '100%'}
            height={isDesktop ? '80%' : '100%'}
            isOpen={isOpen}
            btnStyles={{
                color: colors.blue
            }}
        >
            <h4>
                {text?.tasks?.home?.createNew}
            </h4>
            <MultiFields
                className='field-style'
                name="taskTitle"
                component="input"
                type="text"
                label={text?.tasks?.create?.title}
                block
                validate={required}
                // showLightColors
                autoComplete
                fieldName='taskTitle'
                fields={[
                    {
                        fieldLabel: text?.tasks?.create?.title,
                        fieldName: 'taskTitle',
                    }
                ]}
                suggestionData={tasks}
            />
            <MultiFields
                className='field-style'
                name="taskDescription"
                component="textarea"
                type="text"
                label={text?.tasks?.create?.description}
                block
                validate={required}
            // showLightColors
            />
            <div className={classnames({
                'flex justify-between': isDesktop
            })}>
                <div style={{
                    width: isDesktop ? '50%' : '100%',
                }}>
                    <MultiFields
                        className='field-style'
                        name="taskDeadline"
                        component="date"
                        type="text"
                        label={text?.tasks?.create?.deadline}
                        block
                    />

                    <SelectInput
                        className='field-style'
                        name='taskStatus'
                        isClearable={false}
                        placeholder={text?.tasks?.create?.status}
                        validate={required}
                        // showLightColors
                        options={status(text).filter((x) =>
                            x.value !== 'completed' && x.value !== 'cancelled' && x.value !== 'paused'
                        )}
                        initialValue={status(text).find((x) => x.value === 'pending')}
                        isSearchable={false}
                        style={{
                            width: isDesktop ? '50%' : '100%',
                        }}
                    />
                    <SelectInput
                        className='field-style'
                        isClearable={false}
                        isSearchable={false}
                        name='taskPriority'
                        placeholder={text?.tasks?.create?.priority}
                        validate={required}
                        // showLightColors
                        options={priority(text)}
                        style={{
                            width: isDesktop ? '50%' : '100%',
                        }}
                    />

                    <FormSpy subscription={{ values: true }}>
                        {({ form }) => (
                            <>
                                <SelectInput
                                    className='field-style'
                                    // showLightColors
                                    menuPlacement='top'
                                    name='taskProject'
                                    isClearable={false}
                                    validate={required}
                                    disabled={isProject}
                                    initialValue={initialProjectValue}
                                    placeholder={text?.tasks?.create?.project}
                                    isSearchable
                                    isValidNewOption={() => false}
                                    controlWidth='100%'
                                    options={adminProjects?.filter((x) => x?.projectStatus !== 'completed')?.map((x) => ({
                                        label: x?.projectName,
                                        value: x?._id
                                    }))}
                                    style={{
                                        width: isDesktop ? '50%' : '100%',
                                        marginBottom: '1em'
                                    }}
                                    onChange={() => {
                                        // Reset taskAssignedTo when taskProject changes
                                        form.change('taskAssignedTo',
                                            { label: text?.tasks?.create?.unassigned, value: '000000000000000000000000' }
                                        );
                                    }}
                                />
                                <FormSpy subscription={{ values: true }}>
                                    {({ values }) => {
                                        const selectedProject = projects?.find((x) => x?._id === values?.taskProject?.value);
                                        const assignedEmployees = employees?.filter((employee) =>
                                            selectedProject?.assignedEmployees?.some((assignedEmployee) => assignedEmployee.id === employee.id)
                                        );

                                        return (
                                            <ConditionalRender renderIf={!isEmpty(selectedProject)}>
                                                <SelectInput
                                                    className='field-style'
                                                    // showLightColors
                                                    menuPlacement='top'
                                                    name='taskAssignedTo'
                                                    isClearable={false}
                                                    validate={required}
                                                    placeholder={text?.tasks?.create?.assignedTo}
                                                    isSearchable
                                                    isValidNewOption={() => false}
                                                    controlWidth='100%'
                                                    options={[
                                                        { label: text?.tasks?.create?.unassigned, value: '000000000000000000000000' },
                                                        ...assignedEmployees?.map((employee) => ({
                                                            label: `${employee?.firstName} ${employee?.lastName}`,
                                                            value: employee?.id
                                                        }))
                                                    ]}
                                                    style={{
                                                        width: isDesktop ? '50%' : '100%',
                                                    }}
                                                />
                                            </ConditionalRender>
                                        );
                                    }}
                                </FormSpy>
                            </>
                        )}
                    </FormSpy>
                </div>


                {/* Todos Section */}
                <StyledTodo theme={theme} style={{
                    width: isDesktop ? '30%' : '100%',
                    display: 'flex',
                    flexDirection: 'column',
                }} >
                    <p
                        className='mb-md'
                        style={{
                            fontFamily: colors.openSans
                        }}
                    >{text?.tasks?.create?.todos}</p>
                    {todos.map((todo, index) => (
                        <div className='wrapper' key={index}
                        >
                            <FontAwesomeIcon
                                style={{
                                    cursor: 'pointer',
                                    color: colors.red,
                                    marginRight: '1em'
                                }}
                                onClick={() => removeTodo(todo.id)}
                                icon={faClose}
                            />
                            <FieldWithNoValidation
                                name={`todos[${index}].text`}
                                component="input"
                                // showLightColors
                                type="text"
                                ref={(el) => (inputRefs.current[index] = el)} // Assign the ref to the input
                                value={todo.text} // Make the input a controlled component by passing the current value
                                placeholder={text?.tasks?.create?.placeholder}
                                onChange={(e) => updateTodo(todo.id, 'text', e.target.value)}
                                // Add the onKeyDown event to handle "Enter" key press
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        e.preventDefault(); // Prevent default form submission or page reload
                                        addTodo(); // Call addTodo when Enter is pressed
                                    }
                                }}
                            />
                        </div>
                    ))}
                    {
                        (todos.length === 0) && (
                            <p className='mb-md'>
                                {text?.tasks?.create?.noTodos}
                            </p>
                        )

                    }
                    {error && <p style={{ color: colors.red }}>{error}</p>}

                    <FontAwesomeIcon
                        style={{
                            cursor: 'pointer',
                            color: theme === 'light' ? colors.black : colors.white
                        }}
                        onClick={addTodo}
                        icon={faAdd}
                    />
                </StyledTodo>
            </div>

        </SubmitModal>
    )
}

export default CreateNewTaskContainer