import React, { useState, useEffect } from 'react'
import Wrapper from '../Core/Wrapper'
import { useLanguage } from '../context/LanguageContext'
import ConditionalRender from '../Core/ConditionalRender'
import { proposalColumns } from '../Invetory/columns'
import HoverPopup from '../Core/HoverPopup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CustomSelect from '../Core/CustomSelect'
import { faFilter } from '@fortawesome/free-solid-svg-icons';
import CustomTable from '../Core/Tables/CustomTable'
import { proposalStatus } from '../utils/helperFunctions'
import SearchInput from '../Core/Inputs/SearchInput';
import { useTheme } from '../context/ThemeContext'
import { useToast } from '../context/ToastContext'
import colors from '../globalStyles.scss'
import ConfirmModal from '../Core/ConfirmModal'
import useScreenSize from '../context/useScreenSize'
import { useNavigate } from 'react-router';
import ProposalQuickView from '../QuickView/ProposalQuickView'


import classnames from 'classnames'
import {
    deleteAllProposals,
    removeProposals
} from '../utils/calls'

import { isEmpty } from 'lodash'
import styled from 'styled-components'

const StyledDiv = styled.div``

const ProposalContainerExpanded = ({
    title,
    projects,
    adminProjects,
    user,
    clients,
    proposals,
    proposalsIsLoading,
    fetchAllProposals,
    projectIsCompleted,
    isProject,
    projectId,
    project,
    isAdmin,
    setExpanded
}) => {
    const { text } = useLanguage()
    const { theme } = useTheme();
    const { notify } = useToast();
    const navigate = useNavigate();

    const { isDesktop, loading } = useScreenSize();

    const [tableData, setTableData] = useState([]);
    const [showFilters, setShowFilters] = useState(false);
    const [filters, setFilters] = useState({});
    const [toggleModal, setToggleModal] = useState(false)


    const [addProposalIsOpen, setAddProposalIsOpen] = useState(false)


    const [quickViewData, setQuickViewData] = useState(null);
    const [isQuickViewOpen, setIsQuickViewOpen] = useState(false);


    const handleQuickView = (rowData) => {
        setQuickViewData(rowData);
        setIsQuickViewOpen(!isQuickViewOpen);
    };

    useEffect(() => {
        if (proposals?.length === 0) {
            setTableData([]);
        } else {
            setTableData(proposals);
        }
    }, [proposals]);

    const handleSearchChange = (e) => {
        setFilters({ ...filters, query: e?.target?.value });
    };

    const handleSelectInputChange = (e, name) => {
        setFilters({ ...filters, [name]: e?.value });
    };
    const filterTableData = async () => {
        const filterOptions = {};
        if (filters.query) {
            filterOptions.query = filters.query.toLowerCase();
        }

        if (filters.projectId) {
            filterOptions.projectId = filters.projectId;
        }

        if (filters.clientId) {
            filterOptions.clientId = filters.clientId;
        }

        if (filters.status) {
            filterOptions.status = filters.status;
        }

        let filteredProposals = proposals;

        if (filterOptions.query) {
            filteredProposals = filteredProposals.filter(proposal =>
                proposal?.uuid.toLowerCase().includes(filterOptions.query)
            );
        }

        if (filterOptions.projectId) {
            filteredProposals = filteredProposals.filter(proposal => proposal?.projectId?.id === filterOptions.projectId);
        }

        if (filterOptions.clientId) {
            filteredProposals = filteredProposals.filter(proposal => proposal?.clientId?.id === filterOptions.clientId);
        }

        if (filterOptions.status) {
            filteredProposals = filteredProposals.filter(proposal => proposal?.status === filterOptions.status);
        }

        setTableData(filteredProposals);

    }
    useEffect(() => {
        filterTableData();
    }, [filters, proposals]);

    const handleDeleteAll = async () => {
        try {
            const res = await deleteAllProposals();
            if (res.status === 200) {
                setToggleModal(false);
                fetchAllProposals();
                notify(text?.notificationsUI?.proposal?.deleted, 'success');
            }
        } catch (error) {
            notify(`Error: ${error.message}`, 'error');
            console.error(error)
        }
    }

    const handleDeleteSelection = async () => {
        try {
            const res = await removeProposals(selectedIds);
            if (res.status === 200) {
                setToggleModal(false);
                fetchAllProposals();
                setSelectedIds([]);
                setSelectedItems([]);
                notify(text?.notificationsUI?.proposal?.deleted, 'success');
            }
        } catch (error) {
            notify(`Error: ${error.message}`, 'error');
            console.error(error)
        }
    }

    const [selectedIds, setSelectedIds] = useState([]);
    const [selectedItems, setSelectedItems] = useState([]);

    const handleRowSelectionChange = (rows) => {
        setSelectedItems(rows);
        setSelectedIds(rows.map(x => x._id));
    };

    return (
        <ConditionalRender renderIf={true} isLoading={loading}>
            <StyledDiv>
                <Wrapper
                    isLoading={proposalsIsLoading}
                >
                    <div className='ProposalsContainer mb-md'>
                        <div className='flex flex-column'>
                            <h4 className='mb-md' style={{
                                fontSize: '1.5em',
                            }}>
                                {text?.homeLandingContainer?.services?.proposals?.title}
                            </h4>
                            <ConditionalRender renderIf={!isEmpty(proposals)}>
                                <HoverPopup
                                    className='align-self-start'
                                    onClick={() => setShowFilters(!showFilters)}
                                    placement={'top'}
                                    id='toggle-filters-popup-proposals'
                                    text={isDesktop && text?.inventory?.landing?.table?.showFilters}
                                >
                                    <FontAwesomeIcon icon={faFilter} style={{ color: showFilters ? colors.yellow : colors.white }} />
                                </HoverPopup>
                            </ConditionalRender>
                            <ConditionalRender renderIf={showFilters}>
                                <div
                                    className='filters'
                                >
                                    <SearchInput
                                        className='mt-md'
                                        width={'100%'}
                                        value={filters.query}
                                        onChange={handleSearchChange}
                                        placeHolder={text?.tasks?.home?.filters?.search}
                                    />
                                    <CustomSelect
                                        className='mt-md'
                                        handleSelectInputChange={(e) => handleSelectInputChange(e, 'status')}
                                        options={proposalStatus(text)}
                                        name={'status'}
                                        placeHolder={text?.proposals?.landing?.status}
                                    />
                                    <ConditionalRender renderIf={!isProject}>
                                        <CustomSelect
                                            className='mt-md'
                                            handleSelectInputChange={(e) => handleSelectInputChange(e, 'projectId')}
                                            options={projects?.filter((x) => !x?.isCompleted)?.map((x) => ({
                                                label: x?.projectName,
                                                value: x?._id
                                            }))}
                                            name={'projectId'}
                                            placeHolder={text?.tasks?.home?.filters?.project}
                                        />

                                        <CustomSelect
                                            className='mt-md'
                                            handleSelectInputChange={(e) => handleSelectInputChange(e, 'clientId')}
                                            options={clients?.map((x) => ({
                                                label: x?.name,
                                                value: x?._id
                                            }))}
                                            name={'clientId'}
                                            placeHolder={text?.proposals?.landing?.client}
                                        />
                                    </ConditionalRender>
                                </div>
                            </ConditionalRender>
                        </div>
                        <div className='flex flex-column align-start mt-md'>
                            <CustomTable
                                showLightColors
                                keyField="_id"
                                columns={proposalColumns({
                                    theme,
                                    text,
                                    isProject,
                                    navigate,
                                    handleQuickView,
                                    isDesktop
                                })}
                                rows={tableData
                                    ?.filter(item => !item?.projectId?.isCompleted) // Filter out completed projects
                                    ?.map(item => {
                                        // Filter admin projects
                                        const adminProjects = projects?.filter(project =>
                                            project.adminEmployees?.some(employee => employee?.id === user?.id)
                                        );
                                        // Add isAdmin flag to each inventory item
                                        const isAdmin = adminProjects?.some(project => project?.id === item.projectId?._id) || user?.roleName === 'Admin';
                                        return { ...item, isAdmin };
                                    })}
                                theme={theme}
                                noDataText={text?.proposals?.landing?.notFound}
                                onRowSelectionChange={handleRowSelectionChange}
                            />
                            <ConditionalRender renderIf={!isEmpty(selectedIds)}>
                                <div style={{
                                    color: colors.red,
                                    fontFamily: colors.openSans,
                                    cursor: 'pointer',
                                    display: 'flex',
                                    marginBottom: '5px'
                                }} onClick={() => setToggleModal(true)} >
                                    {text?.proposals?.landing?.deleteSelected}
                                </div>
                                <ConfirmModal
                                    onClick={handleDeleteSelection}
                                    text={text?.tasks?.home?.modal?.button}
                                    toggle={() => setToggleModal(!toggleModal)}
                                    isOpen={toggleModal}
                                    btnStyles={{
                                        color: colors.red
                                    }}
                                    width={isDesktop ? '50%' : '100%'}
                                    height={isDesktop ? '50%' : '100%'}

                                >
                                    <div>
                                        <h4 style={{ marginBottom: '15px' }}>
                                            {text?.proposals?.landing?.modal?.title}
                                        </h4>
                                        <ul>
                                            {selectedItems?.map((x) => {
                                                return (
                                                    <li style={{ fontSize: '0.9rem' }}>
                                                        - {x?.uuid}
                                                    </li>
                                                )
                                            })}
                                        </ul>
                                    </div>
                                </ConfirmModal>
                            </ConditionalRender>

                            <ConditionalRender renderIf={isAdmin && !isEmpty(proposals)}>
                                <div style={{
                                    color: colors.red,
                                    fontFamily: colors.openSans,
                                    alignSelf: 'start',
                                    cursor: 'pointer'
                                }} onClick={() => setToggleModal(true)}>
                                    {text?.proposals?.landing?.deleteAll}
                                </div>
                            </ConditionalRender>
                        </div>

                    </div>
                    {/* Quick View */}
                    <ProposalQuickView
                        isQuickViewOpen={isQuickViewOpen}
                        setIsQuickViewOpen={setIsQuickViewOpen}
                        quickViewData={quickViewData}
                        noOverlay
                    />
                </Wrapper>

                <ConfirmModal
                    onClick={handleDeleteAll}
                    text={text?.inventory?.landing?.modal?.button}
                    toggle={() => setToggleModal(!toggleModal)}
                    isOpen={toggleModal}
                    btnStyles={{
                        color: colors.red
                    }}
                    width={isDesktop ? '50%' : '100%'}
                    height={isDesktop ? '50%' : '100%'}
                >
                    <div>
                        <h4 style={{ marginBottom: '15px' }}>
                            {text?.inventory?.landing?.modal?.title}
                        </h4>
                        <p>
                            {text?.inventory?.landing?.modal?.subtitle}
                        </p>
                    </div>
                </ConfirmModal>
            </StyledDiv>
        </ConditionalRender>
    )
}

export default ProposalContainerExpanded