import React, { useState, useContext } from 'react'
import { useNavigate } from "react-router-dom";

import styled from 'styled-components'
import colors from '../globalStyles.scss'
import HoverPopup from '../Core/HoverPopup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretLeft, faCaretRight, faArrowAltCircleLeft, faClock } from '@fortawesome/free-solid-svg-icons';
import { useLocation } from "react-router-dom";
import TimeTracker from '../Core/TimeTracker'
import ConditionalRender from '../Core/ConditionalRender'
import { useTheme } from '../context/ThemeContext'
import { useLanguage } from '../context/LanguageContext';
import moment from 'moment';
import classnames from 'classnames';
import LandingProjectContainer from '../Project/LandingProjectContainer';
import useScreenSize from '../context/useScreenSize';
import ConfirmModal from '../Core/ConfirmModal';
import RunningTimeSheets from '../Welcome/RunningTimeSheets';
import CallToAction from '../Core/CallToAction';
import { isEmpty } from 'lodash';
import { TimerContext } from '../context/TimeContext';

const StyledSideMenu = styled.div`
    position: relative;
    &.desktop {
        position: relative;
        width: ${(props) => props.isCollapsed ? '0' : '380px'};
        border-right: 10px solid ${props => props.theme === 'dark' ? colors.secondary : colors.background};
        transform: translateX(${(props) => props.isCollapsed ? '-100%' : '0'});
        height: 100%;
    }
    &.tablet, &.phone {
        position: fixed;
        top: 5%;
        height: 95%;
        width: 100%;
        z-index: 3;
        transform: translateX(${(props) => props.isCollapsed ? '-100%' : '0'});
    }
    padding: ${(props) => props.isCollapsed ? '0' : '0'};
    overflow: ${(props) => props.isCollapsed ? 'hidden' : 'visible'};
    transition: transform 0.3s;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: ${(props) => props.theme === 'dark' ? colors.background : colors.white};
    .wrapper {
        height: 100%;
    }
    .wrapper.scroll-container::-webkit-scrollbar {
        width: 0px;
        height: 0px;
      }
    .timeTracker {
        padding: ${(props) => props.isCollapsed ? '0' : '0 1em'};
        padding-bottom: 1em;

    }
`;

const SideMenu = ({
    isLoggedIn,
    isCompanyPremium,
    projects,
    user,
    collapsed,
    setCollapsed,
    projectsIsLoading,
    tasks
}) => {
    const { runningTimesheets } = useContext(TimerContext);
    const { theme } = useTheme();
    const { text } = useLanguage();
    const { isDesktop, isTablet, loading } = useScreenSize();
    const navigate = useNavigate();
    const [isOpen, setIsOpen] = useState(false);
    const location = useLocation();
    const isLogsPage = location.pathname === "/logs";


    const [showTimeTracker, setShowTimeTracker] = useState(false);

    const getGreetingMessage = () => {
        const currentHour = moment().hour();
        const userName = user ? `${user.firstName} ${user.lastName}` : '';

        let greeting;
        if (currentHour < 12) {
            greeting = text?.greetings?.morning;
        } else if (currentHour < 18) {
            greeting = text?.greetings?.afternoon;
        } else {
            greeting = text?.greetings?.evening;
        }

        return `${greeting} ${userName}`;
    };

    return (
        <ConditionalRender renderIf={true} isLoading={loading || projectsIsLoading}>
            <div
                className={classnames({
                    'w-0': collapsed,
                })}
                style={{
                    position: 'relative',
                    height: '100%',
                }}>
                <StyledSideMenu
                    className={isDesktop ? 'desktop side' : isTablet ? 'tablet side' : 'phone side'}
                    isCollapsed={collapsed}
                    theme={theme}
                    style={{
                        padding: '1em',
                    }}
                >
                    <div>
                        <img
                            src={user?.profilePhoto}
                            alt={'profile'}
                            style={{
                                width: '100px',
                                height: '100px',
                                borderRadius: '20%',
                                margin: '1em auto',
                                display: 'block'
                            }}
                        />
                        <p style={{
                            marginTop: '1em',
                        }}>
                            {getGreetingMessage()}
                        </p>
                    </div>
                    <div className='scroll-container wrapper'>
                        <LandingProjectContainer
                            user={user}
                            projects={projects}
                            setCollapsed={setCollapsed}
                        />

                    </div>
                    <ConditionalRender renderIf={isLoggedIn}>
                        <ConditionalRender renderIf={isDesktop}>
                            <ConditionalRender renderIf={!isLogsPage}>
                                <HoverPopup
                                    className='mb-sm mr-md'
                                    onClick={() => setShowTimeTracker(!showTimeTracker)}
                                    style={{
                                        width: '100%'
                                    }}
                                    placement={'top'}
                                    id='clock-popup'
                                    text={text?.timeTracker?.clockIn}
                                    controlledTooltipProps={{
                                        isDark: true,
                                        text: text?.actionTracking?.clockButton,
                                        placement: 'top',
                                        actionParams: {
                                            feature: 'timeTracking',
                                            action: 'clock'
                                        }
                                    }}
                                >
                                    <FontAwesomeIcon
                                        style={{
                                            fontSize: '1.8em',
                                        }}
                                        icon={faClock} />
                                </HoverPopup>
                            </ConditionalRender>
                            <ConfirmModal
                                isOpen={showTimeTracker}
                                toggle={() => setShowTimeTracker(!showTimeTracker)}
                                width={isDesktop ? '50%' : '100%'}
                                height={isDesktop ? '70%' : '100%'}
                            >
                                <div className='w-100'>
                                    <h4 className='mb-md'>
                                        {text?.dashboard?.timeClock}
                                    </h4>
                                    <CallToAction
                                        className='mb-md'
                                        type="button"
                                        onClick={() => {
                                            navigate(`/logs`)
                                            setShowTimeTracker(!showTimeTracker)
                                        }}
                                        text={text?.timeTracker?.manageAllLogs}
                                    />
                                    <TimeTracker
                                        projects={projects}
                                        user={user}
                                        showLightColors={theme === 'light'}
                                    />
                                    <ConditionalRender renderIf={!isEmpty(runningTimesheets)}>
                                        <CallToAction
                                            className='mt-md'
                                            onClick={() => setIsOpen(!isOpen)}
                                            text={text?.dashboard?.viewRunning}
                                            style={{
                                                alignSelf: 'center',
                                                display: 'flex',
                                                width: '100%',
                                            }}
                                            btnStyle={{
                                                color: 'white',
                                            }}
                                        />
                                    </ConditionalRender>
                                    <ConditionalRender renderIf={isEmpty(runningTimesheets)}>
                                        <p className='mb-md'>
                                            {text?.dashboard?.noEmployees}
                                        </p>
                                    </ConditionalRender>
                                    <RunningTimeSheets
                                        runningTimesheets={runningTimesheets}
                                        isOpen={isOpen}
                                        setIsOpen={setIsOpen}
                                    />
                                </div>

                            </ConfirmModal>
                        </ConditionalRender>
                    </ConditionalRender>

                    <ConditionalRender renderIf={!isDesktop}>
                        <HoverPopup
                            style={{
                                background: theme === 'dark' ? colors.secondary : colors.backgroundSecondary,
                                position: 'absolute',
                                right: '5%',
                                bottom: '5%',
                                padding: '0.5em',
                                borderRadius: '50%',
                                display: 'flex',
                                alignItems: 'center',
                            }}
                            placement={'right'}
                            onClick={() => setCollapsed(!collapsed)}
                            id='toggle-side-two-popup'
                        >
                            <FontAwesomeIcon icon={faArrowAltCircleLeft} />
                        </HoverPopup>
                    </ConditionalRender>
                </StyledSideMenu>
                <ConditionalRender renderIf={isDesktop}>
                    <HoverPopup
                        placement={'right'}
                        id={'collapsed'}
                        text={collapsed ? text?.sidebar?.expand : text?.sidebar?.collapse}
                        style={{
                            color: theme === 'dark' ? colors.secondary : colors.background,
                            cursor: 'pointer',
                            position: 'absolute',
                            background: 'transparent',
                            border: 'none',
                            hoverBackground: 'transparent',
                            right: collapsed ? '-25px' : '-10px',
                            top: '50%',
                            zIndex: 1,
                        }}
                    >
                        <FontAwesomeIcon
                            style={{
                                fontSize: '50px'
                            }}
                            onClick={() => setCollapsed(!collapsed)}
                            icon={collapsed ? faCaretRight : faCaretLeft}
                        />
                    </HoverPopup>

                </ConditionalRender>

            </div>

        </ConditionalRender>
    )
}

export default SideMenu